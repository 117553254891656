import VueLib from 'vue'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  const opts = Object.assign({}, {"dsn":"https:\u002F\u002F47752154e620462bb12069b1349b42d9@sentry.onecommerce.shop\u002F15","environment":"dev","release":"9.34.1","ignoreErrors":["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http:\u002F\u002Ftt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http:\u002F\u002Floading.retry.widdit.com\u002F","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","Script error."],"ignoreUrls":[new RegExp("graph\\.facebook\\.com", "i"),new RegExp("connect\\.facebook\\.net\\\u002Fen_US\\\u002Fall\\.js", "i"),new RegExp("eatdifferent\\.com\\.woopra-ns\\.com", "i"),new RegExp("static\\.woopra\\.com\\\u002Fjs\\\u002Fwoopra\\.js", "i"),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("webappstoolbarba\\.texthelp\\.com\\\u002F", "i"),new RegExp("metrics\\.itunes\\.apple\\.com\\.edgesuite\\.net\\\u002F", "i")]}, {
    integrations: [
      new Dedupe({}),
      new ExtraErrorData({}),
      new ReportingObserver({}),
      new RewriteFrames({}),
      new Vue({Vue: VueLib, ...{"attachProps":true}})
    ]
  })

  // Initialize sentry
  Sentry.init(opts)

  // Inject Sentry to the context as $sentry
  inject('sentry', Sentry)
}

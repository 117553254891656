import { Module } from 'vuex'
import { CategoriesState } from './types'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'

export const state = (): CategoriesState => ({
  categoriesFetched: false,
  categories: {},
  selectedCategoryId: null,
  selectedCategoriesTree: [],
  orderCategories: [],
})

const namespaced: boolean = true

const categories: Module<CategoriesState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export default categories

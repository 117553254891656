import Vue from 'vue';

import {
  NavbarPlugin,
  ButtonPlugin,
  CardPlugin,
  LayoutPlugin,
  FormPlugin,
  FormFilePlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  CarouselPlugin,
  FormRadioPlugin,
  BadgePlugin,
  TooltipPlugin,
  ImagePlugin,
  ModalPlugin,
  TablePlugin,
  PaginationPlugin,
  LinkPlugin,
  FormTextareaPlugin,
  ListGroupPlugin,
  VBPopoverPlugin,
  VBTooltipPlugin,
  VBScrollspyPlugin
} from 'bootstrap-vue';

Vue.use(NavbarPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(CarouselPlugin);
Vue.use(FormRadioPlugin);
Vue.use(BadgePlugin);
Vue.use(TooltipPlugin);
Vue.use(ImagePlugin);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(LinkPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ListGroupPlugin);

Vue.use(VBPopoverPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(VBScrollspyPlugin);

import { Module } from 'vuex'
import { Plugins } from '@one/types'
import { Shop } from '@one-commerce/sdk-shared'
import { AxiosResponse } from 'axios'
import Vue from 'vue'
import uuid from 'uuid-random'
import { commons } from '@one/core'
import Type = Plugins.Type
import IFrameShopPlugin = Plugins.IFrameShopPlugin
import AvailableComponents = Plugins.AvailableComponents
import ActivePlugin = Plugins.ActivePlugin
import TokenResponse = Plugins.TokenResponse
import IFrameShopAddToCartPlugin = Plugins.IFrameShopAddToCartPlugin
import IFrameShopEditProductLinesInCartPlugin = Plugins.IFrameShopEditProductLinesInCartPlugin

interface PluginAuthToken {
  token: string
  timestamp: number
}
export interface ExtendedComponent<T = AvailableComponents> {
  id: string
  pluginId: string
  raw: T
  isModalComponent: boolean
  context?: Shop.PluginContext
  auth?: PluginAuthToken
}

export interface ActivePluginWithComponentsIds {
  id: string
  components: Array<string>
  authorizeWithOne: boolean
}
export interface PluginsData {
  [key: string]: any
}
export interface PluginsState {
  plugins: Record<string, ActivePluginWithComponentsIds>
  allComponents: Record<string, ExtendedComponent>
  showPluginModal: boolean
  modalComponentId: string | null
}

export const MODAL_COMPONENTS = [
  Type.IFRAME_SHOP_ADD_TO_CART,
  Type.IFRAME_SHOP_EDIT_PRODUCT_LINES_IN_CART,
]
export const ROUTABLE_COMPONENTS = [Type.IFRAME_SHOP]

export const isRoutableComponent = (component: ExtendedComponent): component is ExtendedComponent<IFrameShopPlugin> =>
  ROUTABLE_COMPONENTS.includes(component.raw.type)
const getCurrentTimeInSeconds = () => Math.floor(Date.now() / 1000)

interface Resolvers {
  embeddingAddressResolver: Record<string, (component: ExtendedComponent & any) => string>
}

const resolvers: Resolvers = {
  embeddingAddressResolver: {
    [Type.IFRAME_SHOP]: (component: ExtendedComponent<IFrameShopPlugin>) =>
      component.raw.page.iframeUrl,
    [Type.IFRAME_SHOP_ADD_TO_CART]: (component: ExtendedComponent<IFrameShopAddToCartPlugin>) =>
      component.raw.popup.iframeUrl,
    [Type.IFRAME_SHOP_EDIT_PRODUCT_LINES_IN_CART]: (component: ExtendedComponent<IFrameShopEditProductLinesInCartPlugin>) =>
      component.raw.popup.iframeUrl,
  },
}
export const state = (): PluginsState => ({
  plugins: {},
  allComponents: {},
  showPluginModal: false,
  modalComponentId: null,
})

const namespaced: boolean = true

const plugins: Module<PluginsState, any> = {
  namespaced,
  state,
  getters: {
    componentForGivenType(state: PluginsState): (type: Type) => Array<any> {
      /*
      Tutaj powinno się jakos dać powiedzieć TSowi jaki konkretnie typ w tablicy będzie zwracany
      na podstawie atrybutu `type` który jest parametrem. Ale nie wiem jak.
       */
      return (type: Type) => {
        return Object.values(state.allComponents).filter(component => component.raw.type === type)
      }
    },
    getComponentById: (state: PluginsState) => (id: string): ExtendedComponent | null => {
      return state.allComponents[id] || null
    },
    getPluginById: (state: PluginsState) => (pluginId: string): ActivePluginWithComponentsIds | null => {
      return state.plugins[pluginId] ?? null
    },
    getRoutableComponentBySlug: (state: PluginsState) => (slug: string): ExtendedComponent<IFrameShopPlugin> | null => {
      return Object.values(state.allComponents)
        .filter(isRoutableComponent)
        .find((component: ExtendedComponent<IFrameShopPlugin>) => commons.UrlWithoutSlash(component.raw.page.slug) === commons.UrlWithoutSlash(slug)) ?? null
    },
    getComponentByPluginIdAndType: (state: PluginsState) => ({ pluginId, type }: { pluginId: string, type: Type }): ExtendedComponent | null => {
      return (
        (Object.values(state.allComponents) as Array<ExtendedComponent>).find(
          (component: ExtendedComponent) =>
            component.pluginId === pluginId && component.raw.type === type,
        ) ?? null
      )
    },
  },
  actions: {
    fetchPlugins({ commit }) {
      const app: any = this.app
      app.wait.start('plugins-fetching')
      return this.$api.plugins.app
        .getPlugins()
        .then((results: AxiosResponse<Array<ActivePlugin>>) => {
          commit('setPlugins', results.data)
          app.wait.end('plugins-fetching')
          return Promise.resolve(results.data)
        })
    },
    findComponentsForGivenType({ state }, type: Type): Array<ExtendedComponent> {
      return (Object.values(state.allComponents) as Array<ExtendedComponent>).filter(
        component => component.raw.type === type,
      )
    },
    async getPluginIdToLaunch({ dispatch }, { productPlugins, type }: { productPlugins: PluginsData, type: Type }) {
      const activePlugins = await dispatch('findComponentsForGivenType', type)
      const productPluginIds = Object.keys(productPlugins)
      const plugins = activePlugins.filter(plugin => productPluginIds.includes(plugin.pluginId))
      if (plugins.length) {
        return plugins[0].pluginId
      }
      return null
    },
    async generateEmbeddingUrl({ state }, componentId: string) {
      const component = state.allComponents[componentId] || null
      const response = await this.$api.plugins.app.createToken(component!.pluginId)
      const src = resolvers.embeddingAddressResolver[component!.raw.type](component)
      const joiningMark = src?.includes('?') ? '&' : '?'
      const tokenQuery = `token=${response.data.token}`
      const originQuery = `&origin=${window.location.origin}`
      return `${src}${joiningMark}${tokenQuery}${originQuery}`
    },
    generateToken({ state, commit }, componentId: string) {
      const component = state.allComponents[componentId] || null
      return this.$api.plugins.app
        .generateAuthWithOneToken(component!.pluginId)
        .then(({ data }: { data: TokenResponse }) => {
          commit('setTokenForComponent', { componentId, token: data.token })
          return Promise.resolve(data.token)
        })
    },
    getCachedTokenOrGenerateNew({ dispatch, state }, componentId: string): Promise<string> {
      const component = state.allComponents[componentId] || null
      if (!component!.auth) {
        return dispatch('generateToken', componentId)
      }
      const tokenValidityTime = 840 // 14min in seconds, token is valid for 15min
      const elapsedTime = getCurrentTimeInSeconds() - component!.auth.timestamp

      if (elapsedTime < tokenValidityTime) {
        return Promise.resolve(component!.auth.token)
      }
      return dispatch('generateToken', componentId)
    },
    showModalPlugin({ commit, state }, componentId: string) {
      const component = state.allComponents[componentId] || null
      if (!component) {
        return
      }
      commit('showModalPlugin', component!.id)
    },
  },
  mutations: {
    setPlugins(state: PluginsState, plugins: Array<ActivePlugin>) {
      plugins.forEach((plugin: ActivePlugin) => {
        const componentIds: Array<string> = plugin.components.map((component) => {
          const componentId: string = uuid()
          state.allComponents[componentId] = {
            pluginId: plugin.id,
            isModalComponent: MODAL_COMPONENTS.includes(component.type),
            id: componentId,
            raw: component,
          }
          return componentId
        })
        state.plugins[plugin.id] = {
          id: plugin.id,
          components: componentIds,
          authorizeWithOne: plugin.authorizeWithOne,
        }
      })
    },
    showModalPlugin(state: PluginsState, componentId: string) {
      state.showPluginModal = true
      state.modalComponentId = componentId
    },
    hideModalPlugin(state: PluginsState) {
      state.showPluginModal = false
      state.modalComponentId = null
    },
    addModalPluginContextData(state: PluginsState, { componentId, context }: { componentId: string, context: Shop.PluginContext }): void {
      const component = state.allComponents[componentId] || null
      if (component) {
        component.context = context
      }
    },
    setTokenForComponent(state: PluginsState, { componentId, token }: { componentId: string, token: string }) {
      const timestamp = getCurrentTimeInSeconds()
      Vue.set(state.allComponents[componentId], 'auth', { token, timestamp })
    },
    CLEAR_STORE(state: PluginsState) {
      state.plugins = {}
      state.allComponents = {}
      state.showPluginModal = false
      state.modalComponentId = null
    },
  },
}

export default plugins

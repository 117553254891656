export default async function ({ redirect, app, route }) {
  let permission = null
  // eslint-disable-next-line array-callback-return
  route.meta.map((meta) => {
    if (meta.permissions) { permission = meta.permissions }
  })
  // eslint-disable-next-line no-unreachable
  if (await app.$auth.hasPermissions(permission, true)) {
    return Promise.resolve()
  }
  return redirect(app.localePath('account'))
}

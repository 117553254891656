import {
  Checkout,
  Orders,
  PaymentMethod,
  Pricing,
  Rfq,
} from '@one/types'
import { Cart, OrderConfirmation, Shared } from '@one/types/dist/orderpath/app'
import { ActionContext } from 'vuex'
import { AxiosResponse } from 'axios'
import { mt } from './mutations'
import { CartState, OrdersHistoryPagination } from './types'
import { RootState } from '~/store'
import PromoPointRestView = Pricing.PromoPointRestView

export const actions: any = {
  // Other
  fetchAdditionalCostName(
    { commit }: ActionContext<CartState, RootState>,
    costTypeId: string,
  ) {
    return this.$api.pricing.app
      .getAdditionalCostTypeName(costTypeId)
      .then(({ data }: AxiosResponse<Pricing.SimpleAdditionalCostTypeDto>) => {
        commit(mt.SET_ADDITIONAL_COST_TYPE, {
          type: costTypeId,
          name: data.name,
        })
        return Promise.resolve(data.name)
      })
  },
  fetchPaymentMethods(
    { commit }: ActionContext<CartState, RootState>,
    context: {
      cartId: string
      warehouseId: string
    },
  ) {
    if (!context) {
      return Promise.reject(
        new Error('cartId and warehouseId are required to fetch payment methods'),
      )
    }
    if (!context.cartId) {
      return Promise.reject(Error('cartId is required to fetch payment methods'))
    }
    if (!context.warehouseId) {
      return Promise.reject(Error('warehouseId is required to fetch payment methods'))
    }
    const { app }: any = this
    app.wait.start(`cart-${context.cartId}-payment-methods-fetching`)

    return this.$api.orderpath.app
      .getPaymentMethods(context.cartId, context.warehouseId)
      .then((success: AxiosResponse<Array<PaymentMethod>>) => {
        commit(mt.SET_PAYMENT_METHODS, {
          cartId: context.cartId,
          warehouseId: context.warehouseId,
          paymentMethods: success.data,
        })
        return success.data
      })
      .finally(() =>
        app.wait.end(`cart-${context.cartId}-payment-methods-fetching`),
      )
  },
  fetchDeliveryMethods(
    { commit }: ActionContext<CartState, RootState>,
    { warehouseId, shipmentsIds, cartId },
  ) {
    const { app }: any = this
    app.wait.start(`cart-${cartId}-delivery-methods-fetching`)
    commit(mt.FETCHING_DELIVERY_METHODS)
    const deliveryMethodsRequests: Array<Promise<any>> = []
    shipmentsIds.forEach((shipmentId: string) => {
      deliveryMethodsRequests.push(
        this.$api.orderpath.app
          .getDeliveryMethods(warehouseId, shipmentId, cartId)
          .then(
            (
              success: AxiosResponse<Cart.Responses.DeliveryMethods>,
            ) => {
              const newDeliveryMethodsForShipment: any = {
                couriers: success.data.couriers
                  .map(
                    courier => ({
                      [courier.info.id]: { ...courier.info },
                    }),
                  )
                  .reduce((x: any, y: any) => ({ ...x, ...y }), {}),
                clickAndCollects: success.data.clickAndCollects
                  .map(
                    cac => ({
                      [cac.info.id]: {
                        ...cac.info,
                        warehouses: cac.warehouses,
                      },
                    }),
                  )
                  .reduce((x: any, y: any) => ({ ...x, ...y }), {}),
                crossDockings: success.data.crossDockings
                  .map(cd => ({
                    [cd.info.id]: { ...cd.info, warehouses: cd.warehouses },
                  }))
                  .reduce((x: any, y: any) => ({ ...x, ...y }), {}),
                parcelLockers: success.data.parcelLockers
                  .map(
                    parcelLocker => ({
                      [parcelLocker.info.id]: { ...parcelLocker.info },
                    }),
                  )
                  .reduce((x: any, y: any) => ({ ...x, ...y }), {}),
              }
              return {
                [shipmentId]: newDeliveryMethodsForShipment,
              }
            },
          ),
      )
    })
    return Promise.all(deliveryMethodsRequests)
      .then((results: Array<any>) => {
        commit(mt.SET_DELIVERY_METHODS, {
          warehouseId,
          cartId,
          deliveryMethods: results.reduce(
            (x: any, y: any) => ({ ...x, ...y }),
            {},
          ),
        })
        commit(mt.FETCHED_DELIVERY_METHODS)
      })
      .finally(() => app.wait.end(`cart-${cartId}-delivery-methods-fetching`))
  },
  fetchPaymentsAndDeliveryMethods(
    ctx: ActionContext<CartState, RootState>,
    cart: Cart.Responses.Cart,
  ) {
    return Promise.all([
      ctx.dispatch('fetchPaymentMethods', {
        cartId: cart.id,
        warehouseId: ctx.rootState.stocks.defaultWarehouse,
      }),
      ctx.dispatch('fetchDeliveryMethods', {
        warehouseId: ctx.rootState.stocks.defaultWarehouse,
        shipmentsIds: cart.shipments.map(x => x.id),
        cartId: cart.id,
      }),
    ])
  },
  // Carts related
  fetchCarts(ctx: ActionContext<CartState, RootState>) {
    return this.$api.orderpath.app
      .getCarts(ctx.rootState.stocks.defaultWarehouse)
      .then((success: AxiosResponse<Array<Cart.Responses.BasicCartInfo>>) => {
        ctx.commit(mt.SET_BASIC_CARTS, success.data)
        return Promise.resolve(success.data)
      })
  },
  fetchCurrentCart(ctx: ActionContext<CartState, RootState>) {
    return ctx.dispatch('fetchCart', ctx.state.selectedCart)
  },
  fetchCart(
    { commit, rootState, dispatch, state }: ActionContext<CartState, RootState>,
    cartId: string,
  ) {
    if (!cartId) {
      throw new Error('Trying to fetchCart with NULL cartId')
    }
    if (!rootState.stocks.defaultWarehouse) {
      throw new Error(`Trying to fetchCart ${cartId} with NULL defaultWarehouse`)
    }
    const { app }: any = this
    app.wait.start(`cart-${cartId}-fetching`)
    const warehouseId: string = rootState.stocks.defaultWarehouse

    return this.$api.orderpath.app
      .getCart(cartId, warehouseId)
      .then((success: AxiosResponse<Cart.Responses.Cart>) => {
        const cart: Cart.Responses.Cart = success.data
        commit(mt.SET_CART, {
          cart,
          contextWarehouseId: warehouseId,
        })
        const additionalCostTypes = JSON.parse(
          JSON.stringify(state.additionalCostTypes),
        )
        if (Object.keys(additionalCostTypes).length < 1) {
          cart.prices?.additionalCosts.map((elem) => {
            return dispatch('fetchAdditionalCostName', elem.type)
          })
        }
        commit('SET_IS_MANUAL_PROMOTIONS_LIST_COLLAPSED', false)
        return Promise.resolve(cart)
      })
      .finally(() => app.wait.end(`cart-${cartId}-fetching`))
  },
  async refetchCart({ dispatch }: ActionContext<CartState, RootState>, cartId: string | undefined = undefined) {
    this.$api.orderpath.app.invalidate(['carts'])
    await dispatch('fetchCartsAndSelect', cartId)
    const refetchedCart = await dispatch('fetchCurrentCart')
    await dispatch('fetchPaymentsAndDeliveryMethods', refetchedCart)
  },
  async fetchCartsAndSelect({ dispatch }: ActionContext<CartState, RootState>, cartId: string | undefined = undefined) {
    const carts = await dispatch('fetchCarts')
    return dispatch('selectCart', cartId || carts[0].id)
  },
  takeoverCart(_, cartId: string) {
    if (!cartId) {
      return Promise.reject(
        Error('cartId cannot be null or undefined when takeover'),
      )
    }

    return this.$api.orderpath.app
      .takeoverCart(cartId)
      .then((success: AxiosResponse<Cart.Responses.NewCart>) => success.data)
  },
  async selectCart(ctx: ActionContext<CartState, RootState>, cartId: string) {
    const { app }: any = this
    if (!cartId) {
      return Promise.reject(
        Error('cartId cannot be null or undefined when selecting'),
      )
    }
    try {
      const fetchedCart: Cart.Responses.Cart = await ctx.dispatch(
        'fetchCart',
        cartId,
      )
      ctx.commit(mt.SET_CURRENT_CART, fetchedCart.id)
      ctx.dispatch('stocks/setWarehouse', fetchedCart.contextWarehouseId, { root: true })
      return Promise.resolve(fetchedCart.id)
    } catch (e) {
      app.$logger.warn(e)
      return Promise.resolve(null)
    }
  },
  createCart(
    _,
    payload: { name: string, warehouseId: string },
  ): Promise<Cart.Responses.NewCart> {
    return this.$api.orderpath.app
      .createCart({
        cartName: payload.name,
        warehouseId: payload.warehouseId,
      })
      .then((success: AxiosResponse<Cart.Responses.NewCart>) =>
        Promise.resolve(success.data),
      )
  },
  fetchAnonymousCart(
    { commit, rootState }: ActionContext<CartState, RootState>,
    cartId: string,
  ): Promise<Cart.Responses.Cart | null> {
    if (!cartId) {
      throw new Error('Trying to fetchCart with NULL cartId')
    }
    if (!rootState.stocks.defaultWarehouse) {
      throw new Error(
        `Trying to fetchCart ${cartId} with NULL defaultWarehouse`,
      )
    }
    const { app }: any = this
    app.wait.start(`cart-${cartId}-fetching`)
    const warehouseId: string = rootState.stocks.defaultWarehouse

    return this.$api.orderpath.app
      .getAnonymousCart(cartId, warehouseId)
      .then((success: AxiosResponse<Cart.Responses.Cart>) => {
        const cart: Cart.Responses.Cart = success.data
        commit(mt.SET_CART, {
          cart,
          contextWarehouseId: warehouseId,
        })
        return Promise.resolve(cart)
      })
      .catch(() => null)
      .finally(() => app.wait.end(`cart-${cartId}-fetching`))
  },
  removeAnonymousCart(_: ActionContext<CartState, RootState>, cartId: string) {
    return this.$api.orderpath.app.removeAnonymousCart(cartId)
  },
  removeCart(ctx: ActionContext<CartState, RootState>, cartId: string) {
    return this.$api.orderpath.app.removeCart(cartId).then(() => {
      if (ctx.state.carts[cartId]) {
        delete ctx.state.carts[cartId]
      }
      if (ctx.state.basicCarts[cartId]) {
        delete ctx.state.basicCarts[cartId]
      }
      if (ctx.state.selectedCart === cartId) {
        ctx.commit(mt.SET_CURRENT_CART, null)
      }
      return Promise.resolve(true)
    })
  },
  renameCart(
    { commit }: ActionContext<CartState, RootState>,
    { cartId, name }: { cartId: string; name: string },
  ) {
    if (!cartId) {
      return Promise.reject(Error('cartId is required'))
    }

    return this.$api.orderpath.app
      .renameCart(cartId, {
        newName: name,
      })
      .then(() => {
        commit(mt.RENAME_CART, {
          cartId,
          name,
        })
        return Promise.resolve()
      })
  },
  addProductToCart(
    ctx: ActionContext<CartState, RootState>,
    { cartId, newProduct, warehouseId },
  ) {
    if (!cartId) {
      return Promise.reject(Error('cartId is required'))
    }
    if (!warehouseId) {
      return Promise.reject(Error('warehouseId is required'))
    }
    const { app }: any = this
    app.wait.start('adding-product-to-cart')

    return this.$api.orderpath.app
      .addProduct(cartId, newProduct, warehouseId)
      .then(async () => {
        await ctx.dispatch('fetchCart', cartId)
      })
      .finally(() => app.wait.end('adding-product-to-cart'))
  },
  changeNumberOfProduct(
    _,
    { cartId, warehouseId, payload }: {cartId: string, warehouseId: string, payload: Cart.Requests.ChangeNumberOfProductInCart},
  ) {
    if (!cartId) {
      return Promise.reject(Error('cartId is required'))
    }
    if (!warehouseId) {
      return Promise.reject(Error('warehouseId is required'))
    }
    return this.$api.orderpath.app
      .changeNumberOfProduct(cartId, warehouseId, payload)
  },
  addSelectedProductsToCart(
    _,
    { cartId, newProducts, warehouseId },
  ) {
    if (!cartId) {
      return Promise.reject(Error('cartId is required'))
    }
    if (!warehouseId) {
      return Promise.reject(Error('warehouseId is required'))
    }
    const { app }: any = this
    app.wait.start('adding-product-to-cart')

    return this.$api.orderpath.app
      .addSelectedProducts(cartId, newProducts, warehouseId)
      .then(() => Promise.resolve(true))
      .catch((e: any) => Promise.reject(e))
      .finally(() => {
        app.wait.end('adding-product-to-cart')
      })
  },
  setCartComments(
    { commit }: ActionContext<CartState, RootState>,
    { cartId, comments },
  ) {
    return this.$api.orderpath.app
      .setCartComments(cartId, comments)
      .then(() => {
        commit(mt.SET_COMMENTS, { cartId, comments })
      })
  },
  setWarehouseContext(_, { cartId, warehouseId }) {
    return this.$api.orderpath.app.setWarehouseContextOnCart(cartId, warehouseId)
  },
  setPaymentMethod(
    { commit }: ActionContext<CartState, RootState>,
    { cartId, paymentMethodId, warehouseId },
  ) {
    const { app }: any = this
    app.wait.start(`cart-${cartId}-payment-methods-updating`)

    return this.$api.orderpath.app
      .setPaymentMethod(cartId, paymentMethodId, warehouseId)
      .then(() => {
        commit(mt.SET_PAYMENT_METHOD, { cartId, paymentMethodId })
      })
      .finally(() => app.wait.end(`cart-${cartId}-payment-methods-updating`))
  },
  setClickAndCollectDeliveryMethod(
    { dispatch }: ActionContext<CartState, RootState>,
    { cartId, shipmentId, data },
  ) {
    return this.$api.orderpath.app
      .setClickAndCollectDeliveryMethod(cartId, shipmentId, data)
      .then(async () => {
        await dispatch('fetchCart', cartId)
      })
  },
  setCourierDeliveryMethod(
    { dispatch }: ActionContext<CartState, RootState>,
    { cartId, shipmentId, data },
  ) {
    return this.$api.orderpath.app
      .setCourierDeliveryMethod(cartId, shipmentId, data)
      .then(async () => {
        await dispatch('fetchCart', cartId)
      })
  },
  setCrossDockingDeliveryMethod(
    { dispatch }: ActionContext<CartState, RootState>,
    { cartId, shipmentId, data },
  ) {
    return this.$api.orderpath.app
      .setCrossDockingDeliveryMethod(cartId, shipmentId, data)
      .then(async () => {
        await dispatch('fetchCart', cartId)
      })
  },
  setParcelLockerDeliveryMethod(
    { dispatch }: ActionContext<CartState, RootState>,
    { cartId, shipmentId, data },
  ) {
    return this.$api.orderpath.app
      .setParcelLockerDeliveryMethod(cartId, shipmentId, data)
      .then(async () => {
        await dispatch('fetchCart', cartId)
      })
  },
  changeOrderOfItemsInCart(
    { commit }: ActionContext<CartState, RootState>,
    {
      cartId,
      newOrder,
    }: { cartId: string; newOrder: Cart.Requests.ChangeOrderOfProductsOption },
  ) {
    return this.$api.orderpath.app
      .changeProductsOrder(cartId, newOrder)
      .then(() => {
        commit(mt.CHANGE_ITEMS_ORDERING, {
          cartId,
          ordering: newOrder.productIds,
        })
      })
  },
  createCheckoutForCart(
    { dispatch, rootState }: ActionContext<CartState, RootState>,
    cartId: string,
  ) {
    const { app }: any = this
    app.wait.start('creating-checkout-for-cart')

    return this.$api.orderpath.app
      .checkoutCart(cartId, rootState.stocks.defaultWarehouse, null)
      .then(async (success: AxiosResponse<any>) => {
        const checkoutId = success.data.id
        await dispatch('fetchCheckout', checkoutId)
        return Promise.resolve(checkoutId)
      })
      .catch((error: any) => Promise.reject(error))
      .finally(() => app.wait.end('creating-checkout-for-cart'))
  },

  removeProductFromCart(
    { dispatch }: ActionContext<CartState, RootState>,
    { cartId, productId }: { cartId: string; productId: Shared.ProductId },
  ) {
    return this.$api.orderpath.app
      .removeProduct(cartId, productId)
      .then(async () => {
        await dispatch('fetchCart', cartId)
        return Promise.resolve()
      })
  },
  removeSelectedProducts(
    _,
    {
      cartId,
      selectedProducts,
    }: { cartId: string; selectedProducts: Array<Shared.ProductId> },
  ) {
    if (selectedProducts && selectedProducts.length > 0) {
      return this.$api.orderpath.app
        .removeSelectedProducts(cartId, selectedProducts)
        .then(() => {
          return Promise.resolve(selectedProducts)
        })
    }
    return null
  },
  // Checkout related
  fetchCheckout(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    checkoutId: string,
  ) {
    const { app }: any = this
    app.wait.start('fetching-checkout')

    return this.$api.orderpath.app
      .getCheckout(checkoutId)
      .then((success: AxiosResponse) => {
        const checkout = success.data
        commit(mt.SET_CHECKOUT, { checkout })
        dispatch('stocks/setWarehouse', checkout.contextWarehouseId, { root: true })
        return Promise.resolve(checkout)
      })
      .finally(() => app.wait.end('fetching-checkout'))
  },
  fetchAvailableDeliveryDates(
    _,
    { checkoutId, shipmentId },
  ) {
    return this.$api.orderpath.app.getAvailableDeliveryDates(
      checkoutId,
      shipmentId,
    )
  },
  setOrderConfirmation(
    { dispatch }: ActionContext<CartState, RootState>,
    checkoutId: string,
  ) {
    return this.$api.orderpath.app
      .createOrderConfirmation(checkoutId)
      .then(async (success: AxiosResponse) => {
        const { orderConfirmationId } = success.data
        await dispatch('fetchOrderConfirmation', orderConfirmationId)
        return Promise.resolve(orderConfirmationId)
      })
  },
  setAddress(
    _,
    { checkoutId, shipmentId, addressId },
  ) {
    return this.$api.orderpath.app.setAddress(checkoutId, shipmentId, addressId)
  },
  setTemporaryAddress(
    _,
    {
      checkoutId,
      shipmentId,
      temporaryAddress,
    }: {
      checkoutId: string
      shipmentId: string
      temporaryAddress: Checkout.Requests.TemporaryAddress
    },
  ) {
    return this.$api.orderpath.app.setTemporaryAddress(
      checkoutId,
      shipmentId,
      temporaryAddress,
    )
  },
  setDeliveryDate(
    _,
    {
      checkoutId,
      shipmentId,
      deliveryDate,
    }: {
      checkoutId: string
      shipmentId: string
      deliveryDate: Checkout.Requests.SetDateOption
    },
  ) {
    return this.$api.orderpath.app.setDeliveryDate(
      checkoutId,
      shipmentId,
      deliveryDate,
    )
  },
  setAuthorizedUsers(
    _,
    { checkoutId, users },
  ) {
    return this.$api.orderpath.app.setAuthorizedUsers(checkoutId, users)
  },
  removeDeliveryDate(
    _,
    { checkoutId, shipmentId },
  ) {
    return this.$api.orderpath.app.removeDeliveryDate(checkoutId, shipmentId)
  },
  removeAddress(
    _,
    { checkoutId, shipmentId },
  ) {
    return this.$api.orderpath.app.removeAddress(checkoutId, shipmentId)
  },
  // OrderConfirmation related
  fetchOrderConfirmation(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    orderConfirmationId: string,
  ) {
    return this.$api.orderpath.app
      .getOrderConfirmation(orderConfirmationId)
      .then(
        (
          success: AxiosResponse<
            OrderConfirmation.Responses.OrderConfirmationCart
          >,
        ) => {
          const orderConfirmation = success.data
          commit(mt.SET_ORDER_CONFIRMATION, { orderConfirmation })
          dispatch('stocks/setWarehouse', orderConfirmation.contextWarehouseId, { root: true })
          return Promise.resolve(orderConfirmation)
        },
      )
  },
  submitOrderConfirmation(
    { dispatch }: ActionContext<CartState, RootState>,
    {
      orderConfirmationId,
      destinationTemplate,
    }: {
      orderConfirmationId: string
      destinationTemplate: string
    },
  ) {
    return this.$api.orderpath.app
      .submitUserOrder(orderConfirmationId, destinationTemplate)
      .then(
        async ({
          data,
        }: AxiosResponse<OrderConfirmation.Responses.SubmitOrderResult>) => {
          await dispatch('fetchOrdersList')
          return Promise.resolve(data)
        },
      )
  },
  setOrderConfirmationComments(
    { commit }: ActionContext<CartState, RootState>,
    {
      orderConfirmationId,
      comments,
    }: {
      orderConfirmationId: string
      comments: string
    },
  ) {
    return this.$api.orderpath.app
      .setOrderConfirmationComments(orderConfirmationId, comments)
      .then(() => {
        commit(mt.SET_CONFIRMATION_COMMENTS, { orderConfirmationId, comments })
        return Promise.resolve(orderConfirmationId)
      })
  },
  submitUserRfq(_, orderConfirmationId: string) {
    return this.$api.orderpath.app
      .submitUserRfq(orderConfirmationId)
      .then(
        (
          success: AxiosResponse<OrderConfirmation.Responses.RfqNegotiationId>,
        ) => {
          const orderConfirmationId = success.data.rfqNegotiationId
          return Promise.resolve(orderConfirmationId)
        },
      )
  },
  sendForAuthorization(_, { destinationUserId, orderConfirmationId }) {
    return this.$api.orderpath.app.sendForAuthorization(
      destinationUserId,
      orderConfirmationId,
    )
  },
  fetchOrderDetail(
    { commit }: ActionContext<CartState, RootState>,
    orderId: string,
  ) {
    return this.$api.orders.app
      .getDetailOrder(orderId)
      .then((results: AxiosResponse<Orders.DetailedOrder.Order>) => {
        commit(mt.SET_ORDER_DETAIL, results.data)
        return Promise.resolve(results.data)
      })
  },
  fetchOrdersList(ctx: ActionContext<CartState, RootState>) {
    const {
      start,
      rows,
      search,
      startDate,
      endDate,
      shipmentStatuses,
    }: OrdersHistoryPagination = ctx.state.ordersHistory
    const { app }: any = this
    app.wait.start('orders-fetching')

    return this.$api.orders.app
      .getOrdersList(start, rows, search, startDate, endDate, shipmentStatuses)
      .then((results: any) => {
        const response: Orders.OrderPagination = results.data
        const ordersIds = response.orders.map((p: any) => p.id)
        ctx.commit(mt.SET_PAGINATED_ORDERS, ordersIds)
        ctx.commit(mt.SET_ORDERS, response.orders)
        ctx.commit(mt.SET_TOTAL, response.totalNumberOfOrders)
        return Promise.resolve(response)
      })
      .finally(() => app.wait.end('orders-fetching'))
  },
  fetchPaginatedOrders(ctx: ActionContext<CartState, RootState>) {
    const {
      page,
      loadedPages,
    }: OrdersHistoryPagination = ctx.state.ordersHistory
    if (!(page in loadedPages)) {
      return ctx.dispatch('fetchOrdersList')
    }
    return Promise.resolve()
  },
  searchChanged(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    search: string | null,
  ) {
    commit(mt.SEARCH_CHANGED, search)
    commit(mt.SET_PAGE, 1)
    commit(mt.CLEAR_LOADED)
    return dispatch('fetchPaginatedOrders')
  },
  changePageOnlineOrdersList(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE, page)
    return dispatch('fetchPaginatedOrders')
  },
  fetchRfqDetail(
    { commit }: ActionContext<CartState, RootState>,
    rfqNegotiationId: string,
  ) {
    return this.$api.orderpath.app
      .getRfqDetail(rfqNegotiationId)
      .then((results: AxiosResponse<Rfq.RfqDetailed.DetailedRfqCart>) => {
        commit(mt.SET_RFQ_DETAIL, results.data)
        return Promise.resolve(results.data)
      })
  },
  fetchRfqList({ state, commit }: ActionContext<CartState, RootState>) {
    const {
      start,
      rows,
      search,
      startDate,
      endDate,
    }: OrdersHistoryPagination = state.rfqsList
    const { app }: any = this
    app.wait.start('rfq-fetching')

    return this.$api.orderpath.app
      .getRfqList(start, rows, search, startDate, endDate)
      .then((results: any) => {
        const response: Rfq.RfqPagination = results.data
        const ordersIds = response.rfqs.map((p: any) => p.rfqNegotiationId)
        commit(mt.SET_PAGINATED_RFQ, ordersIds)
        commit(mt.SET_RFQ, response.rfqs)
        commit(mt.SET_TOTAL_RFQ, response.totalNumberOfOrders)
        return Promise.resolve(response)
      })
      .finally(() => app.wait.end('rfq-fetching'))
  },
  fetchPaginatedRfqList(ctx: ActionContext<CartState, RootState>) {
    const {
      page,
      loadedPages,
    }: OrdersHistoryPagination = ctx.state.rfqsList
    if (!(page in loadedPages)) {
      return ctx.dispatch('fetchRfqList')
    }
    return Promise.resolve()
  },
  searchChangedRfqList(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    search: string | null,
  ) {
    commit(mt.SEARCH_CHANGED_RFQ, search)
    commit(mt.SET_PAGE_RFQ, 1)
    commit(mt.CLEAR_LOADED_RFQ)
    return dispatch('fetchPaginatedRfqList')
  },
  changePageRfqList(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE_RFQ, page)
    return dispatch('fetchPaginatedRfqList')
  },
  // OfflineOrders
  fetchOfflineOrderDetails(
    { commit }: ActionContext<CartState, RootState>,
    orderId: string,
  ) {
    return this.$api.orderpath.app
      .getSingleOfflineOrder(orderId)
      .then((results: AxiosResponse<Orders.OfflineOrder>) => {
        commit(mt.SET_OFFLINE_ORDERS_DETAIL, results.data)
        return Promise.resolve(results.data)
      })
  },
  fetchOfflineOrdersList({
    state,
    commit,
  }: ActionContext<CartState, RootState>) {
    const {
      start,
      rows,
      search,
      startDate,
      endDate,
    }: OrdersHistoryPagination = state.offlineOrdersList
    const { app }: any = this
    app.wait.start('offline-orders-fetching')

    return this.$api.orderpath.app
      .getOfflineOrder(start, rows, search, startDate, endDate)
      .then((results: any) => {
        const response: Orders.OrderPagination = results.data
        const ordersIds = response.orders.map((p: any) => p.id)
        commit(mt.SET_PAGINATED_OFFLINE_ORDERS, ordersIds)
        commit(mt.SET_OFFLINE_ORDERS, response.orders)
        commit(mt.SET_TOTAL_OFFLINE_ORDERS, response.totalNumberOfOrders)
        return Promise.resolve(response)
      })
      .finally(() => app.wait.end('offline-orders-fetching'))
  },
  fetchPaginatedOfflineOrdersList({
    state,
    dispatch,
  }: ActionContext<CartState, RootState>) {
    const {
      page,
      loadedPages,
    }: OrdersHistoryPagination = state.offlineOrdersList
    if (!(page in loadedPages)) {
      return dispatch('fetchOfflineOrdersList')
    }
    return Promise.resolve()
  },
  searchChangedOfflineOrdersList(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    search: string | null,
  ) {
    commit(mt.SEARCH_CHANGED_OFFLINE_ORDERS, search)
    commit(mt.SET_PAGE_OFFLINE_ORDERS, 1)
    commit(mt.CLEAR_LOADED_OFFLINE_ORDERS)
    return dispatch('fetchPaginatedOfflineOrdersList')
  },
  changePageOfflineOrdersList(
    { commit, dispatch }: ActionContext<CartState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE_OFFLINE_ORDERS, page)
    return dispatch('fetchPaginatedOfflineOrdersList')
  },
  fetchOrderStatuses(
    { commit }: ActionContext<CartState, RootState>,
    {
      startDate,
      endDate,
      search,
    }: {
      startDate: string | null
      endDate: string | null
      search: string | null
    } = {
      startDate: null,
      endDate: null,
      search: null,
    },
  ) {
    return this.$api.orders.app
      .getOrderStatuses(startDate, endDate, search)
      .then(({ data }: AxiosResponse<Array<Orders.OrderStatusDto>>) => {
        commit(mt.SET_ORDERS_STATUSES, data)
        return Promise.resolve(data)
      })
  },
  setSplitChoice(
    _: ActionContext<CartState, RootState>,
    {
      cartId,
      splitChoice,
    }: {
      cartId: string
      splitChoice: Shared.SplitChoiceEnum
    },
  ) {
    return this.$api.orderpath.app.setSplitChoice(cartId, splitChoice)
  },
  setCouponCode(
    _,
    {
      cartId,
      couponCode,
    }: {
      cartId: string
      couponCode: Cart.Requests.SetCartCouponOption
    },
  ) {
    return this.$api.orderpath.app.setCouponCodeCart(couponCode, cartId)
  },
  deleteCouponCode(
    _,
    cartId: string,
  ) {
    return this.$api.orderpath.app.deleteCouponCode(cartId)
  },
  fetchPromoPoints() {
    return this.$api.pricing.app.getPromoPoints().then((response: AxiosResponse<Array<PromoPointRestView>>) => {
      return Promise.resolve(response.data)
    })
  },
  setPromoPoints(_, { cartId, usedPoints }) {
    return this.$api.orderpath.app
      .setPromoPoints(cartId, { usedPoints: usedPoints })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  deletePromoPoints(_, cartId: string) {
    return this.$api.orderpath.app.removePromoPoints(cartId)
  },
  fetchManualCampaigns(_, cartId: string) {
    return this.$api.orderpath.app.getManualCampaigns(cartId)
  },
  updateManualCampaigns(_, { cartId, campaigns }) {
    return this.$api.orderpath.app.updateManualCampaigns(cartId, campaigns)
  },
  resetManualCampaigns(_, cartId) {
    return this.$api.orderpath.app.resetManualCampaigns(cartId)
  },
  setIsManualPromotionsListCollapsed({ commit }, isOpen: boolean) {
    commit('SET_IS_MANUAL_PROMOTIONS_LIST_COLLAPSED', isOpen)
  },
}
export default actions

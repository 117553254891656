import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'
import { CartState } from './types'

export const state = (): CartState => ({
  carts: {},
  basicCarts: {},
  selectedCart: null,
  fetchingDeliveryMethods: false,
  checkouts: {},
  orderConfirmations: {},
  cartsCheckouts: {},
  cartsOrderConfirmations: {},
  orderStatuses: {},
  orders: {},
  ordersHistory: {
    start: 0,
    rows: 30,
    page: 1,
    startDate: null,
    endDate: null,
    total: 0,
    search: null,
    loadedPages: {},
    shipmentStatuses: null,
  },
  ordersDetail: {},
  rfqs: {},
  rfqsList: {
    start: 1,
    rows: 30,
    page: 1,
    startDate: null,
    endDate: null,
    total: 0,
    search: null,
    shipmentStatuses: null,
    loadedPages: {},
  },
  rfqsDetail: {},
  offlineOrders: {},
  offlineOrdersList: {
    start: 0,
    rows: 30,
    page: 1,
    startDate: null,
    endDate: null,
    total: 0,
    search: null,
    shipmentStatuses: null,
    loadedPages: {},
  },
  offlineOrdersDetail: {},
  selectedOrder: null,
  selectedOrderConfirmation: null,
  additionalCostTypes: {},
  shipmentsValidity: {},
  isManualPromotionsListCollapsed: false,
})

const namespaced: boolean = true

const cart: Module<CartState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export default cart

import Vue from 'vue'
import { EnsureClientSide } from '~/utils/clientSideDecorator'
import translateBackendError, { ErrorHandlerType } from '~/utils/backendErrorTranslate'

export interface UtilsService {
  readonly isPurchaseEnabledOrAuthenticated: boolean;
  readonly isAnonymousAndPurchaseDisabled: boolean;
  readonly isB2BAndPlatformIsNotOpened: boolean;
  readonly isB2B: boolean;
  readonly isIndexPage: boolean;
  readonly hasPricesEnabled: boolean;
  isMobileOrTablet(resolution: string): boolean;
  isMobile(resolution: string): boolean;
  hideKeyboard(): void;
  preloadImage(src: string): void;
  errorHandler(error: ErrorHandlerType): string;
}

export class Utils implements UtilsService {
  ctx: any;

  constructor(context: any) {
    this.ctx = context
  }

  private get app() {
    return this.ctx.app
  }

  public get isPurchaseEnabledOrAuthenticated(): boolean {
    return this.app.$auth.isAuthenticated || this.app.$tenantConfig.isPlatformOpened
  }

  public get isAnonymousAndPurchaseDisabled(): boolean {
    return !this.app.$auth.isAuthenticated && !this.app.$tenantConfig.isPurchaseEnabled
  }

  public get isB2BAndPlatformIsNotOpened(): boolean {
    return this.app.store.getters['account/isCompanyCustomerType'] && !this.app.$tenantConfig.isPlatformOpened
  }

  public get isB2B(): boolean {
    return this.app.store.getters['account/isCompanyCustomerType']
  }

  public get isIndexPage(): boolean {
    return !!(
      this.app.router.currentRoute.name &&
      this.app.router.currentRoute.name.includes('index')
    )
  }

  public get hasPricesEnabled(): boolean {
    if (!this.app.$auth.isAuthenticated) {
      return true
    }
    return this.app.$auth.hasPermissions(['APP_VIEW_PRICES'], true)
  }
  public isMobileOrTablet(resolution: string): boolean {
    return ['xs', 'sm', 'md'].includes(resolution)
  }
  public isMobile(resolution: string): boolean {
    return ['xs', 'sm'].includes(resolution)
  }

  @EnsureClientSide
  public hideKeyboard() {
    // Source: https://stackoverflow.com/questions/8335834/how-can-i-hide-the-android-keyboard-using-javascript
    // this set timeout needed for case when hideKeyborad
    // is called inside of 'onfocus' event handler
    setTimeout(() => {
      // creating temp field
      const field = document.createElement('input')
      field.setAttribute('type', 'text')
      // hiding temp field from peoples eyes
      // -webkit-user-modify is nessesary for Android 4.x
      field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;')
      document.body.prepend(field)

      // adding onfocus event handler for out temp field
      field.onfocus = () => {
        // this timeout of 200ms is nessasary for Android 2.3.x
        setTimeout(() => {
          field.setAttribute('style', 'display:none;')
          setTimeout(() => {
            document.body.removeChild(field)
            document.body.focus()
          }, 14)
        }, 200)
      }
      // focusing it
      field.focus()
    }, 50)
  }

  public preloadImage(src: string): void {
    new Image().src = src
  }

  public errorHandler(error: ErrorHandlerType): string {
    return translateBackendError(error, this.ctx)
  }
}
export default (ctx: any, inject: Function) => {
  inject('utils', Vue.observable(new Utils(ctx)))
}

import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { en, es, hu, it, lt, pl, ro } from '@one/vuejs-datepicker/dist/locale'
import { NuxtAppOptions, NuxtState } from '@nuxt/types/app'
import customLanguageLoader from '../lang/custom'

export const DEFAULT_LANGUAGE = 'en'
export const defaultLocales = {
  en: () => import('../lang/en-US'),
  de: () => import('../lang/de-DE'),
  es: () => import('../lang/es-ES'),
  hu: () => import('../lang/hu-HU'),
  it: () => import('../lang/it-IT'),
  lt: () => import('../lang/lt-LT'),
  pl: () => import('../lang/pl-PL'),
  ro: () => import('../lang/ro-RO'),
}
export const localesDatepicker = {
  en, es, hu, it, lt, pl, ro,
}

export default async function ({ app, req, nuxtState }: { app: NuxtAppOptions, req: any, nuxtState: NuxtState }) {
  const configuration = req ? req.configuration : nuxtState.state.config.configuration
  const locale = configuration.lang
  const loadCustomMessagesOrGoBackToDefault = async () => {
    const messages = await customLanguageLoader(app.context)
    if (!messages) {
      if (locale !== DEFAULT_LANGUAGE) {
        // @ts-ignore
        const loadedLocale = await defaultLocales[locale]()
        await app.i18n.setLocale('custom')
        return loadedLocale.default
      } else {
        const loadedDefaultLocale = await defaultLocales[DEFAULT_LANGUAGE]()
        await app.i18n.setLocale(DEFAULT_LANGUAGE)
        return loadedDefaultLocale.default
      }
    } else {
      await app.i18n.setLocale('custom')
      return messages
    }
  }
  const defaultLocalization = await import(`vee-validate/dist/locale/${locale}`)
  const customMessages = await loadCustomMessagesOrGoBackToDefault()
  // TODO: after upgrade vee-validate version move it and use configure method to set options
  Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    locale: locale,
    dictionary: {
      [locale]: {
        ...defaultLocalization,
        attributes: {
          ...defaultLocalization.attributes,
          ...customMessages.fields_lang,
        },
      },
    },
  })
}

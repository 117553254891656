import { RMA } from '@one/types'
import { RMAState } from './types'

export default {
  getComplaintReason: (state: RMAState) => state.complaintReasons,
  getComplaints: (state: RMAState) => state.complaints,
  getComplaint: (state: RMAState) => (
    complaintNumber: string,
  ): RMA.App.Response.ComplaintDto | undefined =>
    state.complaints &&
    (state.complaints[complaintNumber] as RMA.App.Response.ComplaintDto),
}

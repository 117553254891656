import Vue from 'vue'
import { Store } from 'vuex'
import ConfigModule from './state'
import { AccessMode, Configuration, State } from './state/interfaces'
import { Mutations } from './state/mutations'

export interface TenantConfig {
  store: Store<any>;
  stateName: string;
  setTenantKey(key: string): TenantConfig;
  setCurrency(key: string): TenantConfig;
  setConfiguration(config: Configuration): TenantConfig;
  setAccessMode(accessMode: AccessMode): TenantConfig;
  setIsDev(isDev: boolean): TenantConfig;
  readonly tenantKey: string | null;
  readonly isRegistrationEnabled: boolean;
  readonly isB2CRegistrationEnabled: boolean;
  readonly isPurchaseEnabled: boolean;
  readonly accessMode: AccessMode | null;
  readonly shouldShowProductId: boolean | null;
  readonly configuration: Configuration | null;
  readonly isPlatformOpened: boolean;
  readonly isPlatformClosed: boolean;
  readonly isCatalogOpened: boolean;
  readonly isDev: boolean | null;
}

export class Config implements TenantConfig {
  store: Store<any>
  stateName: string = 'config'

  constructor(context: any) {
    this.store = context.store
    ConfigModule.register(this.store, this.stateName)
  }

  private dispatch<T>(action: string, payload?: any): Promise<T> {
    return this.store.dispatch(`${this.stateName}/${action}`, payload)
  }

  private commit(mutation: string, payload?: any) {
    this.store.commit(`${this.stateName}/${mutation}`, payload)
  }

  private get state(): State {
    return this.store.state[this.stateName]
  }

  public setTenantKey(key: string) {
    this.commit(Mutations.SET_TENANT_KEY, key)
    return this
  }

  public setCurrency(currency: string) {
    this.commit(Mutations.SET_CURRENCY, currency)
    return this
  }

  public setConfiguration(config: Configuration) {
    this.commit(Mutations.SET_CONFIGURATION, config)
    return this
  }

  public setAccessMode(accessMode: AccessMode) {
    this.commit(Mutations.SET_ACCESS_MODE, accessMode)
    return this
  }

  public setIsDev(isDev: boolean) {
    this.commit(Mutations.SET_IS_DEV, isDev)
    return this
  }

  public get tenantKey(): string | null {
    return this.state.tenantKey
  }

  public get isDev(): boolean | null {
    return this.state.isDev
  }

  public get isRegistrationEnabled(): boolean {
    return !!this.state.isRegistrationEnabled
  }

  public get isB2CRegistrationEnabled(): boolean {
    return !!this.state.isB2CRegistrationEnabled
  }

  public get accessMode(): AccessMode | null {
    return this.state.accessMode
  }

  public get shouldShowProductId(): boolean | null {
    return this.state.showProductId
  }

  public get configuration(): Configuration | null {
    return this.state.configuration
  }

  public get isPlatformOpened(): boolean {
    return this.accessMode === AccessMode.OPEN
  }

  public get isPlatformClosed(): boolean {
    return this.accessMode === AccessMode.CLOSED
  }

  public get isCatalogOpened(): boolean {
    return this.accessMode === AccessMode.OPEN_CATALOG || this.accessMode === AccessMode.OPEN
  }

  public get isPurchaseEnabled(): boolean {
    return this.accessMode !== AccessMode.OPEN_CATALOG
  }
}
export default (ctx: any, inject: Function) => {
  inject('tenantConfig', Vue.observable(new Config(ctx)))
}

/* eslint-disable no-underscore-dangle */
import Vue from 'vue'

const BlackDirective = {
  componentUpdated(el, binding) {
    const id = `backdrop-${binding.value.uid}`
    const element = document.createElement('div')
    element.classList.add('backdrop')
    element.id = id
    if (binding.value.booleanValue) {
      el.style.position = 'relative'
      if (!document.getElementById(id)) {
        el.parentElement.appendChild(element)
      }
    } else {
      el.style.zIndex = null
      el.style.position = null
      if (document.getElementById(id)) {
        setTimeout(() => {
          document.getElementById(id)
            .remove()
        }, binding.value.delay || 0)
      }
    }
  },
}
let isInstalled = false
if (!isInstalled) {
  isInstalled = true
  Vue.directive('black', BlackDirective)
}

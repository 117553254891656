import { ActionTree } from 'vuex'
import { RootState } from '..'
import { MUTATIONS } from './mutations'
import { State } from '.'

export const actions: ActionTree<State, RootState> = {
  async fetchPaymentStatus({ commit }, orderId: string): Promise<void> {
    const { data } = await this.$api.payments.app.getPaymentStatus(orderId)
    commit(MUTATIONS.SET_PAYMENT_SECRET, data.clientSecret)
    commit(MUTATIONS.SET_PAYMENT_STATUS, data.status)
    commit(MUTATIONS.SET_PAYMENT_URL, data.paymentUrl)
  },
}
export default actions

import { Module } from 'vuex'
import { PaymentStatusType } from '@one/types'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'

export interface State {
  paymentUrl: string
  status: PaymentStatusType
  secret: string
}

export const state = (): State => ({
  paymentUrl: '',
  status: '' as PaymentStatusType,
  secret: '',
})

const namespaced: boolean = true

const payments: Module<State, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export default payments

export default {
  methods: {
    $isFieldValid(ref: any): boolean | null {
      // @ts-ignore
      const field = this.veeFields && this.veeFields[ref]
      if (field && (field.dirty || field.validated)) {
        // @ts-ignore
        return !this.errors.has(ref)
      }
      return null
    },
  },
}


  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { Wishlist } from '@one/types/dist/orderpath/app'
  import { CreateElement, RenderContext, VNode } from 'vue'
  import { DefaultProps } from 'vue/types/options'
  import { WishlistInterface } from '@/components/organisms/product/OneProductTileRightActions.vue'

  @Component({
    // @ts-ignore
    functional: true,
  })
  export default class OneProductDropdownAddToWishlist extends Vue {
    @Prop({
      type: Array,
      required: true,
    })
    wishlists!: Array<Wishlist.Responses.BasicWishlist>

    @Prop({
      type: Boolean,
      default: false,
    })
    disabled?: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    progress?: boolean

    @Prop({
      required: true,
      type: String,
    })
    productId!: string

    render(createElement: CreateElement, context: RenderContext<DefaultProps>): VNode {
      const disabled: boolean = context.props.progress || context.props.disabled || false
      const progress: boolean = context.props.progress || false
      const wishlists: Array<Wishlist.Responses.BasicWishlist> = context.props.wishlists || []
      const onAddToWishlistClick = (wishlistId?: WishlistInterface) => {
        const handler: Function | Function[] = context.listeners['add-to-wishlist']
        if (handler && !Array.isArray(handler)) {
          handler(wishlistId)
        }
      }
      const generateDropdownItem = (index: number, wishlist: Wishlist.Responses.BasicWishlist): VNode => {
        return createElement(
          'li',
          {
            props: {
              key: index,
            },
            on: {
              click: () => onAddToWishlistClick(wishlist),
            },
          },
          [
            createElement('a', {
              class: ['dropdown-item'],
              domProps: {
                target: '_self',
                textContent: wishlist.name,
                role: 'menuitem',
              },
            }),
          ],
        )
      }
      const generateDropdown = () => {
        if (!disabled) {
          return wishlists.map((wishlist: Wishlist.Responses.BasicWishlist, idx: number) => {
            return generateDropdownItem(idx, wishlist)
          })
        }
      }
      const generateButton = (): VNode => {
        return createElement(
          'div',
          {
            directives: [
              {
                name: 'one-testid',
                value: `core:product-card:add-product-${context.props.productId}-to-wishlist:div`,
              },
            ],
            class: ['btn-content'],
          },
          [labelButton(), progress && loadingIcon()],
        )
      }
      const labelButton = (): VNode => {
        return createElement(
          'div',
          {
            class: [progress && 'btn-label-hide'],
          },
          [
            context.children,
            createElement('i', {
              class: ['ml-0', 'icon-box-add'],
            }),
          ],
        )
      }
      const loadingIcon = (): VNode => {
        return createElement('one-icon-loading')
      }
      return wishlists.length > 1
        ? createElement(
          'b-dropdown',
          {
            props: {
              split: true,
              'no-flip': true,
              'no-caret': true,
              'menu-class': !disabled ? 'dropdown-menu-buttons' : null,
              disabled: disabled,
            },
            on: {
              click: () => onAddToWishlistClick(),
            },
            class: {
              'disabled-btn': disabled,
            },
          },
          [
            createElement(
              'template',
              {
                slot: 'button-content',
              },
              [generateButton()],
            ),
            generateDropdown(),
          ],
        )
        : createElement(
          'b-btn',
          {
            props: {
              disabled: disabled,
            },
            on: {
              click: () => onAddToWishlistClick(),
            },
            class: {
              'disabled-btn': disabled,
              'px-2': true,
            },
          },
          [generateButton()],
        )
    }
  }

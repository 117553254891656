import Vue from 'vue'

let isInstalled = false
if (!isInstalled) {
  isInstalled = true
  Vue.mixin({
    methods: {
      easeInOut(currentTime, start, change, duration) {
        // eslint-disable-next-line no-param-reassign
        currentTime /= duration / 2
        if (currentTime < 1) {
          return change / 2 * currentTime * currentTime + start
        }
        // eslint-disable-next-line no-param-reassign
        currentTime -= 1
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
      },
      scrollTop(duration, timeout = 0) {
        return new Promise((resolve) => {
          setTimeout(async () => {
            await this.scrollTo(document.body, 0, duration)
            return resolve()
          }, timeout)
        })
      },
      scrollToElement(element, topOffset) {
        const y = element.getBoundingClientRect().top + window.scrollY - topOffset
        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      },
      scrollTo(element, to, duration) {
        const start = element.scrollTop
        const change = to - start
        const increment = 20

        return new Promise((resolve) => {
          const animateScroll = (elapsedTime) => {
            // eslint-disable-next-line no-param-reassign
            elapsedTime += increment
            element.scrollTop = this.easeInOut(elapsedTime, start, change, duration)
            if (elapsedTime < duration) {
              setTimeout(() => {
                animateScroll(elapsedTime)
              }, increment)
            } else {
              resolve()
            }
          }
          animateScroll(0)
        })
      },
      scrollObserver(param, call) {
        if (!this.$isServer) {
          const element = this.$refs[param].$el || this.$refs[param]
          this.observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio > 0) {
              call(true)
            } else {
              call(false)
            }
          })
          this.observer.observe(element)
        }
      },
    },
  })
}

import { AuthService } from '@one/core'
import Vue from 'vue'

export default async (ctx, inject) => {
  const service = await AuthService.install(Vue, {
    store: ctx.store,
    useCookies: true,
    onInit: async (service) => {
      if (process.client && !service.isAuthenticated) {
        const result = await service.initSessionFromStorage()
        if (result) {
          await ctx.store.dispatch('fetchRequiredData')
          await ctx.store.dispatch('fetchAuthenticatedUserData')
        }
      }
    },
    handlers: {
      login: async (credentials) => {
        const response = await ctx.app.$api.authserver.app.authentication(credentials)
        return response.data
      },
      refreshTokens: async (token) => {
        const response = await ctx.app.$api.authserver.app.tokenRefresh({
          refreshToken: token,
        })
        return response.data
      },
    },
  }, false)
  service.$on('auth:login', () => {
    ctx.app.$api.catalog.app.invalidate(['auth-optional'])
  })
  service.$on('auth:logout', async () => {
    await ctx.store.dispatch('clearStore')
    ctx.app.$api.invalidateAllInstances()
    ctx.store.commit('layout/SET_PRICE_GROSS', true)
    await ctx.store.dispatch('fetchRequiredData')
    ctx.store.commit('stocks/SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED')
    await ctx.store.dispatch('fetchHeavyData')
  })
  if (process.client) {
    let attemptsHandler = null
    let impersonateAttempts = 0
    const onKeyPress = (e) => {
      if (e.ctrlKey && e.key === 'i') {
        clearTimeout(attemptsHandler)
        impersonateAttempts += 1
        attemptsHandler = setTimeout(() => {
          impersonateAttempts = 0
        }, 300)
        if (impersonateAttempts > 3) {
          clearTimeout(attemptsHandler)
          // eslint-disable-next-line no-alert
          const email = prompt('Enter email to impersonate')
          if (email) {
            ctx.store.dispatch('account/impersonate', email)
          }
          impersonateAttempts = 0
        }
      }
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('keypress', onKeyPress)
    }
  }
  inject('auth', service)
}

import { MutationTree } from 'vuex'
import { AccessMode, Configuration, State } from './interfaces'

export enum Mutations {
  SET_TENANT_KEY = 'SET_TENANT_KEY',
  SET_CURRENCY = 'SET_CURRENCY',
  SET_CONFIGURATION = 'SET_CONFIGURATION',
  SET_ACCESS_MODE = 'SET_ACCESS_MODE',
  SET_IS_DEV = 'SET_IS_DEV',
}
export default {
  [Mutations.SET_TENANT_KEY](state: State, key: string) {
    state.tenantKey = key
  },
  [Mutations.SET_CURRENCY](state: State, currency: string) {
    state.currency = currency
  },
  [Mutations.SET_CONFIGURATION](state: State, configuration: Configuration) {
    const {
      b2cRegistrationEnabled,
      goodsIssuedNoteEnabled,
      registrationEnabled,
      theme,
    } = configuration
    state.isB2CRegistrationEnabled = b2cRegistrationEnabled
    state.isRegistrationEnabled = registrationEnabled
    state.showGoodsIssuedNotes = goodsIssuedNoteEnabled
    state.showProductId = theme.showProductId
    state.configuration = configuration // FIXME: To jest powielenie tego co w state CMS!!
  },
  [Mutations.SET_ACCESS_MODE](state: State, mode: AccessMode) {
    state.accessMode = mode
  },
  [Mutations.SET_IS_DEV](state: State, isDev: boolean) {
    state.isDev = isDev
  },
} as MutationTree<State>

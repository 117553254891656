
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { Cart } from '@one/types/dist/orderpath/app'
  import { CreateElement, RenderContext, VNode } from 'vue'
  import { DefaultProps } from 'vue/types/options'

  @Component({
    // @ts-ignore
    functional: true,
  })
  export default class OneProductDropdownAddToCart extends Vue {
    @Prop({
      type: Array,
      required: true,
    })
    carts!: Array<Cart.Responses.BasicCartInfo>;

    @Prop({
      type: Boolean,
      default: false,
    })
    disabled?: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    progress?: boolean;

    @Prop({
      required: true,
      type: String,
    })
    productId!: string

    render(createElement: CreateElement, context: RenderContext<DefaultProps>): VNode {
      const disabled: boolean = context.props.progress || context.props.disabled
      const progress: boolean = context.props.progress
      const carts: Array<Cart.Responses.BasicCartInfo> = context.props.carts || []
      const background: any = {
        '--bg-color': 'var(--btn-color);',
      }
      const onAddToCartClick = (cartId?: string) => {
        const handler: Function | Function[] = context.listeners['add-to-cart']
        if (handler && !Array.isArray(handler)) {
          handler(cartId)
        }
      }
      const generateDropdownItem = (index: number, cart: Cart.Responses.BasicCartInfo): VNode => {
        return createElement('li', {
          props: {
            key: index,
          },
          on: {
            click: () => onAddToCartClick(cart.id),
          },
        }, [createElement('a', {
          class: ['dropdown-item'],
          domProps: {
            target: '_self',
            textContent: cart.name,
            role: 'menuitem',
          },
        })])
      }
      const generateDropdown = () => {
        if (!disabled) {
          return carts.map((cart: Cart.Responses.BasicCartInfo, idx: number) => {
            return generateDropdownItem(idx, cart)
          })
        }
      }
      const generateButton = (): VNode => {
        return createElement('div', {
          directives: [
            {
              name: 'one-testid',
              value: `core:product-card:add-product-${context.props.productId}-to-cart:div`,
            },
          ],
          class: ['btn-content'],
        }, [labelButton(), progress && loadingIcon()])
      }
      const labelButton = (): VNode => {
        return createElement('div', {
          class: [progress && 'btn-label-hide'],
        }, [context.children, createElement('i', {
          class: ['ml-1', 'icon-ecommerce-cart-download'],
        })])
      }
      const loadingIcon = (): VNode => {
        return createElement('one-icon-loading')
      }
      return carts.length > 1
        ? createElement('b-dropdown', {
          props: {
            split: true,
            'no-flip': true,
            'menu-class': !disabled ? 'dropdown-menu-buttons' : null,
            disabled: disabled,
          },
          style: background,
          on: {
            click: () => onAddToCartClick(),
          },
          class: {
            'disabled-btn': disabled,
          },
        }, [
          createElement('template', {
            slot: 'button-content',
          }, [
            generateButton(),
          ]),
          generateDropdown(),
        ])
        : createElement('b-btn', {
          props: {
            disabled: disabled,
          },
          style: background,
          on: {
            click: () => onAddToCartClick(),
          },
          class: {
            'disabled-btn': disabled,
          },
        }, [
          generateButton(),
        ])
    }
  }













































  import { Component, Prop } from 'vue-property-decorator'
  import OneInputTooltip from './OneInputTooltip.vue'

  @Component
  export default class OneInputWithControls extends OneInputTooltip {
    name: string = 'one-input-with-controls'

    @Prop({
      type: Boolean,
    })
    notValid?: boolean

    incrementValue(): void {
      const value = this.quantityValue + this.intervalValue
      this.onInput(value)
    }

    decrementValue(): void {
      if (this.quantityValue === this.minValue) {
        return
      }
      const value = this.quantityValue - this.intervalValue
      this.onInput(value)
    }
  }

import { MutationTree } from 'vuex'
import { CMS, Pagination } from '@one/types'
import { arrayToObject } from '@one/core'
import Vue from 'vue'
import { CmsState } from './types'
import PageDto = CMS.Responses.PageDto
import PagesDto = CMS.Responses.PagesDto
import MainConfigurationQuery = CMS.Responses.MainConfigurationQuery
import PageContentDto = CMS.Responses.PageContentDto
import StaticPageDto = CMS.Responses.StaticPageDto

export const mt = {
  SET_MAIN_PAGE_CONFIGURATION: 'SET_MAIN_PAGE_CONFIGURATION',
  SET_PAGES: 'SET_PAGES',
  SET_PAGE_CONTENT: 'SET_PAGE_CONTENT',
  CLEAR_STORE: 'CLEAR_STORE',
  SET_ARTICLES_LIST: 'SET_ARTICLES_LIST',
  SET_ARTICLE: 'SET_ARTICLE',
  SET_ARTICLE_TAGS: 'SET_ARTICLE_TAGS',
  SET_GRID_TEMPLATE: 'SET_GRID_TEMPLATE',
  SET_STATIC_PAGE: 'SET_STATIC_PAGE',
}

export const mutations: MutationTree<CmsState> = {
  [mt.SET_MAIN_PAGE_CONFIGURATION](
    state: CmsState,
    configuration: MainConfigurationQuery,
  ) {
    state.configuration = configuration
  },
  [mt.SET_ARTICLES_LIST](
    state: CmsState,
    payload: Pagination.Pager<CMS.Responses.ArticleLink>,
  ) {
    Vue.set(state.articles, 'articlesList', payload)
  },
  [mt.SET_ARTICLE](state: CmsState, payload: CMS.Responses.ArticleLink) {
    if (!state.articles.byId) { state.articles.byId = {} }
    Vue.set(state.articles.byId, payload.slug, { ...payload })
  },
  [mt.SET_ARTICLE_TAGS](
    state: CmsState,
    payload: Array<CMS.Articles.Tags.ArticleTag>,
  ) {
    Vue.set(state.articles, 'articleTags', {
      ...arrayToObject(payload, 'name'),
    })
  },
  [mt.SET_PAGES](state: CmsState, pages: PagesDto) {
    state.pages = pages
      .map((page: PageDto) => ({ [page.url]: { ...page } }))
      .reduce((x: any, y: any) => ({ ...x, ...y }), {})
  },
  [mt.SET_PAGE_CONTENT](state: CmsState, page: PageContentDto) {
    Vue.set(state.pages, page.url, page)
  },
  [mt.SET_STATIC_PAGE](state: CmsState, page: StaticPageDto) {
    Vue.set(state.staticPages, page.slug, page)
  },
  [mt.CLEAR_STORE]() {
    // we dont want to clear CMS data
  },
  [mt.SET_GRID_TEMPLATE](
    state: CmsState,
    {
      gridSlot,
      content,
    }: { gridSlot: CMS.Grid.GridSlot; content: CMS.Grid.GridElement },
  ) {
    Vue.set(state.grids, gridSlot, content)
  },
}
export default mutations

import Vue from 'vue'

export interface WindowPlugins {
  onMounted: (component: string, vm: typeof Vue | any, data?: any) => any,
  onUnmounted: (component: string) => void,
}
export default (app, inject) => {
  const bus = new Vue()
  const onMounted = (component: string, cb: () => void) => {
    bus.$on(`onMounted:${component}`, cb)
    return {
      unsubscribe: () => {
        bus.$off(`onMounted:${component}`, cb)
      },
    }
  }
  const onUnmounted = (component: string, cb: () => void) => bus.$once(`onUnmounted:${component}`, cb)
  // @ts-ignore
  window.onNuxtReady((app) => {
    const pluginsLoaded = new CustomEvent('one:loaded', {
      detail: {
        onMounted,
        onUnmounted,
        app,
      },
    })
    window.dispatchEvent(pluginsLoaded)
  })
  inject('plugins', {
    onMounted: (component: string, vm: typeof Vue, data?: any) => setTimeout(() => bus.$emit(`onMounted:${component}`, {
      component: vm,
      data,
    }), 250),
    onUnmounted: (component: string) => bus.$emit(`onUnmounted:${component}`),
  } as WindowPlugins)
}

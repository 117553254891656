import { MutationTree } from 'vuex'
import Vue from 'vue'
import { Stocks } from '@one/types'
import { MappedProductStocks, StocksState } from './types'
import Warehouse = Stocks.Warehouse
import ProductsStocks = Stocks.Responses.ProductsStocks
import ProductStocks = Stocks.Responses.ProductStocks

export const mt = {
  SET_WAREHOUSES: 'SET_WAREHOUSES',
  SET_WAREHOUSE: 'SET_WAREHOUSE',
  SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED:
    'SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED',
  SET_PRODUCT_STOCK: 'SET_PRODUCT_STOCK',
  SET_PRODUCTS_STOCKS: 'SET_PRODUCTS_STOCKS',
  SET_DEFAULT_WAREHOUSE: 'SET_DEFAULT_WAREHOUSE',
  SET_DEFAULT_USER_WAREHOUSE: 'SET_DEFAULT_USER_WAREHOUSE',
  CLEAR_STORE: 'CLEAR_STORE',
}

export const mutations: MutationTree<any> = {
  [mt.SET_WAREHOUSES](state: StocksState, warehouses: Array<Warehouse>) {
    const warehousesMapping = {
      allIds: warehouses.map(warehouse => warehouse.id),
      byId: warehouses
        .map(warehouse => ({ [warehouse.id]: warehouse }))
        .reduce((x, y) => ({ ...x, ...y }), {}),
    }
    Vue.set(state, 'warehouses', warehousesMapping)
  },
  [mt.SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED](state: StocksState) {
    if (state.defaultWarehouse === null) {
      const firstCentralWarehouse: Warehouse | undefined = Object.values(
        state.warehouses.byId,
      ).find((warehouse: Warehouse) => {
        // @ts-ignore
        return warehouse.type === 'CENTRAL'
      })
      if (firstCentralWarehouse) {
        state.defaultWarehouse = firstCentralWarehouse.id
        state.defaultUserWarehouse = firstCentralWarehouse.id
      }
    }
  },
  [mt.SET_WAREHOUSE](state: StocksState, warehouse: Warehouse) {
    if (!state.warehouses.allIds.includes(warehouse.id)) {
      state.warehouses.allIds.push(warehouse.id)
    }
    Vue.set(state.warehouses.byId, warehouse.id, warehouse)
  },
  [mt.SET_PRODUCT_STOCK](
    state: StocksState,
    { id, stock }: { id: string; stock: ProductStocks },
  ) {
    const stateStock: MappedProductStocks = {
      ...stock,
      ...{
        warehouses: {
          byId: stock.warehouses
            .map(warehouse => ({ [warehouse.warehouseId]: warehouse }))
            .reduce((x, y) => ({ ...x, ...y }), {}),
          allIds: stock.warehouses.map(warehouse => warehouse.warehouseId),
        },
      },
    }
    Vue.set(state.productsStocks, id, stateStock)
  },
  [mt.SET_DEFAULT_USER_WAREHOUSE](state: StocksState, id: string) {
    state.defaultUserWarehouse = id
  },
  [mt.SET_DEFAULT_WAREHOUSE](state: StocksState, id: string) {
    if (id !== null) {
      state.defaultWarehouse = id
    }
  },
  [mt.SET_PRODUCTS_STOCKS](state: StocksState, stocks: ProductsStocks) {
    const productStocks: any = {
      ...state.productsStocks,
    }
    Object.keys(stocks).forEach((productId: string) => {
      productStocks[productId] = {
        ...stocks[productId],
        ...{
          warehouses: {
            byId: stocks[productId].warehouses
              .map(x => ({ [x.warehouseId]: x }))
              .reduce((x, y) => ({ ...x, ...y }), {}),
            allIds: stocks[productId].warehouses.map(x => x.warehouseId),
          },
        },
      }
    })
    Vue.set(state, 'productsStocks', productStocks)
  },
  [mt.CLEAR_STORE](state: StocksState) {
    /*
        warehouses: {
          byId: {},
          allIds: []
        },
        productsStocks: {},
        defaultWarehouse: null,
     */
    Vue.set(state, 'warehouses', {
      byId: {},
      allIds: [],
    })
    Vue.set(state, 'productsStocks', {})
    state.defaultWarehouse = null
    state.defaultUserWarehouse = null
  },
}
export default mutations

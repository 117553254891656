import { render, staticRenderFns } from "./OneOfflineNotification.vue?vue&type=template&id=22437286&scoped=true&functional=true&"
var script = {}
import style0 from "./OneOfflineNotification.vue?vue&type=style&index=0&id=22437286&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@4.3.0_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "22437286",
  null
  
)

export default component.exports
import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _40df26ce = () => interopDefault(import('../pages/products/_slug/index.vue' /* webpackChunkName: "" */))
const _0836f6c6 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _4ad7f419 = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _71571ac2 = () => interopDefault(import('../pages/account/agreements.vue' /* webpackChunkName: "pages/account/agreements" */))
const _28f4b232 = () => interopDefault(import('../pages/account/billing.vue' /* webpackChunkName: "pages/account/billing" */))
const _9726d078 = () => interopDefault(import('../pages/account/bonuses.vue' /* webpackChunkName: "pages/account/bonuses" */))
const _e3429dbe = () => interopDefault(import('../pages/account/complains.vue' /* webpackChunkName: "pages/account/complains" */))
const _25e45957 = () => interopDefault(import('../pages/account/contact.vue' /* webpackChunkName: "pages/account/contact" */))
const _2ead9a0b = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _1ce59c98 = () => interopDefault(import('../pages/account/invoices-goods-issued-notes.vue' /* webpackChunkName: "pages/account/invoices-goods-issued-notes" */))
const _33c6b08a = () => interopDefault(import('../pages/account/invoices-goods-issued-notes/goods-issue-notes.vue' /* webpackChunkName: "pages/account/invoices-goods-issued-notes/goods-issue-notes" */))
const _39aa0957 = () => interopDefault(import('../pages/account/invoices-goods-issued-notes/goods-received-notes.vue' /* webpackChunkName: "pages/account/invoices-goods-issued-notes/goods-received-notes" */))
const _0d4fe491 = () => interopDefault(import('../pages/account/invoices-goods-issued-notes/invoices.vue' /* webpackChunkName: "pages/account/invoices-goods-issued-notes/invoices" */))
const _a9b0ae6c = () => interopDefault(import('../pages/account/invoices-goods-issued-notes/invoices-correction.vue' /* webpackChunkName: "pages/account/invoices-goods-issued-notes/invoices-correction" */))
const _77d772df = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _dafccc1e = () => interopDefault(import('../pages/account/order-authorization.vue' /* webpackChunkName: "pages/account/order-authorization" */))
const _db638f04 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _5316995c = () => interopDefault(import('../pages/account/orders/offline.vue' /* webpackChunkName: "pages/account/orders/offline" */))
const _608c0434 = () => interopDefault(import('../pages/account/orders/online.vue' /* webpackChunkName: "pages/account/orders/online" */))
const _4019b06e = () => interopDefault(import('../pages/account/promotions.vue' /* webpackChunkName: "pages/account/promotions" */))
const _3e96b2b6 = () => interopDefault(import('../pages/account/rfqs-offers.vue' /* webpackChunkName: "pages/account/rfqs-offers" */))
const _2ade351e = () => interopDefault(import('../pages/account/rfqs-offers/offers.vue' /* webpackChunkName: "pages/account/rfqs-offers/offers" */))
const _780b37a0 = () => interopDefault(import('../pages/account/rfqs-offers/rfqs.vue' /* webpackChunkName: "pages/account/rfqs-offers/rfqs" */))
const _07b94934 = () => interopDefault(import('../pages/account/roles.vue' /* webpackChunkName: "pages/account/roles" */))
const _21d71d7e = () => interopDefault(import('../pages/account/shopping.vue' /* webpackChunkName: "pages/account/shopping" */))
const _0d1b5c2c = () => interopDefault(import('../pages/account/SidebarAccount.vue' /* webpackChunkName: "pages/account/SidebarAccount" */))
const _07eeaaff = () => interopDefault(import('../pages/account/users.vue' /* webpackChunkName: "pages/account/users" */))
const _7af65e6e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4e64ffc8 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _22eaf788 = () => interopDefault(import('../pages/breadcrumbs.ts' /* webpackChunkName: "pages/breadcrumbs" */))
const _21bc061a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _07f44d80 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _22ebd187 = () => interopDefault(import('../pages/complaint/index.vue' /* webpackChunkName: "pages/complaint/index" */))
const _145e0770 = () => interopDefault(import('../pages/goods-issued-notes/index.vue' /* webpackChunkName: "pages/goods-issued-notes/index" */))
const _aaf3b240 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _2812d745 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _57e23fdf = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _3e0e26f0 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _1affd84e = () => interopDefault(import('../pages/rfq/index.vue' /* webpackChunkName: "pages/rfq/index" */))
const _28522a3c = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _554c1c20 = () => interopDefault(import('../pages/set-password/index.vue' /* webpackChunkName: "pages/set-password/index" */))
const _0a7e2202 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _a284e43c = () => interopDefault(import('../pages/checkout/address.vue' /* webpackChunkName: "pages/checkout/address" */))
const _0edca7d6 = () => interopDefault(import('../pages/checkout/order-summary.vue' /* webpackChunkName: "pages/checkout/order-summary" */))
const _132f09ac = () => interopDefault(import('../pages/checkout/thank-you.vue' /* webpackChunkName: "pages/checkout/thank-you" */))
const _07f87bca = () => interopDefault(import('../pages/checkout/thank-you-auth.vue' /* webpackChunkName: "pages/checkout/thank-you-auth" */))
const _14a050cc = () => interopDefault(import('../pages/checkout/thank-you-rfq.vue' /* webpackChunkName: "pages/checkout/thank-you-rfq" */))
const _b4749c0a = () => interopDefault(import('../pages/goods-issued-notes/goods-issued-note.vue' /* webpackChunkName: "pages/goods-issued-notes/goods-issued-note" */))
const _39b54fc5 = () => interopDefault(import('../pages/goods-issued-notes/goods-received-note.vue' /* webpackChunkName: "pages/goods-issued-notes/goods-received-note" */))
const _45d1538a = () => interopDefault(import('../pages/invoices/invoice.vue' /* webpackChunkName: "pages/invoices/invoice" */))
const _314d54cd = () => interopDefault(import('../pages/login/change.vue' /* webpackChunkName: "pages/login/change" */))
const _15bcab30 = () => interopDefault(import('../pages/orders/offline.vue' /* webpackChunkName: "pages/orders/offline" */))
const _62969896 = () => interopDefault(import('../pages/orders/online.vue' /* webpackChunkName: "pages/orders/online" */))
const _4ed2e72b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e5904046 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _85f82a8e = () => interopDefault(import('../pages/checkout/_orderId/thank-you.vue' /* webpackChunkName: "pages/checkout/_orderId/thank-you" */))
const _73b1b8b8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:name(.*)?-id-:slug",
    component: _40df26ce,
    name: "product-page"
  }, {
    path: "/account",
    component: _0836f6c6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "addresses",
      component: _4ad7f419,
      pathToRegexpOptions: {"strict":true},
      name: "account-addresses"
    }, {
      path: "agreements",
      component: _71571ac2,
      pathToRegexpOptions: {"strict":true},
      name: "account-agreements"
    }, {
      path: "billing",
      component: _28f4b232,
      pathToRegexpOptions: {"strict":true},
      name: "account-billing"
    }, {
      path: "bonuses",
      component: _9726d078,
      pathToRegexpOptions: {"strict":true},
      name: "account-bonuses"
    }, {
      path: "complains",
      component: _e3429dbe,
      pathToRegexpOptions: {"strict":true},
      name: "account-complains"
    }, {
      path: "contact",
      component: _25e45957,
      pathToRegexpOptions: {"strict":true},
      name: "account-contact"
    }, {
      path: "dashboard",
      component: _2ead9a0b,
      pathToRegexpOptions: {"strict":true},
      name: "account-dashboard"
    }, {
      path: "invoices-goods-issued-notes",
      component: _1ce59c98,
      pathToRegexpOptions: {"strict":true},
      name: "account-invoices-goods-issued-notes",
      children: [{
        path: "goods-issue-notes",
        component: _33c6b08a,
        pathToRegexpOptions: {"strict":true},
        name: "account-invoices-goods-issued-notes-goods-issue-notes"
      }, {
        path: "goods-received-notes",
        component: _39aa0957,
        pathToRegexpOptions: {"strict":true},
        name: "account-invoices-goods-issued-notes-goods-received-notes"
      }, {
        path: "invoices",
        component: _0d4fe491,
        pathToRegexpOptions: {"strict":true},
        name: "account-invoices-goods-issued-notes-invoices"
      }, {
        path: "invoices-correction",
        component: _a9b0ae6c,
        pathToRegexpOptions: {"strict":true},
        name: "account-invoices-goods-issued-notes-invoices-correction"
      }]
    }, {
      path: "notifications",
      component: _77d772df,
      pathToRegexpOptions: {"strict":true},
      name: "account-notifications"
    }, {
      path: "order-authorization",
      component: _dafccc1e,
      pathToRegexpOptions: {"strict":true},
      name: "account-order-authorization"
    }, {
      path: "orders",
      component: _db638f04,
      pathToRegexpOptions: {"strict":true},
      name: "account-orders",
      children: [{
        path: "offline",
        component: _5316995c,
        pathToRegexpOptions: {"strict":true},
        name: "account-orders-offline"
      }, {
        path: "online",
        component: _608c0434,
        pathToRegexpOptions: {"strict":true},
        name: "account-orders-online"
      }]
    }, {
      path: "promotions",
      component: _4019b06e,
      pathToRegexpOptions: {"strict":true},
      name: "account-promotions"
    }, {
      path: "rfqs-offers",
      component: _3e96b2b6,
      pathToRegexpOptions: {"strict":true},
      name: "account-rfqs-offers",
      children: [{
        path: "offers",
        component: _2ade351e,
        pathToRegexpOptions: {"strict":true},
        name: "account-rfqs-offers-offers"
      }, {
        path: "rfqs",
        component: _780b37a0,
        pathToRegexpOptions: {"strict":true},
        name: "account-rfqs-offers-rfqs"
      }]
    }, {
      path: "roles",
      component: _07b94934,
      pathToRegexpOptions: {"strict":true},
      name: "account-roles"
    }, {
      path: "shopping",
      component: _21d71d7e,
      pathToRegexpOptions: {"strict":true},
      name: "account-shopping"
    }, {
      path: "SidebarAccount",
      component: _0d1b5c2c,
      pathToRegexpOptions: {"strict":true},
      name: "account-SidebarAccount"
    }, {
      path: "users",
      component: _07eeaaff,
      pathToRegexpOptions: {"strict":true},
      name: "account-users"
    }, {
      path: "",
      component: _7af65e6e,
      pathToRegexpOptions: {"strict":true},
      name: "account"
    }]
  }, {
    path: "/blog",
    component: _4e64ffc8,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/breadcrumbs",
    component: _22eaf788,
    pathToRegexpOptions: {"strict":true},
    name: "breadcrumbs"
  }, {
    path: "/cart",
    component: _21bc061a,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/checkout",
    component: _07f44d80,
    pathToRegexpOptions: {"strict":true},
    name: "checkout"
  }, {
    path: "/complaint",
    component: _22ebd187,
    pathToRegexpOptions: {"strict":true},
    name: "complaint"
  }, {
    path: "/goods-issued-notes",
    component: _145e0770,
    pathToRegexpOptions: {"strict":true},
    name: "goods-issued-notes"
  }, {
    path: "/invoices",
    component: _aaf3b240,
    pathToRegexpOptions: {"strict":true},
    name: "invoices"
  }, {
    path: "/login",
    component: _2812d745,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/orders",
    component: _57e23fdf,
    pathToRegexpOptions: {"strict":true},
    name: "orders"
  }, {
    path: "/registration",
    component: _3e0e26f0,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/rfq",
    component: _1affd84e,
    pathToRegexpOptions: {"strict":true},
    name: "rfq"
  }, {
    path: "/search",
    component: _28522a3c,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/set-password",
    component: _554c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "set-password"
  }, {
    path: "/wishlist",
    component: _0a7e2202,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist"
  }, {
    path: "/checkout/address",
    component: _a284e43c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-address"
  }, {
    path: "/checkout/order-summary",
    component: _0edca7d6,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-order-summary"
  }, {
    path: "/checkout/thank-you",
    component: _132f09ac,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-thank-you"
  }, {
    path: "/checkout/thank-you-auth",
    component: _07f87bca,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-thank-you-auth"
  }, {
    path: "/checkout/thank-you-rfq",
    component: _14a050cc,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-thank-you-rfq"
  }, {
    path: "/goods-issued-notes/goods-issued-note",
    component: _b4749c0a,
    pathToRegexpOptions: {"strict":true},
    name: "goods-issued-notes-goods-issued-note"
  }, {
    path: "/goods-issued-notes/goods-received-note",
    component: _39b54fc5,
    pathToRegexpOptions: {"strict":true},
    name: "goods-issued-notes-goods-received-note"
  }, {
    path: "/invoices/invoice",
    component: _45d1538a,
    pathToRegexpOptions: {"strict":true},
    name: "invoices-invoice"
  }, {
    path: "/login/change",
    component: _314d54cd,
    pathToRegexpOptions: {"strict":true},
    name: "login-change"
  }, {
    path: "/orders/offline",
    component: _15bcab30,
    pathToRegexpOptions: {"strict":true},
    name: "orders-offline"
  }, {
    path: "/orders/online",
    component: _62969896,
    pathToRegexpOptions: {"strict":true},
    name: "orders-online"
  }, {
    path: "/",
    component: _4ed2e72b,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/blog/:article",
    component: _e5904046,
    pathToRegexpOptions: {"strict":true},
    name: "blog-article"
  }, {
    path: "/products/:slug",
    component: _40df26ce,
    pathToRegexpOptions: {"strict":true},
    name: "products-slug"
  }, {
    path: "/checkout/:orderId/thank-you",
    component: _85f82a8e,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-orderId-thank-you"
  }, {
    path: "/*",
    component: _73b1b8b8,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

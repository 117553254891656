
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import VLazyImage from 'v-lazy-image'
  import { CreateElement, VNode } from 'vue'

  @Component({
    components: {
      VLazyImage,
    },
  })
  export default class OneImage extends Vue {
    name: string = 'one-image'

    @Prop({
      type: String,
      required: true,
    })
    src!: string

    @Prop({
      type: String,
      required: false,
      default: '',
    })
    srcPlaceholder!: string

    @Prop({
      type: Boolean,
      required: false,
      default: true,
    })
    lazy!: boolean

    @Prop({
      type: String,
      required: false,
    })
    alt!: string

    render(createElement: CreateElement): VNode {
      if (this.lazy) {
        return createElement('v-lazy-image', {
          ...{
            class: 'one-image',
            on: this.$listeners,
            props: {
              srcPlaceholder: this.srcPlaceholder,
              src: this.src,
              alt: this.alt,
            },
          },
        })
      }
      return createElement('img', {
        ...{
          class: 'one-image v-lazy-image-loaded main-img',
          on: this.$listeners,
          attrs: {
            src: this.src,
            alt: this.alt,
          },
        },
      })
    }
  }









































  import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
  import { Shared } from '@one/types/dist/orderpath/app'
  import uuid from 'uuid-random'
  import { namespace } from 'vuex-class'
  import { BindingHelpers } from 'vuex-class/lib/bindings'

  const products: BindingHelpers = namespace('products')

  @Component
  export default class OneInputTooltip extends Vue {
    @products.Getter getDisplayMode: any
    name: string = 'one-input-tooltip'

    @Prop({
      required: true,
      type: String,
    })
    productId!: string

    @Prop({
      required: true,
      type: Object,
    })
    measurementUnits!: Shared.ProductMeasurementUnits

    @Prop({
      required: true,
      type: String,
    })
    unit!: string

    @Prop({
      type: [String, Boolean],
      default: false,
    })
    amountSyncTrigger!: string | boolean

    @Prop({
      type: Number,
      required: false,
    })
    initialQuantity?: number

    @Prop({
      type: Number,
      default: 150,
    })
    debounce!: number

    @Prop({
      type: Number,
      required: false,
    })
    calculatedQuantity?: number

    @Prop({
      type: Number,
      required: false,
    })
    min?: number

    @Prop({
      type: Number,
      required: false,
    })
    step?: number

    @Prop({
      type: Boolean,
      default: false,
      required: false,
    })
    disabled?: boolean

    tooltipUUID: string = uuid()
    quantityValue: number = 1
    hasQuantityError: boolean = false
    isTooltipVisible: boolean = false
    quantityChangeTimeout: any = null

    @Watch('initialQuantity', { immediate: true })
    updateQtyInputValue() {
      this.setInitialQuantityValue()
    }

    // Force update on parent, so both values are in sync (edge case for OneProductTileRightActions)
    @Watch('amountSyncTrigger', { immediate: true })
    syncQuantityWithParent(shouldTriggerSync: boolean) {
      if (shouldTriggerSync) {
        this.onChange()
      }
    }

    @Watch('calculatedQuantity')
    onCalculatedQuantityChanged() {
      if (this.calculatedQuantity) {
        this.quantityValue = this.calculatedQuantity
      }
    }

    onInput(newValue: number) {
      this.quantityValue = newValue
      this.onChange()
    }

    onChange() {
      clearTimeout(this.quantityChangeTimeout)
      this.quantityChangeTimeout = setTimeout(() => {
        if (this.isQuantityValid(this.quantityValue)) {
          this.emitValueChange(this.quantityValue)
        }
      }, this.debounce)
    }

    destroyed() {
      clearTimeout(this.quantityChangeTimeout)
    }

    emitValueChange(value: number) {
      this.$emit('change-value', value)
    }

    showTooltip() {
      this.isTooltipVisible = true
    }

    hideTooltip() {
      setTimeout(() => {
        if (!this.hasQuantityError) {
          this.isTooltipVisible = false
        }
      }, 1000)
    }

    setNewInputValue() {
      if (!this.isQuantityValid(this.quantityValue)) {
        this.roundQtyValueWithQtyInterval()
        if (!this.isQuantityValid(this.quantityValue)) {
          this.setInitialQuantityValue()
        }
        this.isQuantityValid(this.quantityValue)
        this.onChange()
      }
    }

    setInitialQuantityValue() {
      this.quantityValue =
        this.initialQuantity && this.initialQuantity > this.minValue
          ? this.initialQuantity
          : this.minValue
    }

    roundQtyValueWithQtyInterval() {
      this.quantityValue = this.quantityValue - (this.quantityValue % this.intervalValue)
    }

    isQuantityValid(newValue: number) {
      const isRightQty = newValue % this.intervalValue === 0 && newValue >= this.minValue
      this.hasQuantityError = !isRightQty
      this.isTooltipVisible = !isRightQty
      this.$emit('qty-error', this.hasQuantityError)
      return isRightQty
    }

    get intervalValue() {
      return this.step ? this.step : this.measurementUnits.quantityInterval || 1
    }

    get minValue() {
      if (this.min || this.min === 0) {
        return this.min
      }

      return this.measurementUnits.quantityMin || this.measurementUnits.quantityInterval || 1
    }

    get hasIntervalValue() {
      return !!this.measurementUnits.quantityInterval
    }

    get hasMinValue() {
      return !!this.measurementUnits.quantityMin
    }

    get minQtyTxt() {
      return (
        this.measurementUnits.quantityMin &&
        `${this.$t('product_page.min_qty')}: ${this.measurementUnits.quantityMin}`
      )
    }

    get intervalQtyTxt() {
      return (
        this.measurementUnits.quantityInterval &&
        `${this.$t('product_page.interval_qty')}: ${this.measurementUnits.quantityInterval}`
      )
    }

    get showTooltipProductList() {
      return (this.hasIntervalValue || this.hasMinValue) && this.getDisplayMode !== 'LIST'
    }
  }

import { TenantConfiguration } from '../../../store'

// TODO: Ogarnąć konfiguracje tenanta bez CMSa - czekamy na serwis na BE
export type Configuration = TenantConfiguration

export enum AccessMode {
  CLOSED = 'CLOSED',
  OPEN_CATALOG = 'OPEN_CATALOG',
  OPEN = 'OPEN',
}

export interface State {
  configuration: Configuration | null
  tenantKey: string | null
  isDev: boolean | null
  currency: string | null
  accessMode: AccessMode | null
  isRegistrationEnabled: boolean | null
  isB2CRegistrationEnabled: boolean | null
  showProductId: boolean | null
  showGoodsIssuedNotes: boolean | null
}

export default {
  'account_sidebar.account_information': 'Account information',
  'account_sidebar.addresses': 'Addresses',
  'account_sidebar.agreements': 'Agreements',
  'account_sidebar.billing': 'Billing',
  'account_sidebar.bonuses': 'Bonuses',
  'account_sidebar.complains': 'Complains and returns',
  'account_sidebar.contact': 'Contact',
  'account_sidebar.dashboard': 'Dashboard',
  'account_sidebar.goods_issued_notes': 'Goods issued notes',
  'account_sidebar.goods_received_notes': 'Goods received notes',
  'account_sidebar.invoice_corrections': 'Corrections',
  'account_sidebar.invoices': 'Invoices',
  'account_sidebar.invoices_goods_issued_notes': 'Invoices and goods issued notes',
  'account_sidebar.my_promotions': 'My promotions',
  'account_sidebar.notification_settings': 'Notification settings',
  'account_sidebar.offers': 'Offers',
  'account_sidebar.order_authorization': 'Order authorization',
  'account_sidebar.orders_history': 'Orders history',
  'account_sidebar.rfqs': 'RFQs',
  'account_sidebar.rfqs_offers': 'Offers and RFQs',
  'account_sidebar.roles': 'Role',
  'account_sidebar.shopping_list': 'Shopping list',
  'account_sidebar.title': 'My account',
  'account_sidebar.users': 'Users',
  'address_page_list_element.tooltip.billing': 'Billing address',
  'address_page_list_element.tooltip.delivery': 'Default delivery address',
  'address_page_list_element.tooltip.remove_address': 'Remove address',
  'address_page_list_element.tooltip.set_delivery': 'Set as default delivery address',
  'address_page.add_address': 'Add address',
  'address_page.search': 'Search in addresses',
  'address_page.search_placeholder': 'Search',
  'address_page.title': 'Addresses',
  'agreements_page.email': 'E-mail adress',
  'agreements_page.less': 'Less',
  'agreements_page.more': 'More',
  'agreements_page.required_field_text': 'Required field',
  'agreements_page.title': 'My Agreements',
  'alert_address_page.add_address': 'Address added',
  'alert_address_page.error': 'Failed to',
  'alert_address_page.remove_address': 'Address removed',
  'alert_address_page.set_default_address': 'The default address has been changed',
  'authorities.APP_ADD_ORDERS': 'Possibility to place orders',
  'authorities.APP_ADD_TO_AUTHORIZATION_CART': 'User can send cart to another user - for acceptance',
  'authorities.APP_AUTHORIZATION_CART': 'User can authorize other users carts',
  'authorities.APP_DELIVERY_PICKUP': 'User authorized to pick up orders',
  'authorities.APP_USERS': 'Possibility to manage roles and users',
  'authorities.APP_VIEW_INVOICES': 'View the invoice tab',
  'authorities.APP_VIEW_ORDERS': 'View the order history of all users',
  'authorities.APP_VIEW_ROLES': 'Possibility to create and edit user roles',
  'authorities.APP_VIEW_USERS': 'Possibility to edit and create new users',
  'backend_errors.account-account-manager-account-manager-not-found': 'Account manager code not recognized',
  'backend_errors.account-account-registration-account-registration-country-is-required': 'Country is required',
  'backend_errors.account-account-registration-account-registration-email-already-in-use': 'This email address is already in use',
  'backend_errors.account-account-registration-account-registration-tin-already-in-use': 'This TIN number is already in use',
  'backend_errors.account-registration-invalid-email': 'This email address is invalid',
  'backend_errors.CANNOT_BE_ORDER': 'It is not possible to place an order',
  'backend_errors.default': 'An error occurred - please try again later',
  'backend_errors.NOT_ENOUGH_TRADE_CREDIT': 'Unable to place an order due to insufficient funds',
  'backend_errors.ORDER_ALREADY_SUBMITTED': 'Order already submitted',
  'backend_errors.orderpath-offer-command-CanNotPlaceOrderError': 'There is a deleted product in your cart. Delete the product to complete the order',
  'backend_errors.orderpath-order-BlockedClientCreateOrderError': 'It is not possible to place an order due to a blockade',
  'backend_errors.orderpath-order-ExternalAccountBlockadeRequestError': 'No response from external system',
  'backend_errors.orderpath-order-ExternalTradeCreditRequestError': 'No response from external system',
  'backend_errors.orderpath-validate-coupon-coupon-expired': 'Coupon expired',
  'backend_errors.orderpath-validate-coupon-coupon-not-found': 'Coupon not found',
  'backend_errors.orderpath-validate-coupon-coupon-only-for-authorized-users': 'Coupon only for authorized users',
  'backend_errors.orderpath-validate-coupon-coupon-usage-limit-reached': 'Coupon usage limit reached',
  'backend_errors.rma-complaint-EmailValidationError': 'Email address is not valid',
  'backend_errors.rma-complaint-ItemsValidationError': 'Select returned products',
  'backend_errors.rma-complaint-PositionOnInvoiceValidationError': 'The invoice item should be different from 0',
  'backend_errors.rma-complaint-QuantityValidationError': 'Choose the quantity to be returned',
  'backend_errors.USER_DOES_NOT_EXIST': 'It is not possible to place an order. The user does not exist',
  'backend_errors.USER_IS_NOT_ACTIVE': 'The user is inactive',
  'barcode_reader.not_available': 'The device does not support the camera or no permission',
  'barcode_reader.not_found': 'No products found for the given catalog code',
  'barcode_reader.search_placeholder': 'Scan or enter the catalog code',
  'barcode_reader.searching': 'Searching',
  'barcode_reader.select_device': 'Select camera',
  'billing_view.city': 'City',
  'billing_view.country': 'Country',
  'billing_view.email': 'E-mail',
  'billing_view.external_id': 'Customer number from ERP',
  'billing_view.name': 'Company name',
  'billing_view.phone': 'Phone',
  'billing_view.street': 'Street',
  'billing_view.tin': 'TIN',
  'billing_view.title': 'Billing',
  'billing_view.zip_code': 'Zip Code',
  'blog.no_articles': 'No articles',
  'blog.read_more': 'Read more',
  'blog.title': 'BLOG',
  'cart.availability': 'Availability',
  'cart.availability_error': 'Attention! The number of products chosen by you exceeds the quantity available in the warehouse. Decide how to treat your order:',
  'cart.availability_error_option1': 'I am ordering products in the quantity available, remaining in the state of completing the stock of another shipment.',
  'cart.availability_error_option2': 'I am ordering products in quantity available, please remove remaining ones from basket.',
  'cart.availability_error_option3': 'I am ordering products in quantity to be downloaded.',
  'cart.availability_error_option4': 'I am ordering the products in full quantity, this means that the order will be picked up.',
  'cart.award_campaigns.change_awards': 'Change awards',
  'cart.award_campaigns.choose_awards': 'Select awards',
  'cart.award_campaigns.selected': 'selected:',
  'cart.back': 'Go back',
  'cart.blockades.client': 'Due to a blockade, the ability to place an order has been disabled, please contact your account manager for more information',
  'cart.blockades.sale': 'Due to a blockade, the ability to place an order has been disabled, you are only able to submit a shopping cart a RFQ, for more information contact your account manager',
  'cart.blockades.trade_credit': 'Trade credit payment has been disabled due to an imposed blockade, please contact your account manager for more information',
  'cart.btn': 'Go to delivery address',
  'cart.btn_authorize': 'Send a request for authorization',
  'cart.btn_rfq': 'Send a request for quotation',
  'cart.btn_send': 'Send',
  'cart.calculate': 'Recalculate the cart',
  'cart.cancel_calculate': 'Cancel changes',
  'cart.cart_authorization_from_user': 'Shopping cart for authorization from the user:',
  'cart.change': 'Change',
  'cart.coupon_added': 'The coupon has been added to the cart',
  'cart.coupon_delete_btn': 'Delete',
  'cart.coupon_deleted': 'The entered coupon has been deleted',
  'cart.coupon_not_valid': 'The provided coupon is invalid or used',
  'cart.coupon_valid': 'Correct coupon',
  'cart.default_name': 'Cart',
  'cart.delivery_method_error': 'Delivery method error',
  'cart.delivery_methods': 'Delivery methods',
  'cart.delivery_methods_placeholder': 'Choose right delivery',
  'cart.delivery_number': 'Shipment number',
  'cart.delivery_warehouse_placeholder': 'Choose right warehouse',
  'cart.dropdown.add_new': 'Add cart',
  'cart.dropdown.delete': 'Delete cart',
  'cart.dropdown.export_cart': 'Export products',
  'cart.dropdown.export_info': 'Here you can export the entire list of products with quantities from your shopping cart to a CSV file.',
  'cart.dropdown.import_cart': 'Import products',
  'cart.dropdown.import_info': 'Here you can import your entire product list into your cart using CSV, XLS or XLSX file. In order for the import to take place correctly, the file must be prepared according to the scheme:',
  'cart.dropdown.import_info_column_1': 'Column 1: Product id / Manufacturer index / GTIN',
  'cart.dropdown.import_info_column_2': 'Column 2: Quantity',
  'cart.dropdown.rename': 'Rename cart',
  'cart.edit_product': 'Edit product',
  'cart.empty_cart': 'Empty cart',
  'cart.empty_cart_error': 'Cart cannot be empty',
  'cart.extra': 'Add shipment costs to item products?',
  'cart.extra_option': 'Extra option',
  'cart.free_delivery': 'Free shipping',
  'cart.import_errors.column': 'Column {0}.',
  'cart.import_errors.empty_field': 'Required field is empty.',
  'cart.import_errors.internal': 'Internal error while importing the product.',
  'cart.import_errors.nonpositive_amount': 'Negative values are not allowed.',
  'cart.import_errors.not_number_field': 'The value entered is invalid. It should be numeric.',
  'cart.import_errors.price_not_found': 'Price not found.',
  'cart.import_errors.product_amount_is_incorrect_for_product_interval': 'The amount of product is not in line with the interval.',
  'cart.import_errors.product_amount_less_than_minimum_quantity': 'You cannot order a product below the minimum quantity.',
  'cart.import_errors.product_not_found': 'Product not found.',
  'cart.import_errors.product_with_block_label': 'This product cannot be ordered over stock.',
  'cart.import_errors.row': 'Row {0}.',
  'cart.import_errors.undefined_field_type': 'Invalid value.',
  'cart.import_errors.unexpected_value': 'Unexpected value.',
  'cart.invalid_quantity_message': 'This amount of products cannot be added',
  'cart.inventory': 'Inventory',
  'cart.modals.add_placeholder': 'Enter cart name',
  'cart.modals.add_title': 'Create new cart',
  'cart.modals.amount_change_cancel': 'Cancel',
  'cart.modals.amount_change_recalculate': 'Recalculate cart',
  'cart.modals.amount_change_text': 'The quantity of the products has been changed. Please confirm or cancel the changes.',
  'cart.modals.amount_change_title': 'Changing the value in cart',
  'cart.modals.browse': 'Browse',
  'cart.modals.cancel_btn': 'Cancel',
  'cart.modals.cart_created': 'Cart created',
  'cart.modals.cart_removed': 'Deleted cart',
  'cart.modals.cart_renamed': 'Cart renamed',
  'cart.modals.drop_file_placeholder': 'Drop file ...',
  'cart.modals.error_import_product': 'Failed to import products',
  'cart.modals.file_placeholder': 'Choose file or drop it here ...',
  'cart.modals.import_btn': 'Import',
  'cart.modals.import_title': 'Import products from cart',
  'cart.modals.product_search': 'Search for products',
  'cart.modals.product_search_placeholder': 'Enter product name',
  'cart.modals.promotion_fetch_error': 'Wystąpił problem przy pobieraniu promocji. Spróbuj ponownie później.',
  'cart.modals.remove_confirm_btn': 'Remove',
  'cart.modals.remove_text': 'Please confirm cart removal',
  'cart.modals.remove_title': 'Remove',
  'cart.modals.rename_ok_btn': 'Rename',
  'cart.modals.rename_placeholder': 'Enter cart name',
  'cart.modals.rename_title': 'Rename cart',
  'cart.modals.save_btn': 'Save',
  'cart.modals.select_locker': 'Select package locker',
  'cart.modals.selected_file': 'Choosed file:',
  'cart.modals.shipping_locker_cash_info': 'Dear customer, you have chosen payment on delivery, which is available in a very small number of parcel lockers. Change to another form of payment to see the full list of parcel lockers.',
  'cart.modals.success_import_product': 'Products imported successfully',
  'cart.no_delivery_method_selected': 'Select delivery method',
  'cart.no_delivery_methods': 'Selected warehouse does not have delivery methods',
  'cart.no_payment_method_set': 'No payment method set',
  'cart.no_split_shipment_option': 'Send order when all goods are available',
  'cart.no_user_set': 'Choose a user',
  'cart.notes': 'Notes',
  'cart.notification': 'The goods will be ready for collection within 4 hours of placing the order. We will inform you when the goods will be ready for pick-up.',
  'cart.options': 'Options',
  'cart.order_submitted': 'Order already submitted.',
  'cart.payment_methods': 'Payment methods',
  'cart.payment_methods_unavailable': 'The choice of the payment method is possible after determining the method of delivery on shipments',
  'cart.please_select_locker': 'Please select a parcel locker',
  'cart.price': 'Price',
  'cart.products_available': 'Products available',
  'cart.products_ordered': 'Products',
  'cart.products_some_available': 'Some products available',
  'cart.products_unavailable': 'Products unavailable',
  'cart.promo_points_added': 'Points has been added',
  'cart.promo_points_deleted': 'Points deleted',
  'cart.promo_points_usage_info': `<span class="font-weight-bold">{0}</span> of the declared {1} points were used`,
  'cart.promotion_coupon': 'Promotional voucher',
  'cart.promotion_points': 'Your loyalty points',
  'cart.promotion_points_info': 'Enter the point value to be transferred to discounts and recalculate the basket',
  'cart.quantity': 'Quantity',
  'cart.quantity_error': 'Quantity of ordered products is not available, return to cart.',
  'cart.remove_selected_products': 'Remove selected products',
  'cart.return_text': 'Go back',
  'cart.rfq_banner_above_max': 'The value of the cart has exceeded the maximum amount for placing an order, send the cart in the form of an request for quotation and our employee will contact you as soon as possible.',
  'cart.rfq_banner_between_min_max': 'You have the option of sending a cart in the form of an request for quotation to negotiate business terms. The selection of the option to send a cart in the form of an request for quotation or order can be found on the summary of the shopping path.',
  'cart.rfq_banner_labels': 'There are products in the cart that need to be verified. You have the option of sending the cart only in the form of an request for quotation, the option is located on the shopping path summary.',
  'cart.rfq_banner_product_without_price': 'Cannot place an order. No product prices',
  'cart.select': 'Select',
  'cart.select_all_products': 'Select all products',
  'cart.shipment_name': 'Shipment',
  'cart.simplepay_image': 'SimplePay - Online credit card payment',
  'cart.simplepay_info': 'SimplePay customer information',
  'cart.simplepay_order_cancelled_title': 'Transaction cancelled',
  'cart.simplepay_order_subtitle': 'SimplePay transaction ID',
  'cart.simplepay_order_successful_title': 'Successful transaction',
  'cart.simplepay_order_unsuccessful_text': 'Please check that the information provided during the transaction is correct. If you have entered all the information correctly, please contact your card-issuing bank to investigate the reason for the decline.',
  'cart.simplepay_order_unsuccessful_title': 'Unsuccessful transaction',
  'cart.some_products_are_not_available': 'The possibility of ordering the following products over the available stock has been blocked, update the quantities of products in the cart:',
  'cart.some_products_are_not_available_one': 'possible quantity to order:',
  'cart.some_products_are_not_available_two': 'current quantity in the cart:',
  'cart.specific_stock.available': 'available',
  'cart.specific_stock.not_available': 'not available',
  'cart.specific_stock.some_available': 'some available',
  'cart.specific_stock.warehouse_info': 'Part of a series in stock',
  'cart.split_shipment_option': 'Divide the order into available and unavailable goods',
  'cart.status_canceled': 'Canceled',
  'cart.status_failed': 'Failed',
  'cart.status_paid': 'Paid',
  'cart.status_pending': 'Pending',
  'cart.status_timeout': 'Timeout',
  'cart.summary': 'Summary',
  'cart.summary_cart_discount_placeholder': 'If you have discount code, type it here',
  'cart.summary_cart_notes_placeholder': 'Here you can include any notes to your order...',
  'cart.summary_cart_order_number_placeholder': 'Type your order no.',
  'cart.summary_table_additional_costs': 'WEEE surcharge',
  'cart.summary_table_payment': 'Payment',
  'cart.summary_table_product': 'Products',
  'cart.summary_table_shipment': 'Shipment',
  'cart.summary_table_tax': 'Tax',
  'cart.summary_table_total': 'Total',
  'cart.summary_table_total_gross': 'Total gross',
  'cart.support': 'Suport center',
  'cart.support_phone': '+48 0800 800 800',
  'cart.tooltip': 'Some info...',
  'cart.total_price': 'Total price',
  'cart.total_price_gross': 'Total price gross',
  'cart.total_price_net': 'Total price net',
  'cart.trade_credit': 'Your trade credit: ',
  'cart.trade_credit_available': 'Available limit: ',
  'cart.trade_credit_error': 'Not enough trade credit!',
  'cart.trade_credit_error_notification': 'Not enough trade credit',
  'cart.trade_credit_used': 'Limit used: ',
  'cart.your_promotions': 'Promotions applied to your cart',
  'change_password_page.bad_credentials': 'Current password is invalid or something went wrong',
  'change_password_page.error': 'The password could not be changed or something went wrong.',
  'change_password_page.error_login': 'Something went wrong. Log in again.',
  'change_password_page.feedback_cross_field_validation': 'The new password provided does not match',
  'change_password_page.feedback_new_password': 'New password required. The password should contain: one capital letter, one number or special character, min. 8 characters.',
  'change_password_page.feedback_old_password': 'Old password required',
  'change_password_page.feedback_re_enter_new_password': 'Repeat new password required',
  'change_password_page.info_invalid_token': 'Password reset link has expired or is invalid. Try to reset your password again.',
  'change_password_page.is_agreements_accepted': 'Required acceptance of the regulations',
  'change_password_page.label': 'Save new password',
  'change_password_page.new_password': 'New password',
  'change_password_page.new_password_title': 'Enter new password',
  'change_password_page.old_password': 'Old password',
  'change_password_page.repeat_new_password': 'Re-enter new password',
  'change_password_page.resend_new_password': 'A link was sent to the e-mail address provided, where you will complete the account creation',
  'change_password_page.success': 'Password has been successfully changed. Log in again. ',
  'change_password_page.title': 'Logged in for the first time. Password change required',
  'checkout.accepted_delivery_date': 'Accepted delivery date',
  'checkout.contract_conditions.city': 'City',
  'checkout.contract_conditions.company_name': 'Company name',
  'checkout.contract_conditions.country': 'Country',
  'checkout.contract_conditions.phone': 'Phone',
  'checkout.contract_conditions.street': 'Address',
  'checkout.contract_conditions.tin': 'TIN',
  'checkout.contract_conditions.zip_code': 'Zip code',
  'checkout.delivery.add_new_address': 'Add new address',
  'checkout.delivery.addAddress': 'Add address to address book',
  'checkout.delivery.address_name': 'Address name',
  'checkout.delivery.another_delivery_address': 'Delivery address other then billing',
  'checkout.delivery.authorizedPersons': 'Person authorized to pick up an order',
  'checkout.delivery.availabilityTxt': 'Availability',
  'checkout.delivery.chosenMethod': 'Courier',
  'checkout.delivery.city': 'City',
  'checkout.delivery.country': 'Country',
  'checkout.delivery.date': 'Choose date',
  'checkout.delivery.email': 'E-mail',
  'checkout.delivery.error': 'Check the address',
  'checkout.delivery.error_add_address': 'Error add address',
  'checkout.delivery.exactDeliveryDate': 'Delivery date',
  'checkout.delivery.itemAvailability': 'Availability',
  'checkout.delivery.list_address': 'Address book',
  'checkout.delivery.list_user': 'Choose recipient',
  'checkout.delivery.name': 'Name',
  'checkout.delivery.new_address': 'Add new address',
  'checkout.delivery.new_user': 'Enter new recipient',
  'checkout.delivery.newAddressExists': 'Inserted address already exists in the address book',
  'checkout.delivery.phone': 'Phone',
  'checkout.delivery.select_authorized_users': 'Select recipients',
  'checkout.delivery.street': 'Street',
  'checkout.delivery.success_add_address': 'Success add address',
  'checkout.delivery.title': 'Shipment',
  'checkout.delivery.type_another_address': 'Enter another address',
  'checkout.delivery.value': 'Total price',
  'checkout.delivery.zip_code': 'Zip Code',
  'checkout.estimated_delivery_date': 'Estimated delivery date',
  'checkout.full_value_basket': 'Total value',
  'checkout.full_value_basket_address': 'Total value',
  'checkout.full_value_basket_cart': 'Total value',
  'checkout.full_value_basket_summary': 'Total value',
  'checkout.invoice_data.title': 'Billing',
  'checkout.moreRead.less': 'Less',
  'checkout.moreRead.more': 'Read more',
  'checkout.notes': 'Notes',
  'checkout.options.btn_order': 'Order',
  'checkout.options.btn_pay_with_stripe': 'Continue with Stripe payment',
  'checkout.options.btn_stripe': 'Pay',
  'checkout.options.btn_submit': 'Submit',
  'checkout.options.btn_summary': 'Order Summary',
  'checkout.options.btn_try_again': 'Try again',
  'checkout.payment_method': 'Choosed payment method',
  'checkout.payment_notice': 'Placing an order involves the obligation to pay!',
  'checkout.paywall_failed': 'Payment failed, try again',
  'checkout.regulations.error_accept_regulations': 'Accept the regulations',
  'checkout.regulations.title': 'Agreements',
  'checkout.summary.cart_auth': 'Thank you, your cart has been sent for authorization.',
  'checkout.summary.cart_rfq1': 'Thank you, your offer request number',
  'checkout.summary.delivery_number': 'Delivery number',
  'checkout.summary.invoice_data': 'Billing address',
  'checkout.summary.order_status': 'Order status',
  'checkout.summary.step1': 'You will receive confirmation on e-mail',
  'checkout.summary.step2': 'Your order will be verified',
  'checkout.summary.step3': 'We will inform you about picking the order',
  'checkout.summary.step4': 'We will send the package or we will prepare it for collection',
  'checkout.summary.thank_you_fail_first': 'Your order with the number',
  'checkout.summary.thank_you_fail_second': 'was failed.',
  'checkout.summary.thank_you_success_first': 'Thank you. Your order with the number',
  'checkout.summary.thank_you_success_second': 'was submitted successfully',
  'checkout.summary.total_price': 'Total price',
  'checkout.summary.what_next': 'What is next?',
  'common.cancel': 'Cancel',
  'common.edit': 'Edit',
  'common.page_404': 'Page does not exists',
  'common.save': 'Save',
  'company_branches.change_tip': 'If you want to change your default branch, please contact the supervisor',
  'company_branches.consultant': 'Account manager',
  'company_branches.title': 'Branch',
  'complains.cancel_complaint': 'Cancel complaint',
  'complains.complains_number': 'Complaint no.',
  'complains.complaint_reason': 'Reason for complaint',
  'complains.create_complaint': 'Make a new complaint',
  'complains.create_date': 'Create date',
  'complains.declarant': 'Declarant',
  'complains.detail.invoice_number': 'Invoice',
  'complains.detail.notes': 'Notes of complaint',
  'complains.email': 'E-mail',
  'complains.email_not_valid': 'Email address is not valid',
  'complains.empty_complaint_items': 'Select returned products',
  'complains.empty_position_invoice': 'The invoice item should be different from 0',
  'complains.empty_quantity': 'Choose the quantity to be returned',
  'complains.lp': 'L.p',
  'complains.name': 'Name',
  'complains.notes': 'Description of the defect',
  'complains.notes_input': 'Describe the defect, the date it was found and the claim',
  'complains.notification_info': 'To making a complaint or return, fill in the form below.  We will send you an e-mail with the complaint number. The complaint department will contact you as soon as possible',
  'complains.phone': 'Phone number',
  'complains.position_on_invoice': 'Invoice position',
  'complains.product': 'Product',
  'complains.product_sku': 'Product SKU',
  'complains.quantity_on_invoice': 'Quantity on invoice',
  'complains.quantity_to_return': 'Quantity returned',
  'complains.save_complaint': 'Submit an application',
  'complains.step_1': 'Step 1. Search invoice',
  'complains.step_2': 'Step 2. Choose the products you want to return',
  'complains.step_3': 'Steo 3. Enter data',
  'complains.success_complaint': 'Complaint created',
  'complains.unit': 'Unit',
  'default_seo.category': 'Category:',
  'default_seo.description': 'The best ecommerce platform',
  'default_seo.keywords': 'One, Platform, Ecommerce',
  'default_seo.search': 'Search result for:',
  'default_seo.title': 'One',
  'edit_address_view.cancel': 'Cancel',
  'edit_address_view.city': 'City',
  'edit_address_view.country': 'Country',
  'edit_address_view.default': 'Default address',
  'edit_address_view.name': 'Company/Address name',
  'edit_address_view.phone': 'Phone number',
  'edit_address_view.save': 'Save',
  'edit_address_view.street': 'Street',
  'edit_address_view.title': 'Add address',
  'edit_address_view.zipCode': 'Zip code',
  'error_page.copy': 'Copy to clipboard',
  'error_page.error_occurred': 'Error occurred',
  'error_page.home_page': 'Go to home page',
  'error_page.info_req_id': 'Sorry something went wrong. A team of highly trained programist has been dispatched to deal with situation. If you see them, show them this information.',
  'error_page.not_found': 'Page not found',
  'errors.add_to_cart_error': 'Error adding product to cart - try again later',
  'errors.add_to_wishlist_error': 'Error adding product to wishlist - try again later',
  'errors.generic': 'An error occurred - please try again later',
  'errors.no_cart_authorization': 'Current user does not exist/Order confirmation already submitted/Destination user does not exist or is in different organization or does not have authorization',
  'errors.no_prices': 'Price is not available for this product, you cannot purchase it.',
  'errors.no_warehouses': 'No warehouses detected - system will not be fully functional',
  'errors.no_warehouses_cart': 'No warehouses - cart is unavailable',
  'errors.submit_error_400': 'There was a problem to submit (400) - try again later',
  'errors.submit_error_500': 'There was a problem to submit (500) - try again later',
  'errors.warning': 'Warning',
  'fields_lang.city': 'City',
  'fields_lang.company': 'Company',
  'fields_lang.country': 'Country',
  'fields_lang.department': 'Department',
  'fields_lang.email': 'E-mail',
  'fields_lang.fullName': 'First name and last name',
  'fields_lang.guardian': "Your guardian's code",
  'fields_lang.invoiceEmail': 'Email e-invoices',
  'fields_lang.nip': 'TIN',
  'fields_lang.password': 'Password',
  'fields_lang.phone': 'Phone',
  'fields_lang.rePassword': 'Repeat password',
  'fields_lang.street': 'Street',
  'fields_lang.zipCode': 'Zip code',
  'footer.about_us': 'About us',
  'footer.contact': 'Contact',
  'footer.cookie_accept': 'Accept',
  'footer.customer_service': 'Customer service',
  'footer.how_to_buy': 'How to buy',
  'footer.information': 'Informations',
  'footer.mobile.account': 'Account',
  'footer.mobile.cart': 'Cart',
  'footer.mobile.categories': 'Menu',
  'footer.mobile.home': 'Home',
  'global.delete': 'Delete',
  'global.export_to_file': 'Export to file',
  'global.loading_prices': 'Loading prices',
  'global.no_photo': 'No photo',
  'global.remove': 'Remove',
  'goods_issued_notes.details.city': 'City',
  'goods_issued_notes.details.client_data': 'Client data',
  'goods_issued_notes.details.country': 'Country',
  'goods_issued_notes.details.delivery_address': 'Delivery address',
  'goods_issued_notes.details.name': 'Name',
  'goods_issued_notes.details.postCode': 'ZIP Code',
  'goods_issued_notes.details.price': 'Price [Net/Gross]',
  'goods_issued_notes.details.products': 'Products',
  'goods_issued_notes.details.products_title': 'Products for goods issued note with the number ',
  'goods_issued_notes.details.quantity': 'Quantity',
  'goods_issued_notes.details.street': 'Street',
  'goods_issued_notes.details.tin': 'TIN',
  'goods_issued_notes.details.value': 'Value [Net/Gross]',
  'goods_issued_notes.lp': 'L.p.',
  'goods_issued_notes.note_date': 'Document date',
  'goods_issued_notes.note_details': 'View goods issued note',
  'goods_issued_notes.note_gross_value': 'Gross value',
  'goods_issued_notes.note_invoice': 'Invoice',
  'goods_issued_notes.note_number': 'Goods issued note',
  'goods_issued_notes.note_order': 'View order',
  'goods_issued_notes.note_orders': 'Order',
  'goods_issued_notes.note_pdf': 'Download GIN as PDF',
  'goods_issued_notes.note_sale_date': 'Sale date',
  'goods_issued_notes.note_search': 'Search in goods issued note',
  'goods_issued_notes.note_sort': 'Sort goods issued note',
  'goods_issued_notes.note_sort_asc': 'Ascending',
  'goods_issued_notes.note_sort_desc': 'Descending',
  'goods_issued_notes.notes': 'Notes',
  'goods_issued_notes.notes_title': 'Goods issued notes',
  'goods_issued_notes.options': 'Options',
  'goods_issued_notes.sum_selected': 'Sum selected goods issued notes gross',
  'goods_received_notes.details.city': 'City',
  'goods_received_notes.details.client_data': 'Client data',
  'goods_received_notes.details.country': 'Country',
  'goods_received_notes.details.delivery_address': 'Delivery address',
  'goods_received_notes.details.name': 'Name',
  'goods_received_notes.details.postCode': 'ZIP Code',
  'goods_received_notes.details.price': 'Price [Net/Gross]',
  'goods_received_notes.details.products': 'Products',
  'goods_received_notes.details.products_title': 'Products for goods received note with the number ',
  'goods_received_notes.details.quantity': 'Quantity',
  'goods_received_notes.details.street': 'Street',
  'goods_received_notes.details.tin': 'TIN',
  'goods_received_notes.details.value': 'Value [Net/Gross]',
  'goods_received_notes.lp': 'L.p.',
  'goods_received_notes.note_date': 'Document date',
  'goods_received_notes.note_details': 'View goods received note',
  'goods_received_notes.note_gross_value': 'Gross value',
  'goods_received_notes.note_invoice': 'Invoice',
  'goods_received_notes.note_number': 'Goods received note',
  'goods_received_notes.note_order': 'View order',
  'goods_received_notes.note_orders': 'Order',
  'goods_received_notes.note_pdf': 'Download GIN as PDF',
  'goods_received_notes.note_sale_date': 'Sale date',
  'goods_received_notes.note_search': 'Search in goods received note',
  'goods_received_notes.note_sort': 'Sort goods received note',
  'goods_received_notes.note_sort_asc': 'Ascending',
  'goods_received_notes.note_sort_desc': 'Descending',
  'goods_received_notes.notes': 'Notes',
  'goods_received_notes.notes_title': 'Goods received notes',
  'goods_received_notes.options': 'Options',
  'goods_received_notes.sum_selected': 'Sum selected goods received notes gross',
  'header.about_us': 'About us',
  'header.account': 'Account',
  'header.address': 'Address',
  'header.brands': 'Brands',
  'header.categories': 'Categories',
  'header.choose_office': 'Choose branch',
  'header.choose_right_office': 'Choose right office',
  'header.hot_deals': 'Hot Deals',
  'header.login': 'Login',
  'header.logout': 'Logout',
  'header.manager': 'Account manager',
  'header.messages': 'Messages',
  'header.missing_warehouse_address': 'Address not available',
  'header.news': 'News',
  'header.open_hours': 'Open hours',
  'header.our_offices': 'Our offices',
  'header.phone': 'Phone',
  'header.PLN': 'PLN',
  'header.price_gross': 'VAT incl.',
  'header.price_net': 'VAT excl.',
  'header.promotions': 'Promotions',
  'header.register': 'Register',
  'header.select_city': 'Select/type city',
  'header.select_office': 'Select branch',
  'header.selected_office': 'Selected branch',
  'header.stepCart': 'Your cart',
  'header.stepCheckoutData': 'Address data',
  'header.stepCheckoutSummary': 'Summary',
  'home.cms.list_with_megapopup.all': 'All',
  'home.cms.top_products.errors.product_404': 'Product {product} does not exists',
  'home.cms.top_products.errors.product_not_set': 'Set proper SKU in backoffice to show product',
  'home.cms.top_products.loading': 'Loading',
  'invoices_details.corrections_invoice': 'Corrections to the invoice',
  'invoices_details.detail_info': 'Detail info',
  'invoices_details.download': 'Download',
  'invoices_details.download_pdf': 'Download the invoice as PDF',
  'invoices_details.export': 'Export to file',
  'invoices_details.goods_issue_notes': 'Goods issued notes',
  'invoices_details.goods_received_notes': 'Goods received notes',
  'invoices_details.gross_value': 'Gross value',
  'invoices_details.invoice_correction': 'Invoice for correction',
  'invoices_details.invoice_email_error': 'The e-invoice shipping address could not be saved',
  'invoices_details.invoice_email_success': 'E-invoice shipping address has been saved',
  'invoices_details.invoice_email_title': 'Address for sending e-invoices',
  'invoices_details.invoice_reference_number': 'Invoice reference number',
  'invoices_details.is_not_paid_lang': 'Is not paid',
  'invoices_details.left_pay': 'Left to pay',
  'invoices_details.net_value': 'Net value',
  'invoices_details.order_number': 'Order number',
  'invoices_details.order_numbers': 'Order numbers',
  'invoices_details.paid_lang': 'Paid',
  'invoices_details.pay': 'Pay',
  'invoices_details.print': 'Print',
  'invoices_details.products_in_invoice': 'Products for invoice with the number ',
  'invoices_details.purchase_numbers': 'Purchase numbers',
  'invoices_details.sale_date': 'Sale date',
  'invoices_details.status': 'Invoice status',
  'invoices_details.summary': 'Summary',
  'invoices_details.tax_rate': 'Tax rate',
  'invoices_details.tax_value': 'Tax value',
  'invoices_details.update': 'Save',
  'invoices_details.value_paid': 'Gross value paid',
  'invoices_details.your_order_number': 'Your order number',
  'invoices_info.account_number': 'Account number for payment',
  'invoices_info.granted_amount': 'Granted amount',
  'invoices_info.info': 'If you want to change the amount of the trade credit, contact your guardian.',
  'invoices_info.remaining_amount_used': 'The remaining amount to be used',
  'invoices_info.see_invoices': 'See invoices',
  'invoices_info.sum_unpaid_invoices': 'Sum of unpaid invoices',
  'invoices_info.term_payment': 'Term payment',
  'invoices_info.trade_credit': 'Trade credit',
  'invoices_info.unpaid_invoices': 'Unpaid invoices',
  'invoices.banner.message': 'There are late unpaid invoices in your account, please settle the payment. ',
  'invoices.banner.show_more': 'Show invoices',
  'invoices.category': 'Category',
  'invoices.check_all_invoices': 'Select all invoices',
  'invoices.check_overdue_invoices': 'Show overdue invoices',
  'invoices.check_unpaid_invoices': 'Show unpaid invoices',
  'invoices.date_header': 'Date',
  'invoices.date_payment_header': 'Date of payment',
  'invoices.days_delays_header': 'Delays days',
  'invoices.details_header': 'Details',
  'invoices.error_search': 'No search results found',
  'invoices.hide_details': 'Hide',
  'invoices.invoice_number_header': 'Invoice/correction number',
  'invoices.lp': 'L.p',
  'invoices.more_details': 'More',
  'invoices.search': 'Search in invoices',
  'invoices.search_placeholder': 'Search',
  'invoices.show_invoice': 'View invoice',
  'invoices.sum_remaining_invoice': 'The sum remaining to be paid gross',
  'invoices.sum_selected_invoice': 'The sum of selected gross invoices',
  'invoices.value_header': 'Value gross',
  'login_page.back_login_page': 'Return to login page',
  'login_page.create_account_label': 'Create account',
  'login_page.error_change_password': 'An error occured',
  'login_page.forgot_password_label': 'Forgot password?',
  'login_page.incorrect_login': 'Incorrect username or password',
  'login_page.login': 'Email',
  'login_page.login_label': 'Log in',
  'login_page.password': 'Password',
  'login_page.registration_label': 'Registration',
  'login_page.reset_info': 'A password recovery instruction will be sent to the email address you provided',
  'login_page.reset_label': 'Reset password',
  'login_page.reset_title': 'Reset your password',
  'login_page.show_password': 'Show password',
  'login_page.success_change_password': 'A password recovery instruction will be sent to the email address you provided',
  'login_page.title': 'Log in to your account',
  'my_account.add_photo': 'Add photo',
  'my_account.admin': 'Admin',
  'my_account.change_password': 'Change password',
  'my_account.login': 'Email/login',
  'my_account.name': 'Name',
  'my_account.new_password': 'New password',
  'my_account.notification_error': 'Failed to',
  'my_account.notification_update_password': 'Updated data',
  'my_account.notification_update_user': 'Updated data',
  'my_account.old_password': 'Old password',
  'my_account.password': 'Password',
  'my_account.permissions': 'Permissions',
  'my_account.phone': 'Phone number',
  'my_account.title': 'Account information',
  'my_account.user': 'User',
  'new_user_account.add': 'New user',
  'new_user_account.cancel': 'Cancel',
  'new_user_account.edit': 'Edit user',
  'new_user_account.email': 'E-mail',
  'new_user_account.name': 'Name and surname',
  'new_user_account.password': 'Password',
  'new_user_account.phone': 'Phone number',
  'new_user_account.rePassword': 'Repeat password',
  'new_user_account.roles': 'Roles',
  'new_user_account.save': 'Save',
  'newsletter.btnText': 'Subscribe',
  'newsletter.placeholder': 'Enter Your e-mail...',
  'newsletter.text': 'Do You want to be notified about current promotions and sales?',
  'newsletter.title': 'Newsletter',
  'notifications_page.email_notifications': 'Email notifications',
  'notifications_page.invoices': 'Invoices',
  'notifications_page.invoices_sent': 'Invoices sent',
  'notifications_page.offers': 'Offers',
  'notifications_page.offers_suggestions': 'Offers suggestions',
  'notifications_page.order_confirmation': 'Order confirmation',
  'notifications_page.order_status': 'Status of the order',
  'notifications_page.order_waiting_pickup': 'The order is waiting for pickup',
  'notifications_page.order_waybill': 'Orders shipped with a waybill',
  'notifications_page.title': 'Notification settings',
  'offers_page.accept': 'accept',
  'offers_page.created_date_filter': 'Creation date',
  'offers_page.date_from': 'Start date',
  'offers_page.date_to': 'End date',
  'offers_page.error_search': 'Not fount',
  'offers_page.expiration_date_filter': 'Expiration date',
  'offers_page.found': 'Found',
  'offers_page.get_pdf': 'PDF',
  'offers_page.header.created_date': 'Creation date',
  'offers_page.header.expiration_date': 'Expiration date',
  'offers_page.header.offer_number': 'Offer number',
  'offers_page.header.preview': 'Preview',
  'offers_page.header.products_amount': 'Amount of products',
  'offers_page.header.rfq_number': 'RFQ number',
  'offers_page.header.status': 'Status',
  'offers_page.header.value': 'Value',
  'offers_page.header.version': 'Version',
  'offers_page.not_found': 'Not found',
  'offers_page.offer_search': 'Search in offers',
  'offers_page.offer_search_placeholder': 'Search by offer number or RFQ number',
  'offers_page.status_filter': 'Status',
  'offers_page.status_filter_placeholder': 'Filter by status',
  'offers_page.statuses.offered': 'Offered',
  'offers_page.statuses.overdue': 'Overdue',
  'offers_page.statuses.won': 'Won',
  'offers_page.title': 'Offers',
  'offline': 'Currently you are offline. Please check connection',
  'order_authorization_page.details': 'Authorize',
  'order_authorization_page.error': 'No orders to authorize',
  'order_authorization_page.name': 'Cart name',
  'order_authorization_page.payment': 'Payment',
  'order_authorization_page.price': 'Value [Gross/Net]',
  'order_authorization_page.user': 'User',
  'order_detail.back': '< return to the orders view',
  'order_detail.billing_address': 'Billing address',
  'order_detail.contact_person': 'Contact person',
  'order_detail.courier': 'Courier',
  'order_detail.date': 'Date',
  'order_detail.delivery_address': 'Delivery address',
  'order_detail.delivery_date': 'Delivery date',
  'order_detail.delivery_method': 'Delivery method',
  'order_detail.detail_note': 'Notes',
  'order_detail.estimate': 'Estimated delivery',
  'order_detail.full_value_basket': 'Total value',
  'order_detail.gross_price': 'Value [Gross/Net]',
  'order_detail.invoices': 'Invoices',
  'order_detail.manufacturer': 'Manufacturer',
  'order_detail.manufacturer_index': 'Manufacturer Index',
  'order_detail.net_price': 'Price [Net]',
  'order_detail.note': 'Note',
  'order_detail.order': 'Order',
  'order_detail.order_quantity': 'Ordered quantity',
  'order_detail.payment_method': 'Purchase no',
  'order_detail.pickup': 'Pick-up',
  'order_detail.purchase_no': 'Customer PO no',
  'order_detail.reserved_quantity': 'Reserved quantity',
  'order_detail.shipment': 'Shipment',
  'order_detail.shipments': 'Shipments',
  'order_detail.shipped_quantity': 'Shipped quantity',
  'order_detail.status': 'Status',
  'order_detail.title': 'Product',
  'order_detail.user': 'User',
  'order_detail.waybills': 'Waybills',
  'order.modals.renew_order.accept_button': 'Retry',
  'order.modals.renew_order.actions': 'Actions',
  'order.modals.renew_order.amount': 'Quantity',
  'order.modals.renew_order.cancel_button': 'Cancel',
  'order.modals.renew_order.empty_products_list': 'There are no products that meet the criteria for reordering',
  'order.modals.renew_order.error_message': 'You cannot reorder products that are out of stock',
  'order.modals.renew_order.header.actions': 'Actions',
  'order.modals.renew_order.header.amount': 'Quantity',
  'order.modals.renew_order.header.price': 'Price',
  'order.modals.renew_order.header.remove_selected_products': 'Remove selected products',
  'order.modals.renew_order.header.select_all_products': 'Select all products',
  'order.modals.renew_order.header.warehouse': 'Warehouse',
  'order.modals.renew_order.price': 'Price',
  'order.modals.renew_order.product_unavailable': 'Unavailable',
  'order.modals.renew_order.product.add_product': '{0} of {1} products added to cart',
  'order.modals.renew_order.product.error': 'The following products are unavailable or out of stock',
  'order.modals.renew_order.product.general_error': 'Out of stock or product unavailable',
  'order.modals.renew_order.product.select': 'Select',
  'order.modals.renew_order.stock': 'Stock',
  'order.modals.renew_order.title': 'Retry order',
  'orders_history_page.btn_pay': 'Pay',
  'orders_history_page.button_hide_more.hide': 'Hide',
  'orders_history_page.button_hide_more.more': 'More',
  'orders_history_page.button_hide_more.show': 'Show',
  'orders_history_page.details.address_text': 'Address',
  'orders_history_page.details.courier': 'Courier',
  'orders_history_page.details.export_pdf_text': 'Export PDF',
  'orders_history_page.details.export_to_file_text': 'Export to file',
  'orders_history_page.details.location_text': 'Shipping method',
  'orders_history_page.details.more_shipment': 'View detail shipment',
  'orders_history_page.details.price_text': 'Price',
  'orders_history_page.details.shipment_text': 'Shipment',
  'orders_history_page.details.status_text': 'Status',
  'orders_history_page.details.view_order_text': 'View order',
  'orders_history_page.details.view_shipment': 'View shipment',
  'orders_history_page.details.warehouse': 'Warehouse',
  'orders_history_page.error_search': 'No search results found',
  'orders_history_page.found': 'Found items',
  'orders_history_page.header.date': 'Date',
  'orders_history_page.header.details': 'Details',
  'orders_history_page.header.order': 'Order no./Your purchase no.',
  'orders_history_page.header.pay': 'Value [Gross/Net]',
  'orders_history_page.header.payment': 'Payment',
  'orders_history_page.header.user': 'User',
  'orders_history_page.not_found': 'Not found',
  'orders_history_page.order_offline': 'In store orders',
  'orders_history_page.order_online': 'Online orders',
  'orders_history_page.order_search': 'Search in orders',
  'orders_history_page.order_statuses': 'Order statuses',
  'orders_history_page.title': 'Orders history',
  'pages.account': 'Account',
  'pages.account_info': 'My account',
  'pages.addresses': 'Addresses',
  'pages.agreements': 'Agreements',
  'pages.billing': 'Billing address',
  'pages.blog': 'Blog',
  'pages.cart': 'Cart',
  'pages.checkout': 'Checkout',
  'pages.complains': 'Complains and returns',
  'pages.contact': 'Contact',
  'pages.goods_issued_notes': 'Goods issued notes',
  'pages.goods_received_notes': 'Goods received notes',
  'pages.index': 'Home',
  'pages.invoices': 'Invoices',
  'pages.invoices_goods_issue_notes': 'Invoices and goods issued notes',
  'pages.offers': 'Offers',
  'pages.order_authorization': 'Order authorization',
  'pages.order_summary': 'Order summary',
  'pages.orders_history': 'Orders history',
  'pages.rfq': 'RFQs',
  'pages.rfqs_offers': 'Offers and RFQs',
  'pages.roles': 'Roles',
  'pages.users': 'Users',
  'pages.wishlist': 'Wishlist',
  'powered_by': 'Powered by',
  'price.gross': 'gross',
  'price.lowest_price': 'The lowest price in the last {0} days',
  'price.net': 'net',
  'product_block.cannot_add': 'Cannot add',
  'product_block.invalid_amount': 'Request amount is invalid',
  'product_block.is_blocked': 'is blocked',
  'product_block.no_price_available': 'Price for this product is not available',
  'product_block.product': 'Product',
  'product_block.product_blocked_one': 'You cannot add product with SKU',
  'product_block.product_blocked_two': 'with following labels:',
  'product_block.text': 'The max possible quantity to buy is ',
  'product_block.title_one': 'It is not possible to add ',
  'product_block.title_two': ' to the cart over stock',
  'product_page.add_to_cart': 'To cart',
  'product_page.add_to_wishlist': 'Add to wishlist',
  'product_page.alert_added_all_to_cart': 'All products have been added to the cart',
  'product_page.alert_added_to_cart': 'Added to cart',
  'product_page.alert_added_to_wishlist': 'Added to wishlist',
  'product_page.brand': 'Brand',
  'product_page.brand_description': 'About brand',
  'product_page.category': 'Category',
  'product_page.certificates': 'Certificates',
  'product_page.complementary_products': 'Complementary products',
  'product_page.data_sheets': 'Data Sheets',
  'product_page.description': 'Description',
  'product_page.discount': 'Discount',
  'product_page.download': 'Download',
  'product_page.hour': 'h',
  'product_page.insufficient_quantity_stock': 'Insufficient stock quantity',
  'product_page.interval_qty': 'Interval',
  'product_page.login_or_register_to_buy': 'Sign in or sign up to buy',
  'product_page.login_to_buy': 'Sign in to buy',
  'product_page.manufacturer': 'Manufacturer',
  'product_page.manufacturer_index': 'Manufacturer index',
  'product_page.catalog_index': 'Product EAN',
  'product_page.min_qty': 'Minimum',
  'product_page.more_stocks': 'See more magazines ({count})',
  'product_page.price_unavailable': 'No price',
  'product_page.product_id': 'Product ID',
  'product_page.product_options': 'Product options',
  'product_page.product_size': 'Product size',
  'product_page.related_accessories': 'Related accessories',
  'product_page.related_products': 'Related products',
  'product_page.related_products.actions': 'Actions',
  'product_page.related_products.availability': 'Availability',
  'product_page.related_products.price': 'Price',
  'product_page.related_products.show_more': 'Show more',
  'product_page.related_products.variants': 'Product variants',
  'product_page.series': 'Series',
  'product_page.shipping_time': 'Shipping time',
  'product_page.technical_data': 'Technical data',
  'product_page.unavailable': 'Stocks information is unavailable now',
  'product_page.usage': 'Usage',
  'products_list.categories': 'Categories',
  'products_list.delete_all_filters': 'Delete all filters',
  'products_list.empty.default': "Unfortunately, we didn't find any products that meet your expectations.",
  'products_list.empty.link_container': 'Change your criteria or {0}.',
  'products_list.empty.link_text': 'see all products in {0}',
  'products_list.empty.not_found': 'Product "{0}" not found for set filters. Would you like to search through the entire catalogue?',
  'products_list.empty.search': 'Search',
  'products_list.filters': 'Filters',
  'products_list.found_items': 'Found items',
  'products_list.no_categories': 'No categories',
  'products_list.no_products': 'No products found',
  'products_list.price': 'Price',
  'products_list.promotions': 'Promotions',
  'products_list.searched_in_all_categories': 'We searched for all categories, here are the results:',
  'products_list.select_filters': 'Selected filters',
  'products_list.show_products': 'Show products',
  'products_list.sort_high_price': 'Sort: highest price',
  'products_list.sort_low_price': 'Sort: lowest price',
  'products_list.sort_name_ascending': 'Sort: name ASC',
  'products_list.sort_name_descending': 'Sort: name DESC',
  'pwa.reload': 'Refresh',
  'pwa.update': 'New version of the app is available',
  'registration.account': 'Account',
  'registration.address': 'Address details',
  'registration.agreements': 'Agreements',
  'registration.choose_account_type': 'Choose account type',
  'registration.company_label': 'Company',
  'registration.company.city': 'City',
  'registration.company.code_guardian': "Your guardian's code",
  'registration.company.country': 'Country',
  'registration.company.department': 'Department',
  'registration.company.name': 'Company name',
  'registration.company.street': 'Street',
  'registration.company.tin': 'TIN',
  'registration.company.zip_code': 'Zip code',
  'registration.contact_person': 'Contact person',
  'registration.customer_label': 'Private person',
  'registration.data_download_error': 'Address data download error',
  'registration.data_downloaded': 'Address data downloaded',
  'registration.email': 'E-mail',
  'registration.errors.country_required': 'Country is required',
  'registration.errors.default': 'Failed to create new customer',
  'registration.errors.email_in_use': 'This email address is already in use',
  'registration.errors.invalid_email': 'This email address is invalid',
  'registration.errors.tin_in_use': 'This TIN number is already in use',
  'registration.field_minimum': 'Field {name} should contain minimum',
  'registration.field_required': 'Field {name} is required',
  'registration.get_gus_data': 'Get address data',
  'registration.info.four': 'Individual promotional campaigns',
  'registration.info.one': 'Individual commercial conditions',
  'registration.info.three': 'Customer panel',
  'registration.info.title': 'Join us',
  'registration.info.two': 'Dedicated sales manager',
  'registration.invoice': 'Billing',
  'registration.isNotValid': 'Complete all fields',
  'registration.name': 'First name and last name',
  'registration.password': 'Password',
  'registration.password_error': 'Password is required. It should contain: one capital letter, one number or special character, min. 8 characters.',
  'registration.phone': 'Phone',
  'registration.re_password': 'Repeat password',
  'registration.rules_lowercase': '1 lowercase character',
  'registration.rules_min': 'characters',
  'registration.rules_special_char': '1 digit or 1 special character',
  'registration.rules_uppercase': '1 uppercase character',
  'registration.save': 'Register',
  'registration.success': 'A new customer has been created',
  'registration.tin_not_found': 'TIN not found',
  'registration.title': 'Account information',
  'registration.user_data': 'Account information',
  'rfq_detail.back': '< return to the orders view',
  'rfq_detail.billing_address': 'Billing address',
  'rfq_detail.contact_person': 'Contact person',
  'rfq_detail.courier': 'Courier',
  'rfq_detail.delivery_address': 'Delivery address',
  'rfq_detail.delivery_date': 'Delivery date',
  'rfq_detail.delivery_method': 'Delivery method',
  'rfq_detail.detail_note': 'Notes',
  'rfq_detail.estimate': 'Estimated delivery',
  'rfq_detail.full_value_basket': 'Total value',
  'rfq_detail.gross_price': 'Value [Gross/Net]',
  'rfq_detail.invoices': 'Invoices',
  'rfq_detail.manufacturer': 'Manufacturer',
  'rfq_detail.manufacturer_index': 'Manufacturer Index',
  'rfq_detail.net_price': 'Price [Net]',
  'rfq_detail.note': 'Note',
  'rfq_detail.order': 'Request for quotation',
  'rfq_detail.order_quantity': 'Ordered quantity',
  'rfq_detail.payment_method': 'Purchase no',
  'rfq_detail.pickup': 'Pick-up',
  'rfq_detail.purchase_no': 'Customer PO no',
  'rfq_detail.shipment': 'Shipment',
  'rfq_detail.shipments': 'Shipments',
  'rfq_detail.shipped_quantity': 'Shipped quantity',
  'rfq_detail.status': 'Status',
  'rfq_detail.title': 'Product',
  'rfq_detail.user': 'User',
  'rfq_detail.waybills': 'Waybills',
  'rfq_page.button_hide_more.hide': 'Hide',
  'rfq_page.button_hide_more.more': 'More',
  'rfq_page.details.address_text': 'Address',
  'rfq_page.details.courier': 'Courier',
  'rfq_page.details.export_pdf_text': 'Export PDF',
  'rfq_page.details.export_to_file_text': 'Export to file',
  'rfq_page.details.location_text': 'Shipping method',
  'rfq_page.details.more_shipment': 'View detail request for quotation',
  'rfq_page.details.price_text': 'Price',
  'rfq_page.details.shipment_text': 'Request for quotation',
  'rfq_page.details.status_text': 'Status',
  'rfq_page.details.status.sent': 'Sent',
  'rfq_page.details.view_order_text': 'View request for quotation',
  'rfq_page.details.view_shipment': 'View request for quotation',
  'rfq_page.details.warehouse': 'Warehouse',
  'rfq_page.error_search': 'No search results found',
  'rfq_page.found': 'Found items',
  'rfq_page.header.date': 'Date',
  'rfq_page.header.details': 'Details',
  'rfq_page.header.order': 'Offer',
  'rfq_page.header.pay': 'Value [Gross/Net]',
  'rfq_page.header.payment': 'Payment',
  'rfq_page.header.user': 'User',
  'rfq_page.not_found': 'Not found',
  'rfq_page.order_offline': 'In store orders',
  'rfq_page.order_online': 'Request for quotation',
  'rfq_page.rfq_search': 'Search in offers',
  'rfq_page.title': 'Request for quotation',
  'roles_page_list_element.tooltip.edit_role': 'Edit roles',
  'roles_page_list_element.tooltip.info': 'See role permissions',
  'roles_page_list_element.tooltip.remove_role': 'Delete roles',
  'roles_page.add_role': 'Add a new role',
  'roles_page.cancel': 'Cancel',
  'roles_page.edit_role': 'Edit role',
  'roles_page.label_permissions': 'Permissions',
  'roles_page.label_role': 'Role name',
  'roles_page.notif_create_role': 'Roles created',
  'roles_page.notif_delete_role': 'Role removed',
  'roles_page.notif_edit_role': 'The role has been updated',
  'roles_page.notif_error': 'Failed to',
  'roles_page.save': 'Save',
  'roles_page.title': 'Roles',
  'search.all_categories': 'All categories',
  'search.category_breadcrumb': "Search in category '{categoryName}'",
  'search.default_breadcrumb': 'Search',
  'search.filter_date': 'Choose date range',
  'search.filter_end_date': 'End date',
  'search.filter_start_date': 'Starting date',
  'search.placeholder': 'What are you looking for...',
  'search.query_breadcrumb': "Search results for '{searchString}'",
  'search.suggest.categories': 'Categories',
  'search.suggest.manufacturers': 'Manufacturers',
  'search.suggest.products': 'Products',
  'sidebar.advices': 'Advices',
  'sidebar.categories': 'Categories',
  'sidebar.manufacturers': 'Manufacturers',
  'sidebar.messages': 'Messages',
  'sidebar.promotions': 'Promotions',
  'sidebar.sales': 'Sales',
  'sidebar.title': 'Menu',
  'user_page_list_element.logged': 'Logged in user',
  'user_page_list_element.reset_password': 'Reset password',
  'user_page_list_element.reset_password_message': 'A link resetting the password was sent to the {0} email address',
  'user_page_list_element.reset_password_modal_text': 'Are you sure you want to reset your password for {0}?',
  'user_page_list_element.tooltip.block_user': 'Block the user',
  'user_page_list_element.tooltip.delete_user': 'Delete user',
  'user_page_list_element.tooltip.edit_user': 'Edit user',
  'user_page_list_element.tooltip.unblock_user': 'Unlock the user',
  'user_page.add_users': 'Add users',
  'user_page.error': 'Failed to',
  'user_page.error_user_exists': 'The e-mail address is busy',
  'user_page.information_create_new_user': 'Create new user:',
  'user_page.login': 'Login:',
  'user_page.password': 'Password:',
  'user_page.permissions': 'Permissions',
  'user_page.remove_user': 'Remove user',
  'user_page.roles': 'Roles',
  'user_page.search': 'Search in users',
  'user_page.search_placeholder': 'Search in users',
  'user_page.success_add_user': 'New user added',
  'user_page.success_edit_user': 'User was edited',
  'user_page.title': 'Users',
  'user_page.update': 'Updated',
  'wishlist.add_all': 'Add all',
  'wishlist.create_cart_from_wishlist': 'Create cart from shopping list',
  'wishlist.default_name': 'Shopping list',
  'wishlist.dropdown.add_new': 'Add new wishlist',
  'wishlist.dropdown.delete': 'Delete current wishlist',
  'wishlist.dropdown.rename': 'Rename wishlist',
  'wishlist.empty_wishlist': 'Empty shopping list',
  'wishlist.modals.add_placeholder': 'Enter wishlist name',
  'wishlist.modals.add_title': 'Create new wishlist',
  'wishlist.modals.cancel_btn': 'Cancel',
  'wishlist.modals.remove_confirm_btn': 'Remove',
  'wishlist.modals.remove_text': 'Please confirm wishlist removal',
  'wishlist.modals.remove_title': 'Remove',
  'wishlist.modals.rename_ok_btn': 'Rename',
  'wishlist.modals.rename_placeholder': 'Enter wishlist name',
  'wishlist.modals.rename_title': 'Rename wishlist',
  'wishlist.modals.save_btn': 'Save',
  'wishlist.modals.wishlist_created': 'Wishlist created',
  'wishlist.modals.wishlist_removed': 'Wishlist deleted',
  'wishlist.modals.wishlist_renamed': 'Wishlist renamed',
  'utils.add': 'Add',
  'cart.promotions': 'Promotions',
  'cart.promotions.reset': 'Reset promotions',
  'cart.promotions.reset.success': 'Promotions successfully reset',
  'cart.promotions.link': 'Link',
  'cart.promotions.granted': 'Granted promotions',
  'cart.promotions.added': 'Added promotions',
  'cart.promotions.available.list': 'List of additional promotions',
  'cart.promotions.not_available': 'No additional promotions available'
}

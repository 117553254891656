

























  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  @Component
  export default class OneButton extends Vue {
    isLoading: boolean = false
    showAnimationCircle: boolean = false
    emitLocked: boolean = false
    debounceTimeoutHandler: any = null

    @Prop({
      type: String,
      required: false,
      default: 'fade',
    })
    loaderTransition!: string;

    @Prop({
      type: String,
      required: false,
      default: 'out-in',
    })
    loaderTransitionMode!: string;

    @Prop({
      type: String,
      required: false,
      default: '#fff',
    })
    loaderIndicatorColor!: string;

    @Prop({
      type: String,
      required: false,
      default: '#fff',
    })
    loaderColorFill!: string;

    @Prop({
      type: Number,
      required: false,
      default: 0.9,
    })
    loaderScale!: number;

    @Prop({
      type: String,
      required: false,
    })
    label!: string;

    @Prop({
      type: String,
      required: false,
    })
    testId!: string;

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    progress!: boolean;

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    animated!: boolean;

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    disabled!: boolean;

    @Prop({
      type: String,
      required: false,
    })
    type!: string;

    @Prop({
      type: Number,
      required: false,
      default: 150,
    })
    debounceTimeout!: number;

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    outline!: boolean;

    onLoaderSlided() {
      this.isLoading = false
    }

    destroyed() {
      clearTimeout(this.debounceTimeoutHandler)
    }

    onClick() {
      if (!this.emitLocked) {
        this.emitLocked = true
        this.$emit('click')
        this.debounceTimeoutHandler = setTimeout(() => {
          this.emitLocked = false
        }, this.debounceTimeout)
      }
    }

    @Watch('progress', { immediate: true })
    onProgressChange() {
      if (this.progress) {
        this.isLoading = true
      } else if (!this.animated) {
        this.isLoading = false
      }
      this.showAnimationCircle = this.progress
    }

    get classes() {
      return {
        'one-button--outlined': this.outline,
      }
    }
  }

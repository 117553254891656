
export default (ctx) => {
  // For @nuxt/sentry 3.0.0
  if (typeof ctx.app.$sentry !== 'undefined') {
    ctx.app.$sentry.getCurrentHub().getClient().getOptions().environment = ctx.app.$config.SENTRY_ENVIRONMENT || 'dev'
    ctx.app.$sentry.setTag('SSR', process.server)
  }
  // For @nuxt/sentry 5.0.0
  // const sentryClient = ctx.app.$sentry.getCurrentHub().getClient()
  // if (typeof sentryClient !== 'undefined') {
  //   ctx.app.$sentry.getCurrentHub().getClient().getOptions().environment = ctx.app.$config.SENTRY_ENVIRONMENT || 'dev'
  //   ctx.app.$sentry.setTag('SSR', process.server)
  // }
}

import Vue from 'vue'
import '~/utils/closeable'
import { priceFormatter, parcelLockerAddressFormatter } from '~/utils/filters'

// register filters
Vue.filter('priceFormatter', priceFormatter)
Vue.filter('parcelLockerAddressFormatter', parcelLockerAddressFormatter)

export default (_, inject) => {
  if (typeof window !== 'undefined') {
    const vSelect = require('vue-select').VueSelect
    Vue.component('VSelect', vSelect)
  }
  inject('lib', {
    staticBucket: 'https://static-demo-images.s3-eu-west-1.amazonaws.com',
  })
}

import { Wishlist } from '@one/types/dist/orderpath/app'
import { MappedWishlist, WishlistState } from './types'

export default {
  getBasicWishlists: (state: WishlistState) =>
    state.basicWishlists,
  getWishlists: (state: WishlistState) => state.wishlists,
  getCurrentWishlist: (
    state: WishlistState,
  ): MappedWishlist | null =>
    state.selectedWishlist ? state.wishlists[state.selectedWishlist] : null,
  getCurrentWishlistId: (state: WishlistState): string | null =>
    state.selectedWishlist,
  getWishlistProductsKeys: (state: WishlistState) => (
    wishlistId: string,
  ) =>
    state.wishlists[wishlistId]
      ? Array.from(state.wishlists[wishlistId].products.keys())
      : null,
  getWishlistProduct: (state: WishlistState) => (
    wishlistId: string,
  ) => (productId: string) =>
    state.wishlists[wishlistId] &&
    state.wishlists[wishlistId].products.has(productId)
      ? state.wishlists[wishlistId].products.get(productId)
      : null,

  getWishlistItemsCount: (state: WishlistState) => (
    wishlistId: string,
  ) => {
    if (state.wishlists[wishlistId]) {
      return state.wishlists[wishlistId].products.size
    }
    return null
  },
  getBasicWishlistById: (state: WishlistState) => (
    id: string,
  ): Wishlist.Responses.BasicWishlist | null =>
    state.basicWishlists[id] ? state.basicWishlists[id] : null,
  getWishlistById: (state: WishlistState) => (
    id: string,
  ): MappedWishlist | null =>
    state.wishlists[id] ? state.wishlists[id] : null,
  getWishlistProducts: (state: WishlistState) => (
    wishlistId: string,
  ) =>
    state.wishlists[wishlistId] ? state.wishlists[wishlistId].products : null,
  getWishlistProductPrice: () => (
    prices: Wishlist.WishlistProductPricesDto,
    priceGross: boolean = false,
  ) => {
    if (!prices) { return null }
    if (priceGross) {
      return {
        price: prices.unitGross,
        catalogPrice: prices.catalogPriceGross,
        discount: prices.catalogPriceDiscount,
        line: prices.lineGross,
        lineTax: prices.lineTax,
        unit: prices.unitGross,
        unitTax: prices.unitTax,
      }
    } else {
      return {
        price: prices.unitNet,
        catalogPrice: prices.catalogPriceNet,
        discount: prices.catalogPriceDiscount,
        line: prices.lineNet,
        lineTax: prices.lineTax,
        unit: prices.unitNet,
        unitTax: prices.unitTax,
      }
    }
  },
}

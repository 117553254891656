










  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component
  export default class OneNotificationBanner extends Vue {
    name: string = 'one-notification-banner';
    show: boolean = true

    @Prop({
      type: Boolean,
      default: true,
    })
    tooltipIcon?: boolean;

    @Prop({
      type: Boolean,
      default: false,
    })
    closeIcon?: boolean;

    @Prop({
      type: Boolean,
      default: false,
    })
    warning?: boolean

    close() {
      this.show = false
      this.$emit('close-click')
    }
  }

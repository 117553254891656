









  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  const cart: BindingHelpers = namespace('cart')

  @Component
  export default class OneAdditionalCostName extends Vue {
    name: string = 'one-additional-cost-name'

    @cart.Getter getAdditionalCostName: any

    @Prop({
      required: true,
      type: String,
    })
    costType!: string
  }

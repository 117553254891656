import { Wishlist } from '@one/types/dist/orderpath/app'
export const arrangeProductsInWishlist = (
  wishlist: Wishlist.Responses.Wishlist,
): Map<string, Wishlist.WishlistProduct> => {
  const products = new Map<string, Wishlist.WishlistProduct>()
  wishlist.products.forEach((product: Wishlist.WishlistProduct) => {
    products.set(product.id, product)
  })
  return products
}

export const reorderProducts = (
  products: Map<string, Wishlist.WishlistProduct>,
  ordering: Array<string>,
) => {
  const newMapping = new Map<
    string,
    Wishlist.WishlistProduct
  >()
  ordering.forEach((productId: string) => {
    if (products.has(productId)) {
      newMapping.set(productId, products.get(productId)!)
    }
  })
  return newMapping
}

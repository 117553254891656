











  import { Component, Vue, Prop } from 'nuxt-property-decorator'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  import { namespace } from 'vuex-class'

  const products: BindingHelpers = namespace('products')

  @Component
  export default class OneQuantityAndIntervalInfo extends Vue {
    name: string = 'one-quantity-and-interval-info'

    @products.Getter getDisplayMode: any

    @Prop({
      type: Number,
      required: true,
    })
    quantityInterval!: number

    @Prop({
      type: Number,
      required: true,
    })
    quantityMin!: number

    get isVisible(): boolean {
      return this.quantityInterval > 1 && this.getDisplayMode !== 'MINI_LIST'
    }
  }

import { ActionContext } from 'vuex'
import {
  Shared,
} from '@one/types/dist/orderpath/app'
import { converters } from '@one/core'
import { RootState } from '../index'
import {
  CHANGE_SEARCH,
  CLEAR_OFFER_LIST,
  SET_DATE_FILTER,
  SET_PAGE,
  SET_PAGINATED_OFFERS,
  SET_STATUS_FILTER,
} from './mutations'
import { OffersState } from './index'

export const actions: any = {
  fetchOffersList({ state, commit }: ActionContext<OffersState, RootState>) {
    const {
      pageNumber,
      pageSize,
      search,
      status,
      createdOnStartDate,
      createdOnEndDate,
      expirationDateStartDate,
      expirationDateEndDate,
    } = state.fetchParams

    return this.$api.orderpath.app
      .getOffersList(
        pageNumber,
        pageSize,
        search,
        status,
        createdOnStartDate,
        createdOnEndDate,
        expirationDateStartDate,
        expirationDateEndDate,
      )
      .then((result) => {
        commit(SET_PAGINATED_OFFERS, result.data)
        return Promise.resolve(result)
      })
  },

  searchChanged(
    { commit, dispatch }: ActionContext<OffersState, RootState>,
    search: string | null,
  ) {
    commit(CLEAR_OFFER_LIST)
    commit(CHANGE_SEARCH, search)
    commit(SET_PAGE, 1)
    return dispatch('fetchOffersList')
  },
  dateFilterChanged(
    { commit, dispatch }: ActionContext<OffersState, RootState>,
    { dateType, value }: { dateType: string; value: Date | null },
  ) {
    const preparedDate: string | null = value ? converters.prepareFormatDate(value) : null
    commit(CLEAR_OFFER_LIST)
    commit(SET_DATE_FILTER, { dateType: dateType, value: preparedDate })
    commit(SET_PAGE, 1)
    return dispatch('fetchOffersList')
  },
  statusChanged(
    { commit, dispatch }: ActionContext<OffersState, RootState>,
    status: Shared.OfferStatusEnum,
  ) {
    commit(CLEAR_OFFER_LIST)
    commit(SET_STATUS_FILTER, status)
    commit(SET_PAGE, 1)
    return dispatch('fetchOffersList')
  },
  setPage(
    { commit, dispatch }: ActionContext<OffersState, RootState>,
    page: number,
  ) {
    commit(SET_PAGE, page)
    return dispatch('fetchOffersList')
  },
}
export default actions

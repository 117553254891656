import { Categories } from '@one/types'
import find from 'lodash-es/find.js'
import { CategoriesState } from './types'
import Category = Categories.Category

export default {
  getCategory: (state: CategoriesState) => (id: string): Category | null =>
    state.categories[id],
  isCategory: (state: CategoriesState) => (slug: string): boolean => {
    return !!find(Object.values(state.categories), { slug })
  },
  getCategoryByUrl: (state: CategoriesState) => (
    url: string,
  ): Category | null => {
    // @ts-ignore
    return find(Object.values(state.categories), { url }) || null
  },
  getCategoryChild: (state: CategoriesState) => (
    id: string,
  ): Array<number> | null => {
    const category = state.categories[id]
    if (!category) { return null }
    return category.children
  },
  mainCategories: (state: CategoriesState, getters: any) =>
    state.orderCategories.filter(
      (id: string) => !getters.getCategory(id).parent,
    ),
  sortedMainCategories: (
    _state: CategoriesState,
    getters: any,
    rootState: any,
  ) => {
    const sortCategories: boolean =
      rootState.products.productsList.alphabeticalCategoriesSorting
    return getters.mainCategories.sort((a: string, b: string) => {
      if (!sortCategories) {
        return 0
      }
      if (getters.getCategory(a).name < getters.getCategory(b).name) {
        return -1
      }
      if (getters.getCategory(a).name > getters.getCategory(b).name) {
        return 1
      }
      return 0
    })
  },
  getSelectedCategoryId: (state: CategoriesState): string | null => {
    return state.selectedCategoryId
  },
  getSelectedCategory: (state: CategoriesState): Category | null => {
    return (
      (state.selectedCategoryId &&
        state.categories[state.selectedCategoryId]) ||
      null
    )
  },
  getTraverseTreeForCategory: (state: CategoriesState) => (
    categoryId: string,
  ) => {
    const tree: Array<string> = []
    const traverseCategoriesTree = (
      arr: Array<string>,
      categoryId: string,
    ): void => {
      const cat: Categories.Category = state.categories[categoryId]
      if (cat) {
        arr.unshift(categoryId)
        if (cat.parent) {
          return traverseCategoriesTree(arr, cat.parent)
        }
      }
    }
    traverseCategoriesTree(tree, categoryId)
    return tree
  },
}

import {
  Cart,
} from '@one/types/dist/orderpath/app'
import { format } from 'date-fns'
import { BaseService } from '~/plugins/services/base'
import { CartState, MappedCartObject } from '~/store/cart/types'

export interface CartServiceOptions {
  dateFormat?: string,
}

export class CartService extends BaseService<CartState> {
  dateFormat: string = 'dd.MM.yyyy HH:mm:ss'

  constructor(context: any, options?: CartServiceOptions) {
    super(context, 'cart')
    if (options) {
      this.loadOptions(options)
    }
  }

  private setCartInLocalStorage(key: string, id: string) {
    const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
    userConfig.cartId = id
    localStorage?.setItem(key, JSON.stringify(userConfig))
  }

  private get getWarehouseId() {
    return this.store.getters['stocks/getDefaultWarehouseId']
  }

  private get getDefaultUserWarehouseId() {
    return this.store.state.stocks.defaultUserWarehouse
  }

  private setWarehouseId(warehouseId: string) {
    return this.store.commit('stocks/SET_DEFAULT_WAREHOUSE', warehouseId)
  }

  public clearCartFromLocalStorage(key: string) {
    const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
    delete userConfig.cartId
    localStorage?.setItem(key, JSON.stringify(userConfig))
  }

  private loadOptions(options: CartServiceOptions) {
    this.dateFormat = options.dateFormat || this.dateFormat
  }

  private createCartName(name?: string) {
    return `${this.i18n.t('cart.default_name')} - ${(name && name.trim()) || format(new Date(), this.dateFormat)}`
  }

  public get currentCartId(): string | null {
    return this.state.selectedCart
  }

  public get currentCart(): MappedCartObject | null {
    return this.store.getters['cart/getCurrentCart']
  }

  public async fetchAdditionalCosts() {
    const additionalCostTypes = new Set<string>()

   Array.from(this.currentCart?.products.values() ?? [])
        .map(product => product.prices?.additionalCosts)
        .flat()
        .forEach(additionalCostType => additionalCostType && additionalCostTypes.add(additionalCostType.type))
    Object.values(this.currentCart?.shipments ?? {})
      .map(shipment => shipment.prices?.additionalCosts)
      .flat()
      .forEach(cost => cost && additionalCostTypes.add(cost.type))
    await Promise.all(Array.from(additionalCostTypes).map((additionalCostType: string) => this.fetchAdditionalCostName(additionalCostType)))
  }

  public fetchCarts(): Promise<Array<Cart.Responses.BasicCartInfo>> {
    return this.dispatch<Array<Cart.Responses.BasicCartInfo>>('fetchCarts')
  }

  public fetchAdditionalCostName(additionalCostType: string) {
    return this.dispatch<any>('fetchAdditionalCostName', additionalCostType)
  }

  // TODO: set proper types for fetchPaymentsAndDeliveryMethods promise
  public fetchPaymentsAndDeliveryMethods(cart: Cart.Responses.NewCart | Cart.Responses.Cart): Promise<any> {
    return this.dispatch<any>('fetchPaymentsAndDeliveryMethods', cart)
  }

  public fetchAnonymousCart(cartId: string): Promise<Cart.Responses.Cart | null> {
    return this.dispatch<Cart.Responses.Cart>('fetchAnonymousCart', cartId)
  }

  public fetchCart(cartId: string | null): Promise<Cart.Responses.Cart> {
    return this.dispatch<Cart.Responses.Cart>('fetchCart', cartId || this.currentCartId)
  }

  public createCart(name?: string): Promise<Cart.Responses.NewCart> {
    const cartName = this.createCartName(name)
    return this.dispatch<Cart.Responses.NewCart>('createCart', {
      name: cartName,
      warehouseId: this.getDefaultUserWarehouseId,
    })
  }

  public renameCart(newName?: string) {
    return this.dispatch<void>('renameCart', {
      cartId: this.currentCartId,
      name: this.createCartName(newName),
    })
  }

  public selectCart(cartId: string): Promise<string | null> {
    return this.dispatch<string | null>('selectCart', cartId).then(
      (cartId: string | null) => {
        if (cartId) {
          const userKey: string =
            this.context.app.$auth.isAuthenticated ? this.store.state.account.currentUser.id : 'one-anonymous'
          this.setCartInLocalStorage(userKey, cartId)
        }
        return Promise.resolve(cartId)
      },
    ).catch((e) => {
      console.warn(e)
      return null
    })
  }

  public takeoverCart(cartId: string): Promise<Cart.Responses.NewCart> {
    return this.dispatch<Cart.Responses.NewCart>('takeoverCart', cartId)
  }

  public removeAnonymousCart(cartId: string): Promise<void> {
    return this.dispatch<void>('removeAnonymousCart', cartId)
  }

  public changeNumberOfProduct(cartId: string, warehouseId: string, payload: Cart.Requests.ChangeNumberOfProductInCart): Promise<void> {
    return this.dispatch<void>('changeNumberOfProduct', {
      cartId,
      warehouseId,
      payload,
    })
  }

  public setWarehouseContext(warehouseId: string, cartId?: string): Promise<void> {
    const cartToSetWarehouse: string | null = cartId || this.currentCartId
    if (!cartToSetWarehouse) {
      return Promise.reject(new Error('No cart selected'))
    }
    return this.dispatch<void>('setWarehouseContext', {
      cartId: cartToSetWarehouse,
      warehouseId,
    })
  }

  /**
   * @param cartId  If empty, currentCartId is used.
   */
  public removeCart(cartId?: string): Promise<void> {
    const cartToRemove: string | null = cartId || this.currentCartId
    if (!cartToRemove) {
      return Promise.reject(new Error('No cart to remove'))
    }
    return this.dispatch<void>('removeCart', cartToRemove).then(
      async () => {
        const basicCarts: Array<Cart.Responses.BasicCartInfo> = await this.fetchCarts()
        if (basicCarts.length) {
          await this.selectCart(basicCarts[0].id)
        } else {
          await this.createCartAndSelect()
        }
      },
    )
  }

  /**
   * Method creates new cart, then refetch basiccarts from BE and select first basic cart
   * @param name User friendly name. If empty, current date in format dd-MM-yyy is set
   */
  public async createCartAndSelect(name?: string): Promise<string> {
    const newCart: Cart.Responses.NewCart = await this.createCart(name)
    if (this.context.app.$auth.isAuthenticated) {
      await this.fetchCarts()
    }
    await this.selectCart(newCart.cartId)
    return newCart.cartId
  }

  public async fetchCartsAndSelectOrCreate(): Promise<string> {
    const basicCarts: Array<Cart.Responses.BasicCartInfo> = await this.fetchCarts()
    if (basicCarts.length) {
      const selectedCartId = await this.selectCart(basicCarts[0].id)
      if (selectedCartId) {
        return selectedCartId
      }
    }
    return await this.createCartAndSelect()
  }
}

import axios from 'axios'
import { NuxtState } from '@nuxt/types/app'
import { DEFAULT_LANGUAGE, defaultLocales } from '~/plugins/locale'

export default async ({ req, nuxtState }: { req: any, nuxtState: NuxtState }) => {
  const configuration = req ? req.configuration : nuxtState.state.config.configuration
  const url = req
    ? req.configuration.translationFileUrl
    : nuxtState.state.config.configuration.translationFileUrl
  const tenantKey = req?.configuration.tenantKey ?? nuxtState.state.config.tenantKey
  const locale = configuration.lang
  if (!url) {
    if (locale !== DEFAULT_LANGUAGE) {
      // @ts-ignore
      const loadedLocale = await defaultLocales[locale]()
      return loadedLocale.default
    }
    return null
  }
  try {
    const json = await axios.get(url)
    return json.data
  } catch (e) {
    console.warn(`[${tenantKey}] Failed to load custom locales.\n`, e)
    return null
  }
}

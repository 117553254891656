import {
  Cart,
  Shared,
} from '@one/types/dist/orderpath/app'
import ProductInCart = Shared.ProductInCart

export const getProductKeyAttribute = (product: Shared.ProductInCart | Shared.ProductId) => {
  return product.productLineId ? 'productLineId' : 'id'
}

export const arrangeProductsInCart = (
  cart: Cart.Responses.Cart,
): Map<string, ProductInCart> => {
  const products = new Map<string, ProductInCart>()
  cart.products.forEach((product: ProductInCart) => {
    const keyAttribute = getProductKeyAttribute(product)
    products.set(product[keyAttribute], product)
  })
  return products
}

export const reorderProducts = (
  products: Map<string, ProductInCart>,
  ordering: Array<string>,
) => {
  const newMapping = new Map<string, ProductInCart>()
  ordering.forEach((productId: string) => {
    if (products.has(productId)) {
      newMapping.set(productId, products.get(productId)!)
    }
  })
  return newMapping
}

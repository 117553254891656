import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/wishlist/index.ts'), 'wishlist/index.ts')
  resolveStoreModules(require('../store/stocks/index.ts'), 'stocks/index.ts')
  resolveStoreModules(require('../store/rma/index.ts'), 'rma/index.ts')
  resolveStoreModules(require('../store/products/index.ts'), 'products/index.ts')
  resolveStoreModules(require('../store/plugins/index.ts'), 'plugins/index.ts')
  resolveStoreModules(require('../store/payments/index.ts'), 'payments/index.ts')
  resolveStoreModules(require('../store/offers/index.ts'), 'offers/index.ts')
  resolveStoreModules(require('../store/layout/index.ts'), 'layout/index.ts')
  resolveStoreModules(require('../store/idle/index.ts'), 'idle/index.ts')
  resolveStoreModules(require('../store/cms/index.ts'), 'cms/index.ts')
  resolveStoreModules(require('../store/categories/index.ts'), 'categories/index.ts')
  resolveStoreModules(require('../store/cart/index.ts'), 'cart/index.ts')
  resolveStoreModules(require('../store/account/index.ts'), 'account/index.ts')
  resolveStoreModules(require('../store/account/actions.ts'), 'account/actions.ts')
  resolveStoreModules(require('../store/account/getters.ts'), 'account/getters.ts')
  resolveStoreModules(require('../store/account/mutations.ts'), 'account/mutations.ts')
  resolveStoreModules(require('../store/account/types.ts'), 'account/types.ts')
  resolveStoreModules(require('../store/cart/actions.ts'), 'cart/actions.ts')
  resolveStoreModules(require('../store/cart/getters.ts'), 'cart/getters.ts')
  resolveStoreModules(require('../store/cart/mutations.ts'), 'cart/mutations.ts')
  resolveStoreModules(require('../store/cart/types.ts'), 'cart/types.ts')
  resolveStoreModules(require('../store/cart/utils.ts'), 'cart/utils.ts')
  resolveStoreModules(require('../store/categories/actions.ts'), 'categories/actions.ts')
  resolveStoreModules(require('../store/categories/getters.ts'), 'categories/getters.ts')
  resolveStoreModules(require('../store/categories/mutations.ts'), 'categories/mutations.ts')
  resolveStoreModules(require('../store/categories/types.ts'), 'categories/types.ts')
  resolveStoreModules(require('../store/cms/actions.ts'), 'cms/actions.ts')
  resolveStoreModules(require('../store/cms/getters.ts'), 'cms/getters.ts')
  resolveStoreModules(require('../store/cms/mutations.ts'), 'cms/mutations.ts')
  resolveStoreModules(require('../store/cms/types.ts'), 'cms/types.ts')
  resolveStoreModules(require('../store/layout/actions.ts'), 'layout/actions.ts')
  resolveStoreModules(require('../store/layout/getters.ts'), 'layout/getters.ts')
  resolveStoreModules(require('../store/layout/mutations.ts'), 'layout/mutations.ts')
  resolveStoreModules(require('../store/layout/types.ts'), 'layout/types.ts')
  resolveStoreModules(require('../store/offers/actions.ts'), 'offers/actions.ts')
  resolveStoreModules(require('../store/offers/mutations.ts'), 'offers/mutations.ts')
  resolveStoreModules(require('../store/payments/actions.ts'), 'payments/actions.ts')
  resolveStoreModules(require('../store/payments/getters.ts'), 'payments/getters.ts')
  resolveStoreModules(require('../store/payments/mutations.ts'), 'payments/mutations.ts')
  resolveStoreModules(require('../store/products/actions.ts'), 'products/actions.ts')
  resolveStoreModules(require('../store/products/getters.ts'), 'products/getters.ts')
  resolveStoreModules(require('../store/products/mutations.ts'), 'products/mutations.ts')
  resolveStoreModules(require('../store/products/types.ts'), 'products/types.ts')
  resolveStoreModules(require('../store/rma/actions.ts'), 'rma/actions.ts')
  resolveStoreModules(require('../store/rma/getters.ts'), 'rma/getters.ts')
  resolveStoreModules(require('../store/rma/mutations.ts'), 'rma/mutations.ts')
  resolveStoreModules(require('../store/rma/types.ts'), 'rma/types.ts')
  resolveStoreModules(require('../store/stocks/actions.ts'), 'stocks/actions.ts')
  resolveStoreModules(require('../store/stocks/getters.ts'), 'stocks/getters.ts')
  resolveStoreModules(require('../store/stocks/mutations.ts'), 'stocks/mutations.ts')
  resolveStoreModules(require('../store/stocks/types.ts'), 'stocks/types.ts')
  resolveStoreModules(require('../store/wishlist/actions.ts'), 'wishlist/actions.ts')
  resolveStoreModules(require('../store/wishlist/getters.ts'), 'wishlist/getters.ts')
  resolveStoreModules(require('../store/wishlist/mutations.ts'), 'wishlist/mutations.ts')
  resolveStoreModules(require('../store/wishlist/types.ts'), 'wishlist/types.ts')
  resolveStoreModules(require('../store/wishlist/utils.ts'), 'wishlist/utils.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}

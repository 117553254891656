import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'
import { WishlistState } from './types'
import { RootState } from '~/store'

export const state = (): WishlistState => ({
  wishlists: {},
  basicWishlists: {},
  selectedWishlist: null,
})

const namespaced: boolean = true

const wishlist: Module<WishlistState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
export default wishlist

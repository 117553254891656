import Vue from 'vue'
import { LayoutState } from './types'

export const mt = {
  TOGGLE_BACKDROP: 'TOGGLE_BACKDROP',
  TOGGLE_MOBILE_MENU: 'TOGGLE_MOBILE_MENU',
  TOGGLE_ACCOUNT_MENU: 'TOGGLE_ACCOUNT_MENU',
  TOGGLE_FILTERS_MENU: 'TOGGLE_FILTERS_MENU',
  TOGGLE_CATEGORIES_MENU: 'TOGGLE_CATEGORIES_MENU',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_MENU_ACCOUNT_PAGE: 'SET_MENU_ACCOUNT_PAGE',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  TOGGLE_BREADCRUMBS: 'TOGGLE_BREADCRUMBS',
  SET_LAYOUT_SCROLL: 'SET_LAYOUT_SCROLL',
  SET_PRICE_GROSS: 'SET_PRICE_GROSS',
  SET_SHOULD_SET_GROSS_AS_DEFAULT_VALUE: 'SET_SHOULD_SET_GROSS_AS_DEFAULT_VALUE',
}

const CURRENCY_ISO: any = {
  PLN: 'pl-PL',
  HUF: 'hu-HU',
  USD: 'en-US',
}

export default {
  [mt.TOGGLE_BACKDROP](state: LayoutState, isVisible: boolean) {
    state.backdrop = isVisible
  },
  [mt.TOGGLE_MOBILE_MENU](state: LayoutState, payload: boolean) {
    state.menuMobileShow = payload
  },
  [mt.TOGGLE_ACCOUNT_MENU](state: LayoutState, payload: boolean) {
    state.menuAccountShow = payload
  },
  [mt.TOGGLE_FILTERS_MENU](state: LayoutState, payload: boolean) {
    state.menuFiltersShow = payload
  },
  [mt.TOGGLE_CATEGORIES_MENU](state: LayoutState, payload: boolean) {
    state.menuCategoriesShow = payload
  },
  [mt.SET_CURRENCY](state: LayoutState, code: string) {
    state.currency = {
      code,
      iso: CURRENCY_ISO[code],
      symbol: '', // FIXME: Symbol is not used anymore so we can remove it
    }
  },
  [mt.SET_BREADCRUMBS](state: LayoutState, breadcrumbs: Array<any>) {
    state.breadcrumbs = breadcrumbs
  },
  [mt.TOGGLE_BREADCRUMBS](state: LayoutState, isVisible: boolean) {
    state.showBreadcrumbs = isVisible
  },
  [mt.SET_LAYOUT_SCROLL](state: LayoutState, payload: boolean) {
    Vue.set(state, 'layoutScroll', payload)
  },
  [mt.SET_PRICE_GROSS](state: LayoutState, isPriceGross: boolean) {
    state.priceGross = isPriceGross
  },
  [mt.SET_SHOULD_SET_GROSS_AS_DEFAULT_VALUE](state: LayoutState, shouldSetGrossAsDefaultValue: boolean) {
    state.shouldSetGrossAsDefaultValue = shouldSetGrossAsDefaultValue
  },
}

export default (_, inject) => {
  const customLog = (...args) => {
    if (process.env.NODE_ENV === 'production') {
      console.log(JSON.stringify(args))
    } else {
      console.log(...args)
    }
  }

  const customError = (...args) => {
    if (process.env.NODE_ENV === 'production') {
      console.error(JSON.stringify(args))
    } else {
      console.error(...args)
    }
  }

  const customWarn = (...args) => {
    if (process.env.NODE_ENV === 'production') {
      console.warn(JSON.stringify(args))
    } else {
      console.warn(...args)
    }
  }

  const logger = {
    log: customLog,
    error: customError,
    warn: customWarn,
  }

  inject('logger', logger)
}

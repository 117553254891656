import { RMA } from '@one/types'
import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'
import { RMAState } from './types'
import App = RMA.App
import { RootState } from '~/store'
export const state = (): RMAState => ({
  createComplaint: {} as App.Request.ComplaintFormDto,
  complaintReasons: {},
  complaintsList: {
    offset: 0,
    rows: 30,
    page: 1,
    elementsAmount: 0,
    loadedPages: {},
  } as RMA.App.Response.PaginationComplaint,
  complaints: {},
})

const namespaced: boolean = true

const rma: Module<RMAState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}

export default rma

var render = function render(_c,_vm){return _c('span',{staticClass:"one-fake-product-list-view"},_vm._l((_vm.props.fakes),function(n,index){return _c('div',{key:index,staticClass:"ph-item mt-3",style:({ 'min-height': '315px' })},[_vm._m(0,true),_vm._v(" "),_vm._m(1,true),_vm._v(" "),_vm._m(2,true),_vm._v(" "),_vm._m(3,true),_vm._v(" "),_vm._m(4,true),_vm._v(" "),_vm._m(5,true)])}),0)
}
var staticRenderFns = [function (_c,_vm){return _c('div',{staticClass:"top-header ph-col-12"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-11 big"})])])
},function (_c,_vm){return _c('div',{staticClass:"header-divider ph-col-12"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-12 empty"})])])
},function (_c,_vm){return _c('div',{staticClass:"picture ph-col-4"},[_c('div',{staticClass:"ph-picture"})])
},function (_c,_vm){return _c('div',{staticClass:"details ph-col-4"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-2 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-8"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-12"}),_vm._v(" "),_c('div',{staticClass:"ph-col-10"})])])])])
},function (_c,_vm){return _c('div',{staticClass:"ph-col-4"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-10"}),_vm._v(" "),_c('div',{staticClass:"ph-col-2 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-10"})])])
},function (_c,_vm){return _c('div',{staticClass:"ph-col-12"},[_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-12 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-12 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-12 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-8 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-3"}),_vm._v(" "),_c('div',{staticClass:"ph-col-1 empty"})]),_vm._v(" "),_c('div',{staticClass:"ph-row"},[_c('div',{staticClass:"ph-col-3"}),_vm._v(" "),_c('div',{staticClass:"ph-col-5 empty"}),_vm._v(" "),_c('div',{staticClass:"ph-col-4"})])])
}]

export { render, staticRenderFns }
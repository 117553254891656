import { Products, CombinedFilters } from '@one/types'
import { isPast } from 'date-fns'
import { ProductsState } from '~/store/products/types'

export enum ProductImageSize {
  BIG = 'BIG',
  SMALL_100 = 'SMALL_100',
  SMALL_200 = 'SMALL_200',
  SMALL_300 = 'SMALL_300',
  SMALL_400 = 'SMALL_400',
  TINY_32 = 'TINY_32',
}

export default {
  getProduct: (state: ProductsState) => (id: string): Products.Product | null =>
    state.products[id],
  getProductImage: (state: ProductsState) => (
    id: string,
    size: ProductImageSize,
    num: number,
  ): string => {
    const defaultPhoto =
      state.products[id].photos[0] && state.products[id].photos[0].url
    const photo =
      state.products[id].photosWithVariants[num] &&
      state.products[id].photosWithVariants[num].variantMap &&
      state.products[id].photosWithVariants[num].variantMap[size]
    return photo || defaultPhoto
  },
  getProductImages: (state: ProductsState) => (
    id: string,
    size: ProductImageSize,
  ): Array<string> => {
    const defaultPhoto =
      state.products[id].photos[0] && state.products[id].photos[0].url
    return (
      (state.products &&
        state.products[id].photosWithVariants.map(
          (variant: Products.PhotoWithVariants) =>
            variant.variantMap && variant.variantMap[size],
        )) || [defaultPhoto]
    )
  },
  getCategorySelected: (state: ProductsState) => state.categorySelected,
  getProductBySlug: (state: ProductsState) => (slug: string): Products.Product | null =>
    Object.values(state.products).find(
      (p: Products.Product) => p.slug === slug,
    ) || null,
  getRelatedProducts: (state: ProductsState) => (
    id: string,
    type: string,
  ): Array<Products.ProductIdAndQuantityDto> | null => {
    const product = state.products[id]
    if (product) {
      const relatedProducts = product.relatedProducts.find(
        (x: Products.RelatedProductsDto) => x.relationType === type,
      )
      return (
        (relatedProducts &&
          relatedProducts.products.map(
            (x: Products.ProductIdAndQuantityDto) => x,
          )) ||
        null
      )
    }
    return null
  },
  getProductOrderUnit: (state: ProductsState) => (id: string): string =>
    state.products[id] &&
    state.products[id].measurementUnits &&
    state.products[id].measurementUnits.orderUnitDescription,
  getProductCertificates: (state: ProductsState) => (
    id: string,
  ): Array<Products.ExternalUrl> | null =>
    state.products[id] && state.products[id].certificates,
  getProductDataSheets: (state: ProductsState) => (
    id: string,
  ): Array<Products.ExternalUrl> | null =>
    state.products[id] && state.products[id].productDataSheets,

  getSearchedText: (state: ProductsState): string => state.searchText,
  getPerPage: (state: ProductsState): number => state.productsList.pageSize,
  getCurrentPage: (state: ProductsState): number => state.productsList.pageNumber,
  getTotalProducts: (state: ProductsState): number => state.productsList.totalItems,
  getTotalPages: (state: ProductsState): number => state.productsList.totalPages,
  getSortCriteria: (state: ProductsState): string | undefined =>
    state.productsList.sortCriteria,
  getSortingOptions: (state: ProductsState): Array<Products.SortingOption> =>
    state.productsList.sortingOptions,
  getCurrentProducts: (state: ProductsState): Array<string> | null =>
    state.productsList.loadedPages[state.productsList.pageNumber]
      ? state.productsList.loadedPages[state.productsList.pageNumber].products
      : null,
  getProducts: (state: ProductsState): Array<string> => Object.keys(state.products),
  getRecommendedProducts: (state: ProductsState) =>
    Object.keys(state.products).slice(1, 5),
  getProductsFromCategory: (state: ProductsState) => (
    categoryId: string,
  ): Array<Products.Product> =>
    Object.values(state.products).filter(
      product => product.canonicalCategory === categoryId,
    ),
  getAttributesValue: (state: ProductsState) => (
    id: string,
  ): Array<Products.MultivalueAttribute> | null =>
    state.products[id]
      ? state.products[id].multivalueAttributes.map(
          (element: Products.MultivalueAttribute) => element,
        )
      : null,
  getFiltersIds: (state: ProductsState): Array<string> =>
    state.productsList.attributes.allIds,
  getFilter: (state: ProductsState) => (id: string): CombinedFilters | null =>
    state.productsList.attributes.byId[id]
      ? state.productsList.attributes.byId[id]
      : null,
  getCategoryCount: (state: ProductsState) => (id: string) =>
    state.productsList.categoriesCount[id]
      ? state.productsList.categoriesCount[id]
      : 0,
  getFilterAttributeValue: (state: ProductsState) => (
    attrId: string,
    valueCode: string,
  ) => state.productsList.attributes.byId[attrId].items[valueCode],
  getSelectedAttributes: (state: ProductsState) => state.productsList.selectedFilters,
  getSelectedAttributesCount: (state: ProductsState) => {
    const { selectedFilters } = state.productsList
    let selectedAttributesCount: number = 0
    for (const item of Object.values(selectedFilters)) {
      selectedAttributesCount += item.length
    }
    return selectedAttributesCount
  },
  isFilterAttributeSelected: (state: ProductsState) => (
    attrId: string,
    itemValue: string,
  ) =>
    state.productsList.attributes.byId[attrId] &&
    state.productsList.attributes.byId[attrId].items[itemValue]
      ? state.productsList.attributes.byId[attrId].items[itemValue].selected
      : false,
  isPriceExpired: (state: ProductsState) => (productId: string) => {
    const productPrice = state.prices[productId]
    return !productPrice || isPast(productPrice.timestamp)
  },
  productPrice: (state: ProductsState) => (productId: string) =>
    state.prices[productId],
  getProductPrice: (state: ProductsState) => (
    productId: string,
    priceGross: boolean = false,
  ) => {
    const prices = state.prices[productId]
    if (!prices) { return null }
    if (priceGross) {
      return {
        price: prices.priceGross!,
        catalogPrice: prices.catalogPriceGross!,
        discount: prices.discount!,
        tax: prices.tax,
        priceHistory: prices.priceHistory,
        timestamp: prices.timestamp,
      }
    }
    return {
      price: prices.priceNet!,
      catalogPrice: prices.catalogPriceNet!,
      discount: prices.discount!,
      tax: prices.tax,
      priceHistory: prices.priceHistory,
      timestamp: prices.timestamp,
    }
  },
  getCurrentFiltersQuery: (state: ProductsState) => {
    const filters: Array<string> = []
    state.productsList.attributes.allIds.forEach((attributeId: string) => {
      const attribute = state.productsList.attributes.byId[attributeId]
      switch (attribute.type) {
        case 'MULTICHECKBOX':
          Object.keys(attribute.items).forEach((attributeValueId: string) => {
            const filterValue = attribute.items[attributeValueId]
            if (filterValue.selected) {
              filters.push(`${attributeId}:${filterValue.valueCode}`)
            }
          })
          break
        // case FilteringAttributeType.INPUT:
        //   filters.push(`${filterCode}:${filterValues.value}`)
        //   break
      }
    })
    return filters
  },
  getProductLabels: (state: ProductsState) => (labelText: string, id: string) =>
    state.products[id].labels
      ? state.products[id].labels.includes(labelText)
      : false,
  getPriceFilter: (state: ProductsState) => (type: string) =>
    state.productsList.priceFilters && state.productsList.priceFilters[type],
  getPrice: (state: ProductsState): string | undefined => state.productsList.price,
  getDefaultSortForCatalogBrowse: (state: ProductsState): string =>
    state.productsList.sortOptionForCatalogBrowser,
  getDefaultSortForSearch: (state: ProductsState): string =>
    state.productsList.sortOptionForSearch,
  getDisplayMode: (state: ProductsState): string => state.productsList.displayMode,
}

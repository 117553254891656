import { BroadcastChannel } from 'broadcast-channel'
import hash from 'object-hash'
import Vue from 'vue'
import { Store } from 'vuex'

/*
  Messages structure on BroadcastChannel:
  {
    action: String -> <your_unique_action_name>, required
    content: any -> Any content that is hashable by object-hash, required
  }

  Action will not be send when You not define default behavior on receiving it in <handlers>.

*/

interface MessageHandlers {
  [key: string]: Function,
}

interface OneChannelOptions {
  channelName: string,
  useInWebWorkers: boolean
}
interface OneChannelConfig {
  context: any,
  handlers?: MessageHandlers,
  options?: Partial<OneChannelOptions>,
}

interface ChannelEvent {
  action: string,
  content: any
}

export class OneChannel {
  options: OneChannelOptions = {
    channelName: 'one-channel',
    useInWebWorkers: false,
  }

  messagesFromChannel: Array<string> = []
  readonly store: Store<any>;
  context: any = null
  channel: BroadcastChannel | null = null

  handlers: MessageHandlers = {}
  constructor(config: OneChannelConfig) {
    if (!config.context) { throw new Error('Context is required') }
    this.context = config.context
    this.store = this.context.store
    this.options = { ...this.options, ...config.options }
    this.handlers = {
      ...{
        'stocks/SET_DEFAULT_WAREHOUSE': this.defaultMutationHandler,
        'cart/RENAME_CART': this.defaultMutationHandler,
        'cart/SET_CURRENT_CART': this.defaultMutationHandler,
        'cart/SET_PAYMENT_METHOD': this.defaultMutationHandler,
        'cart/SET_DELIVERY_METHOD': this.defaultMutationHandler,
        'wishlist/RENAME_WISHLIST': this.defaultMutationHandler,
        'wishlist/SET_WISHLIST': this.defaultMutationHandler,
        'wishlist/SET_CURRENT_WISHLIST': this.defaultMutationHandler,
        'cart/SET_CART': this.defaultMutationHandler,
        'auth/LOGOUT': this.onLogout,
        'auth/AUTHORIZE': this.onAuthorize,
        LOGIN: this.onLogin,
      },
      ...config.handlers,
    }
    this.initializeChannel()
    this.store.subscribe(this.onStoreMutation)
  }

  initializeChannel = () => {
    this.channel = new BroadcastChannel(this.options.channelName, {
      webWorkerSupport: this.options.useInWebWorkers,
    })
    this.channel.onmessage = this.onChannelMessage
  }

  onStoreMutation = (mutation: any) => {
    if (!this.channel) { return }
    if (this.isRegisteredMessage(mutation.type)) {
      const isFromChannelIdx = this.messagesFromChannel.indexOf(hash(mutation))
      if (isFromChannelIdx < 0) {
        this.channel.postMessage({
          action: mutation.type,
          content: mutation,
        })
      } else {
        this.messagesFromChannel.splice(isFromChannelIdx, 1)
      }
    }
  }

  isRegisteredMessage = (name: string) => Object.keys(this.handlers).includes(name)

  onChannelMessage = (event: ChannelEvent) => {
    this.messagesFromChannel.push(hash(event.content))
    this.handlers[event.action](event)
  }

  defaultMutationHandler = (event: ChannelEvent) => {
    this.store.commit(event.content.type, event.content.payload)
  }

  onLogout = () => {
    this.context.app.$auth.logout()
    this.context.app.router.push('login')
  }

  onLogin = async () => {
    await this.store.dispatch('fetchRequiredData')
    await this.store.dispatch('fetchAuthenticatedUserData')
    this.context.app.router.push('/', () => {
      this.store.dispatch('fetchHeavyData')
    })
  }

  onAuthorize = async (event: ChannelEvent) => {
    await this.context.app.$auth.authorize(event.content.payload)
  }

  send = (message: ChannelEvent) => {
    if (!this.channel) { throw new Error('Channel not connected') }
    if (this.isRegisteredMessage(message.action)) {
      if (!message.content) { throw new Error('Content is required!') }
      return this.channel.postMessage(message)
    }
    return Promise.reject(new Error('Unknown message'))
  }
}

export default (context: any, inject: any) => {
  inject('channel', Vue.observable(new OneChannel({
    context,
  })))
}

import { CMS } from '@one/types'
import { Module } from 'vuex'
import { mutations } from './mutations'
import getters from './getters'
import { actions } from './actions'
import StaticPageDto = CMS.Responses.StaticPageDto
import { ArticlesState, CmsState } from '~/store/cms/types'
import { RootState } from '~/store'

const state = (): CmsState => ({
  configuration: {} as CMS.Responses.MainConfigurationQuery,
  pages: {},
  staticPages: {} as Record<string, StaticPageDto>,
  articles: {} as ArticlesState,
  grids: {} as Record<CMS.Grid.GridSlot, CMS.Grid.GridElement>,
  availableGridSlots: [],
})

export const namespaced: boolean = true

const cms: Module<CmsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
export default cms

import { MutationTree } from 'vuex'
import Vue from 'vue'
import { arrayToObject } from '@one/core'
import { Wishlist } from '@one/types/dist/orderpath/app'
import { arrangeProductsInWishlist, reorderProducts } from './utils'
import { WishlistState } from './types'

export const mt = {
  SET_WISHLIST: 'SET_WISHLIST',
  SET_BASIC_WISHLISTS: 'SET_BASIC_WISHLISTS',
  SET_CURRENT_WISHLIST: 'SET_CURRENT_WISHLIST',
  ADD_NEW_WISHLIST: 'ADD_NEW_WISHLIST',
  REMOVE_ITEM_FROM_WISHLIST: 'REMOVE_ITEM_FROM_WISHLIST',
  REMOVE_WISHLIST: 'REMOVE_WISHLIST',
  CHANGE_ITEMS_ORDERING: 'CHANGE_ITEMS_ORDERING',
  RENAME_WISHLIST: 'RENAME_WISHLIST',
  CLEAR_STORE: 'CLEAR_STORE',
}

export const mutations: MutationTree<WishlistState> = {
  [mt.ADD_NEW_WISHLIST](
    state: WishlistState,
    newWishlist: Wishlist.Responses.WishlistId,
  ) {
    Vue.set(state.wishlists, newWishlist.id, newWishlist)
  },
  [mt.REMOVE_ITEM_FROM_WISHLIST](
    state: WishlistState,
    { wishlistId, productId }: { wishlistId: string; productId: string },
  ) {
    Vue.delete(state.wishlists[wishlistId].products, productId)
  },
  [mt.CHANGE_ITEMS_ORDERING](
    state: WishlistState,
    { wishlistId, ordering },
  ) {
    Vue.set(
      state.wishlists[wishlistId],
      'products',
      reorderProducts(state.wishlists[wishlistId].products, ordering),
    )
  },
  [mt.SET_CURRENT_WISHLIST](
    state: WishlistState,
    wishlistId: string,
  ) {
    state.selectedWishlist = wishlistId
  },
  [mt.SET_BASIC_WISHLISTS](
    state: WishlistState,
    wishlists: Array<Wishlist.Responses.BasicWishlist>,
  ) {
    state.basicWishlists = {}
    Vue.set(state, 'basicWishlists', arrayToObject(wishlists, 'id'))
  },
  [mt.REMOVE_WISHLIST](
    state: WishlistState,
    wishlistId: string,
  ) {
    Vue.delete(state.wishlists, wishlistId)
  },
  [mt.SET_WISHLIST](
    state: WishlistState,
    wishlist: Wishlist.Responses.Wishlist,
  ) {
    const mappedProducts = arrangeProductsInWishlist(wishlist)
    const mappedWishlist = {
      ...wishlist,
      products: mappedProducts,
    }
    Vue.set(state.wishlists, wishlist.id, mappedWishlist)
  },
  [mt.RENAME_WISHLIST](
    state: WishlistState,
    { wishlistId, name }: { wishlistId: string; name: string },
  ) {
    if (state.wishlists[wishlistId]) {
      Vue.set(state.wishlists[wishlistId], 'name', name)
    }
    if (state.basicWishlists[wishlistId]) {
      Vue.set(state.basicWishlists[wishlistId], 'name', name)
    }
  },
  [mt.CLEAR_STORE](state: WishlistState) {
    /*
        wishlists: {} as WishlistsMapping,
        basicWishlists: {} as BasicWishlistsMapping,
        selectedWishlist: null
     */
    Vue.set(state, 'wishlists', {})
    Vue.set(state, 'basicWishlists', {})
    state.selectedWishlist = null
  },
}

export default mutations

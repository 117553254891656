import { AxiosResponse } from 'axios'
import { ActionContext, ActionTree } from 'vuex'
import { Stocks } from '@one/types'
import { RootState } from '../index'
import { mt } from './mutations'
import { StocksState } from './types'
import Warehouse = Stocks.Warehouse
import ProductsAndWarehouses = Stocks.Requests.ProductsAndWarehouses
import StockForProduct = Stocks.Requests.StockForProduct
import ProductsStocks = Stocks.Responses.ProductsStocks

import ProductStocks = Stocks.Responses.ProductStocks

export const actions: ActionTree<StocksState, RootState> = {
  fetchWarehouses({ commit }: ActionContext<StocksState, RootState>) {
    const app: any = this.app
    app.wait.start('stocks-fetching')
    return this.$api.stock.app
      .getWarehouses()
      .then((success: AxiosResponse<Array<Warehouse>>) => {
        const warehouses: Array<Warehouse> = success.data
        commit(mt.SET_WAREHOUSES, warehouses)
        return Promise.resolve(warehouses)
      })
      .catch((error: any) => Promise.reject(error))
      .finally(() => app.wait.end('stocks-fetching'))
  },
  fetchWarehouse(
    { commit }: ActionContext<StocksState, RootState>,
    warehouseId: string,
  ) {
    return this.$api.stock.app
      .getWarehouse(warehouseId)
      .then((success: AxiosResponse<Warehouse>) => {
        const warehouse: Warehouse = success.data
        commit(mt.SET_WAREHOUSE, warehouse)
        return Promise.resolve(warehouse)
      })
      .catch((error: any) => Promise.reject(error))
  },
  fetchStocksForProductsAndWarehouses(
    { commit }: ActionContext<StocksState, RootState>,
    { skus, warehouses }: ProductsAndWarehouses,
  ) {
    if (!warehouses.length || !skus.length) {
      return Promise.resolve()
    }
    return this.$api.stock.app
      .getStocksForProductsAndWarehouses(skus, warehouses)
      .then((success: AxiosResponse<ProductsStocks>) => {
        const response: ProductsStocks = success.data
        commit(mt.SET_PRODUCTS_STOCKS, response)
        return Promise.resolve(response)
      })
      .catch((error: any) => Promise.reject(error))
  },
  setDefaultWarehouseIfNotSelected(
    { commit, state }: ActionContext<StocksState, RootState>,
    warehouseId: string,
  ) {
    if (!state.defaultWarehouse && warehouseId && warehouseId.trim() !== '') {
      commit(mt.SET_DEFAULT_WAREHOUSE, warehouseId)
      commit(mt.SET_DEFAULT_USER_WAREHOUSE, warehouseId)
    }
    return Promise.resolve()
  },
  setWarehouse({ commit }: ActionContext<StocksState, RootState>, warehouseId: string) {
    if (warehouseId == null) {
      throw new Error('Warehouse ID cannot be null')
    }
    return commit(mt.SET_DEFAULT_WAREHOUSE, warehouseId)
  },
  fetchStockForProduct(
    { commit }: ActionContext<StocksState, RootState>,
    { productId, warehouses }: StockForProduct,
  ) {
    const { app }: any = this
    app.wait.start(`product-${productId}-stocks-loading`)
    return (
      this.$api.stock.app
        .getStocksForProduct(productId, warehouses)
        // TODO: Dodać poprawne typowanie
        .then((success: AxiosResponse) => {
          const stock: ProductStocks = success.data
          commit(mt.SET_PRODUCT_STOCK, { id: productId, stock })
          return Promise.resolve(stock)
        })
        .catch((error: any) => Promise.reject(error))
        .finally(() => app.wait.end(`product-${productId}-stocks-loading`))
    )
  },
}

export default actions

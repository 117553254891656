const middleware = {}

middleware['breadcrumbs'] = require('../middleware/breadcrumbs.js')
middleware['breadcrumbs'] = middleware['breadcrumbs'].default || middleware['breadcrumbs']

middleware['changePasswordRequired'] = require('../middleware/changePasswordRequired.js')
middleware['changePasswordRequired'] = middleware['changePasswordRequired'].default || middleware['changePasswordRequired']

middleware['isAuthenticated'] = require('../middleware/isAuthenticated.js')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['isAuthenticatedOrPlatformOpened'] = require('../middleware/isAuthenticatedOrPlatformOpened.js')
middleware['isAuthenticatedOrPlatformOpened'] = middleware['isAuthenticatedOrPlatformOpened'].default || middleware['isAuthenticatedOrPlatformOpened']

middleware['isAuthenticatedOrPurchaseEnabled'] = require('../middleware/isAuthenticatedOrPurchaseEnabled.js')
middleware['isAuthenticatedOrPurchaseEnabled'] = middleware['isAuthenticatedOrPurchaseEnabled'].default || middleware['isAuthenticatedOrPurchaseEnabled']

middleware['isB2B'] = require('../middleware/isB2B.js')
middleware['isB2B'] = middleware['isB2B'].default || middleware['isB2B']

middleware['isNotAuthenticated'] = require('../middleware/isNotAuthenticated.js')
middleware['isNotAuthenticated'] = middleware['isNotAuthenticated'].default || middleware['isNotAuthenticated']

middleware['logoutBeforeEnter'] = require('../middleware/logoutBeforeEnter.js')
middleware['logoutBeforeEnter'] = middleware['logoutBeforeEnter'].default || middleware['logoutBeforeEnter']

middleware['notChangePasswordRequired'] = require('../middleware/notChangePasswordRequired.js')
middleware['notChangePasswordRequired'] = middleware['notChangePasswordRequired'].default || middleware['notChangePasswordRequired']

middleware['permission'] = require('../middleware/permission.js')
middleware['permission'] = middleware['permission'].default || middleware['permission']

middleware['routeExposer'] = require('../middleware/routeExposer.js')
middleware['routeExposer'] = middleware['routeExposer'].default || middleware['routeExposer']

export default middleware

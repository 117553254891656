import { ActionContext, ActionTree } from 'vuex'
import {
  AttributeFilter,
  GenericFilter,
  MappedAttributeMultiCheckboxFilter,
  Products,
  Suggestions,
  TransformedFiltersMapping,
  FilterItem,
  MappedGenericMultiCheckboxFilter,
  CombinedFilters,
} from '@one/types'
import { AxiosResponse } from 'axios'
import { isNotNull, converters } from '@one/core'
import { RootState } from '../index'
import { mt } from './mutations'
import { ProductsState } from './types'
import ProductsPagination = Products.Requests.ProductsPagination
import FiltersPagination = Products.Requests.FiltersPagination
import ProductsPrices = Products.Requests.ProductsPrices
import ProductPrice = Products.ProductPrice
import Suggestion = Suggestions.Responses.Suggestion
import SearchFilters = Products.Responses.SearchFilters
import SearchResultPager = Products.Responses.SearchResultPager
import TransformedFiltersResponse = Products.Responses.TransformedFiltersResponse

const selectedFilters = (
  filters: Array<GenericFilter | AttributeFilter>,
): Record<string, Array<string>> =>
  filters
    .map(filter => ({
      [filter.nameCode]: filter.items
        .map(item => (item.selected ? item.valueCode : null))
        .filter(isNotNull),
    }))
    .filter((x: Record<string, Array<string>>) => Object.values(x)[0].length)
    .reduce(
      (x: Record<string, Array<string>>, y: Record<string, Array<string>>) => ({
        ...x,
        ...y,
      }),
      {},
    )

type CombinedBaseFilters = GenericFilter | AttributeFilter
const transformFilters = (
  store: any,
  filters: Array<CombinedBaseFilters>,
): TransformedFiltersMapping => {
  const mapFilterItems = (
    filter: GenericFilter | AttributeFilter,
  ): Record<
    string,
    MappedGenericMultiCheckboxFilter | MappedAttributeMultiCheckboxFilter
  > => {
    const mapped:
      | MappedGenericMultiCheckboxFilter
      | MappedAttributeMultiCheckboxFilter = {
      ...filter,
      items: filter.items
        .filter((item: FilterItem) => shouldShowItem(filter, item))
        .map((item: FilterItem) => {
          item.label = labelFunction(filter.nameCode)(item)
          return {
            [item.valueCode]: item,
          }
        })
        .reduce(
          (x: Record<string, FilterItem>, y: Record<string, FilterItem>) => ({
            ...x,
            ...y,
          }),
          {},
        ),
    }
    return { [filter.nameCode]: mapped }
  }
  const labelFunction = (key: string): Function => {
    const options: any = {
      stocks: (item: FilterItem): string => {
        const label =
          store.getters['stocks/getWarehouse'](item.value) &&
          store.getters['stocks/getWarehouse'](item.value).label
        return label || item.value
      },
    }
    return options[key]
      ? options[key]
      : (item: FilterItem): string => item.value
  }
  const shouldShowItem = (
    filter: CombinedBaseFilters,
    item: FilterItem,
  ): boolean => {
    if (
      filter.nameCode === 'stocks' &&
      store.state.account.isWarehouseSelectionBlocked
    ) {
      const isDefaultWarehouse =
        item.valueCode === store.getters['stocks/getDefaultWarehouseId']
      const isCentralWarehouse =
        item.valueCode ===
        store.getters['stocks/getCentralWarehouse'].find((x: any) => x)
      return isDefaultWarehouse || isCentralWarehouse
    }
    return true
  }
  return {
    allIds: [...new Set(filters.map(filter => filter.nameCode))],
    byId: filters
      .map((filter): Record<string, CombinedFilters> => mapFilterItems(filter))
      .reduce((x, y) => ({ ...x, ...y }), {}),
  }
}

export const actions: ActionTree<ProductsState, RootState> = {
  fetchFilters(
    { commit, rootState, dispatch }: ActionContext<ProductsState, RootState>,
    query,
  ) {
    const { app }: any = this
    app.wait.start('filters-fetching')
    const filtersPagination: FiltersPagination = {
      q: query.q,
      category: rootState.categories.selectedCategoryId,
      filtersQuery: query.filtersQuery,
      price: query.price,
    }
    return dispatch('fetchFiltersForSearch', filtersPagination)
      .then((response: TransformedFiltersResponse) => {
        commit(mt.SET_FILTERS, response.transformedFilters)
        commit(mt.SET_SELECTED_FILTERS, response.selectedFilters)
        commit(mt.SET_PRICE_FILTER, response.filters.priceFilters)
        commit(mt.SET_CATEGORIES_COUNT, response.filters.categoriesCount)
        return response
      })
      .finally(() => app.wait.end('filters-fetching'))
  },
  fetchProducts(
    { commit, state, rootState, dispatch }: ActionContext<ProductsState, RootState>,
    query,
  ) {
    const { app }: any = this
    app.wait.start('products-fetching')

    const payload: ProductsPagination = {
      sortCriteria: state.productsList.sortCriteria,
      filtersQuery: query.filtersQuery || [],
      category: rootState.categories.selectedCategoryId,
      q: query.q || '',
      price: query.price,
      pageSize: state.productsList.pageSize,
      pageNumber: state.productsList.pageNumber,
    }
    if (!state.productsList.loadedPages[state.productsList.pageNumber]) {
      commit(mt.CREATE_LOADED_PAGE, state.productsList.pageNumber)
    }
    return dispatch('fetchProductsForSearch', payload)
      .then((response: SearchResultPager) => {
        const isCategorySelectedByBackend =
          // @ts-ignore
          response.categorySelected || response.isCategorySelected || !!rootState.categories.selectedCategoryId
        commit(mt.CATEGORY_SELECTED, isCategorySelectedByBackend)
        commit(mt.SET_TOTAL_ITEMS, response.totalItems)
        commit(mt.SET_SORTING_OPTIONS, response.sortingOptions)
        commit(mt.SET_ORDERING, response.sortCriteria)
        commit(mt.SET_TOTAL_PAGES, response.totalPages)
        const productsIds = response.items.map((p: Products.Product) => p.id)
        commit(mt.SET_PAGINATED_PRODUCTS, {
          page: response.pageNumber,
          productsIds,
        })
        return {
          ids: productsIds,
          categorySelected: isCategorySelectedByBackend,
        }
      })
      .finally(() => app.wait.end('products-fetching'))
  },
  fetchFiltersForSearch(
    _,
    query: FiltersPagination,
  ): Promise<TransformedFiltersResponse> {
    return this.$api.catalog.app
      .getFilters(query)
      .then((response: AxiosResponse<SearchFilters>) => {
        const { app }: any = this
        const transformedFilters = transformFilters(app.store, [
          ...response.data.genericFilters,
          ...response.data.attributes,
        ])
        const selected = selectedFilters([
          ...response.data.genericFilters,
          ...response.data.attributes,
        ])
        return Promise.resolve({
          filters: response.data,
          transformedFilters,
          selectedFilters: selected,
          filtersFromUrl: query.filtersQuery,
        })
      })
  },
  fetchProductsForSearch(
    { commit },
    query: ProductsPagination,
  ): Promise<SearchResultPager> {
    return this.$api.catalog.app
      .getProducts(query)
      .then(({ data }: AxiosResponse<SearchResultPager>) => {
        commit(mt.SET_PRODUCTS, data.items)
        return data
      })
  },
  fetchPricesForProducts(
    { state, commit, getters }: ActionContext<ProductsState, RootState>,
    productsIds: Array<string>,
  ) {
    const { app }: any = this
    app.wait.start('batch-prices-loading')
    const productsWithExpiredPrices = productsIds.filter((productId: string) =>
      getters.isPriceExpired(productId),
    )
    if (productsWithExpiredPrices.length) {
      productsWithExpiredPrices.forEach((id: any) =>
        app.wait.start(`product-${id}-price-loading`),
      )
      const pricesPayload: ProductsPrices = {
        products: productsWithExpiredPrices.map((productId: string) => ({
          productId,
          quantity: converters.toContentUnit(
            1,
            state.products[productId]?.measurementUnits?.packingQuantity,
          ),
        })),
      }

      return this.$api.pricing.app
        .fetchPricingForProducts(pricesPayload)
        .then((success: AxiosResponse<Array<ProductPrice>>) => {
          if (this.$utils.hasPricesEnabled) {
            commit(mt.SET_PRODUCTS_PRICES, success.data)
          }
          productsWithExpiredPrices.forEach((id: any) =>
            app.wait.end(`product-${id}-price-loading`),
          )
          app.wait.end('batch-prices-loading')
          return success.data
        })
    }
    app.wait.end('batch-prices-loading')
    return productsIds.map((productId: string) =>
      getters.productPrice(productId),
    )
  },
  fetchProductPrice(
    { commit, state }: ActionContext<ProductsState, RootState>,
    { productId, quantity = 1 }: { productId: string; quantity: number },
  ) {
    const { app }: any = this
    const payload: ProductsPrices = {
      products: [
        {
          productId,
          quantity: converters.toContentUnit(
            quantity,
            state.products[productId]?.measurementUnits?.packingQuantity,
          ),
        },
      ],
    }
    app.wait.start(`product-${productId}-price-loading`)

    return this.$api.pricing.app
      .fetchPricingForProducts(payload)
      .then((success: AxiosResponse<Array<ProductPrice>>) => {
        if (this.$utils.hasPricesEnabled) {
          commit(mt.SET_PRODUCT_PRICE, success.data[0])
        }
        app.wait.end(`product-${productId}-price-loading`)
        return success.data[0]
      })
  },
  clearSearchAndListing({ commit }: ActionContext<ProductsState, RootState>) {
    commit(mt.SEARCH_CHANGED, '')
    commit(mt.CLEAR_SELECTED_FILTERS)
    commit(mt.CLEAR_LOADED)
    commit(mt.SET_PAGE_NUMBER, 1)
    commit(mt.SET_PRICE, '')
    commit('categories/SELECT_CATEGORY', null, { root: true })
    commit('categories/SET_SELECTED_CATEGORIES_TREE', [], { root: true })
  },
  updateSearchString(
    { commit }: ActionContext<ProductsState, RootState>,
    query: string,
  ) {
    commit(mt.SEARCH_CHANGED, query)
    return Promise.resolve()
  },
  searchChanged(
    { commit }: ActionContext<ProductsState, RootState>,
    query: string,
  ) {
    commit(mt.SEARCH_CHANGED, query)
    return Promise.resolve()
  },
  changePage(
    { commit }: ActionContext<ProductsState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE_NUMBER, page)
    return Promise.resolve()
  },
  changeRows(
    { commit }: ActionContext<ProductsState, RootState>,
    pageSize: number,
  ) {
    commit(mt.SET_PAGE_SIZE, pageSize)
    return Promise.resolve()
  },
  sortCriteriaChanged(
    { commit }: ActionContext<ProductsState, RootState>,
    sortCriteria: string,
  ) {
    commit(mt.SET_SORT_CRITERIA, sortCriteria)
    return Promise.resolve()
  },
  async fetchProductWithPriceAndStock(
    { dispatch, rootState }: ActionContext<ProductsState, RootState>,
    id: string,
  ): Promise<Products.Product> {
    const product: Products.Product = await dispatch('fetchProduct', id)
    if (this.$utils.hasPricesEnabled) {
      await Promise.all([
        dispatch('fetchProductPrice', {
          productId: id,
          quantity: 1,
        }),
        dispatch(
          'stocks/fetchStockForProduct',
          {
            productId: id,
            warehouses: rootState.stocks.warehouses.allIds,
          },
          { root: true },
        ),
      ])
    } else {
      await dispatch(
        'stocks/fetchStockForProduct',
        {
          productId: id,
          warehouses: rootState.stocks.warehouses.allIds,
        },
        { root: true },
      )
    }
    return product
  },
  fetchProduct({ commit }: ActionContext<ProductsState, RootState>, id: string) {
    return this.$api.catalog.app
      .getProduct(id)
      .then((result: AxiosResponse<Products.Product>) => {
        commit(mt.SET_PRODUCT, result.data)
        commit(
          mt.SET_PRODUCT_PAGE_RELATED_PRODUCTS,
          result.data.relatedProducts,
        )
        return Promise.resolve(result.data)
      })
  },
  fetchProductBySlug(
    { commit }: ActionContext<ProductsState, RootState>,
    slug: string,
  ) {
    return this.$api.catalog.app
      .getProductBySlug(slug)
      .then((result: AxiosResponse<Products.Product>) => {
        commit(mt.SET_PRODUCT, result.data)
        commit(
          mt.SET_PRODUCT_PAGE_RELATED_PRODUCTS,
          result.data.relatedProducts,
        )
        return Promise.resolve(result.data)
      })
  },
  fetchSuggestions({ commit }, query: string) {
    const { app }: any = this
    app.wait.start('suggest-loading')

    return this.$api.catalog.app
      .getSuggestionForQuery(query)
      .then((success: AxiosResponse<Suggestion>) => {
        const suggestions: Suggestion = success.data
        commit(mt.SET_SUGGESTIONS, suggestions)
        setTimeout(() => {
          app.wait.end('suggest-loading')
        }, 200)
        return suggestions
      })
      .catch((e: any) => {
        setTimeout(() => {
          app.wait.end('suggest-loading')
        }, 200)
        throw e
      })
  },
  fetchDefaultSortOptions({ commit }) {
    return this.$api.catalog.app
      .getProductsDefaultSorting()
      .then(({ data }: AxiosResponse<Products.Responses.DefaultSortOptions>) => {
          commit(mt.SET_DEFAULT_SORTING, data)
      })
  },
  fetchProductsDisplayMode({ commit }) {
    return this.$api.catalog.app
      .getProductsDisplayMode()
      .then(
        ({
          data,
        }: AxiosResponse<Products.Responses.ProductsListingConfiguration>) => {
          commit(mt.SET_PRODUCTS_DISPLAY_MODE, data.displayMode)
        },
      )
  },
  fetchProductsById({ commit }, ids: Array<string>) {
    const { app }: any = this
    return this.$api.catalog.app
      .getProductsListById(true, ids)
      .then(({ data }: AxiosResponse<Array<Products.Product>>) => {
        commit(mt.SET_PRODUCTS, data)
        return Promise.resolve(data)
      })
      .catch(app.$logger.warn)
  },
  getProductsListByIdOrIndex({ commit }, ids: Array<string>) {
    const { app }: any = this
    return this.$api.catalog.app
      .getProductsListByIdOrIndex(ids)
      .then(({ data }: AxiosResponse<Array<Products.Product>>) => {
        commit(mt.SET_PRODUCTS, data)
        return Promise.resolve(data)
      })
      .catch(app.$logger.warn)
  },
  fetchManufacturers({ commit }, sku: string) {
    const { app }: any = this
    return this.$api.product.app
      .getManufacturers(sku)
      .then(
        ({
          data,
        }: AxiosResponse<Array<Products.CableDrum.Responses.Manufacturer>>) => {
          commit(mt.SET_MANUFACTURERS, { data, sku })
          return Promise.resolve(data)
        },
      )
      .catch(app.$logger.warn)
  },
  fetchStockByWarehouse(
    { commit },
    { sku, ids }: { sku: string; ids: Array<string> },
  ) {
    return this.$api.product.app
      .getStocksByWarehouse(sku, ids)
      .then(
        ({
          data,
        }: AxiosResponse<Products.CableDrum.Responses.ProductStocks>) => {
          commit(mt.SET_STOCK_BY_WAREHOUSE, { data, sku })
          return Promise.resolve(data)
        },
      )
  },
  saveCablesSummary(
    { commit },
    {
      sku,
      body,
    }: {
      sku: string
      body: Products.CableDrum.Request.CalculateCableProductsSummaryCommand
    },
  ) {
    return this.$api.product.app
      .addStocksToSummary(body)
      .then(
        ({
          data,
        }: AxiosResponse<
          Products.CableDrum.Responses.CableProductsSummary
        >) => {
          commit(mt.SET_CABLE_PRICES, { data, sku })
          return Promise.resolve(data)
        },
      )
  },
}
export default actions

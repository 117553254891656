import Vue from 'vue'
import FieldValidationMixin from '../mixins/FieldValidationMixin'

const mixinName = '__FieldValidationMixin__'
// @ts-ignore
if (!Vue[mixinName]) {
  // @ts-ignore
  Vue[mixinName] = true
  Vue.mixin(FieldValidationMixin)
}

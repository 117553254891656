/* @preserve
 *
 * StickyStack
 *
 * A vanilla javascript sticky-element stacker
 *
 * For licence details and usage see:
 * https://github.com/jrsouth/stickystack
 *
 */

import { debounce } from 'lodash-es'
export class StickyStack {
  mobileBreak = 768
  list = []
  number = 0
  stackTop = 0
  pageBottom = null
  stickyClass = 'stick-top'
  stuckClass = 'stick-top--stuck'
  noMobileClass = 'stick-top--no-mobile'
  topClass = 'stick-top--head'
  semiClass = 'stick-top--semi'

  constructor() {
    // Set up listeners to fire on load
    window.addEventListener('DOMContentLoaded', this.init.bind(this))
    window.addEventListener('load', this.init.bind(this))
    window.addEventListener('resize', this.init.bind(this))

    // Set up listeners to fire on scroll
    window.addEventListener('scroll', this.update.bind(this))
    window.addEventListener('touchmove', this.update.bind(this))

    // Add a hacky empty function to cause iOS to update the scroll offset value while scrolling
    // Still doesn't update during momentum scrolling
    // From https://remysharp.com/2012/05/24/issues-with-position-fixed-scrolling-on-ios/
    // TODO: Check if needed now that touchmove event has update() attached
    window.ontouchstart = function () {}

    // Trigger delayed extra recalculations, in case elements change
    // size as a result of the first init() call.
    window.addEventListener('DOMContentLoaded', () => {
      setTimeout(() => { this.update() }, 500)
    })
    window.addEventListener('load', () => {
      setTimeout(() => { this.update() }, 500)
    })
  }

  init = debounce(() => {
    this.stopObserving()
    // Clean up if init already run
    if (this.pageBottom) {
      this.pageBottom.parentNode.removeChild(this.pageBottom)
    } else {
      this.pageBottom = document.createElement('div')
      this.pageBottom.style.height = 0
      this.pageBottom.style.width = '100%'
      this.addClass(this.pageBottom, this.stickyClass)
    }

    document.body.appendChild(this.pageBottom)
    for (let i = 0; i < this.number; i++) {
      const placeholder = this.list[i].placeholder
      const currElement = this.list[i].element

      placeholder.parentNode.removeChild(placeholder)
      this.removeClass(currElement, this.stuckClass)

      currElement.style.position = null
      currElement.style.top = null
      currElement.style.left = null
      currElement.style.width = null
      currElement.style.marginTop = null
      currElement.style.marginBottom = null
    }
    // Reset this.number
    this.number = 0

    // Get elements with 'js-stickystack' class
    let elementList = document.getElementsByClassName(this.stickyClass)

    // If on a small screen, strip out elements marked
    // to _not_ stick on mobile (js-stickystack-nomobile)
    // TODO: Dynamically load/assign mobile breakpoint
    if (window.innerWidth <= this.mobileBreak) {
      const newElementList = []
      for (let i = 0; i < elementList.length; i++) {
        if (!this.hasClass(elementList[i], this.noMobileClass)) {
          newElementList.push(elementList[i])
        }
      }
      elementList = newElementList
    }

    this.number = elementList.length
    this.list = []
    this.stackTop = 0

    for (let i = 0; i < this.number; i++) {
      // Get element's computed style
      // Used both to set up the placeholder and to get needed list[] values
      const style = window.getComputedStyle(elementList[i])

      // Create placeholder block element, replicating all
      // layout-affecting properties of the sticky element
      const placeholderElement = document.createElement('div')
      placeholderElement.style.width = style.getPropertyValue('width')
      placeholderElement.style.height = style.getPropertyValue('height')
      placeholderElement.style.top = style.getPropertyValue('top')
      placeholderElement.style.right = style.getPropertyValue('right')
      placeholderElement.style.bottom = style.getPropertyValue('bottom')
      placeholderElement.style.left = style.getPropertyValue('left')
      placeholderElement.style.margin = style.getPropertyValue('margin')
      placeholderElement.style.padding = style.getPropertyValue('padding')
      placeholderElement.style.border = style.getPropertyValue('border')
      placeholderElement.style.float = style.getPropertyValue('float')
      placeholderElement.style.clear = style.getPropertyValue('clear')
      placeholderElement.style.position = style.getPropertyValue('position')

      // Hide the placeholder until it's needed
      // And have it invisible even when it IS needed
      placeholderElement.style.display = 'none'
      placeholderElement.style.visibility = 'hidden'

      // Add it to the DOM, immediately before the sticky element
      elementList[i].parentNode.insertBefore(placeholderElement, elementList[i])

      const coords = this.getCoords(elementList[i])

      this.list.push({
        element: elementList[i],
        top: coords.top,
        left: coords.left - parseFloat(style.getPropertyValue('margin-left')),
        width: parseFloat(style.getPropertyValue('width')),
        placeholder: placeholderElement,
        semistuck: this.hasClass(elementList[i], this.semiClass),
        zIndex: 100 - i,
      })
    }

    // Sort the list top down
    this.list.sort(this.sortByTop)

    // Set the stack top to (the top of) the highest-placed element
    // with the js-stickystack-top class. Note that this element
    // does not have to be sticky itself (otherwise we could just use the
    // existing vertically-sorted list).
    //
    // This allows other sticky elements (admin toolbars etc.) to
    // peacefully co-exist with this.
    //
    // Defaults to 0 (the top of the viewport) if no elements are found.

    this.stackTop = 0

    elementList = document.getElementsByClassName(this.topClass)
    for (let i = 0; i < elementList.length; i++) {
      const top = this.getCoords(elementList[i]).top
      if (i === 0) {
        this.stackTop = top
      } else {
        this.stackTop = Math.min(this.stackTop, top)
      }
    }

    // Fire the initial calculation
    this.update()
    this.startObserving()
  }, 100)

  update() {
    const stack = [[this.stackTop, 0, document.body.offsetWidth]]
    const pageOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    for (let i = 0; i < this.number; i++) {
      const curr = this.list[i]

      const currElement = curr.element
      const currPlaceholder = curr.placeholder
      const currLeft = curr.left
      const currTop = curr.top - pageOffset
      const currWidth = curr.width

      const overlapData = this.getOverlap([currTop, currLeft, currWidth], stack)

      if (overlapData.overlap > 0) {
        currPlaceholder.style.display = 'block'
        this.addClass(currElement, this.stuckClass)

        let scrollback = 0

        // Semi-sticky handling

        // Only bother if this element is "semistuck" and there are
        // further elements in the list.
        if (this.list[i].semistuck && (i + 1) < this.number) {
          const tempStack = [[overlapData.absolute + currElement.offsetHeight, currLeft, currWidth]]

          // Check each following element for collision/overlap
          for (let j = i + 1; j < this.number; j++) {
            const testBlock = [
              this.list[j].top - pageOffset,
              this.list[j].left,
              this.list[j].width,
            ]
            const overlapSemiData = this.getOverlap(testBlock, tempStack)

            if (overlapSemiData.overlap > 0) {
              scrollback = overlapSemiData.overlap
              break
            }
          }
        }

        currElement.style.position = 'fixed'
        currElement.style.top = overlapData.absolute - scrollback + 'px'
        currElement.style.left = currLeft + 'px'
        currElement.style.width = currWidth + 'px'
        currElement.style.zIndex = curr.zIndex
        currElement.style.marginTop = 0
        currElement.style.marginBottom = 0

        stack.push([overlapData.absolute + currElement.offsetHeight - scrollback, currElement.offsetLeft, currElement.offsetWidth])
      } else {
        this.removeClass(currElement, this.stuckClass)

        currElement.style.position = null
        currElement.style.top = null
        currElement.style.left = null
        currElement.style.width = null
        currElement.style.zIndex = null
        currElement.style.marginTop = null
        currElement.style.marginBottom = null

        currPlaceholder.style.display = 'none'
      }
    }
  }

  startObserving() {
    // If there's no existing MutationObserver object, create one to
    // trigger a recalulation on changes in the sticky elements.
    // If there is one, stop it.
    if (!this.observer) {
      const MutationObserver = window.MutationObserver || window.WebKitMutationObserver
      this.observer = new MutationObserver(() => this.init())
    } else {
      this.observer.disconnect()
    }

    // Since any change to the document might require a re-layout, observe EVERYTHING
    // TODO: Check performance penalty, if any.
    this.observer.observe(document.body, {
      attributes: true,
      characterData: true,
      subtree: true,
    })
  }

  stopObserving() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  getOverlap(block, stack) {
    let overlap = 0
    let absolute = 0
    for (let i = 0; i < stack.length; i++) {
      if (block[0] < stack[i][0] && (block[1] + block[2] > stack[i][1] && stack[i][1] + stack[i][2] > block[1])) {
        overlap = Math.max(overlap, stack[i][0] - block[0])
        absolute = Math.max(absolute, stack[i][0])
      }
    }
    return { overlap: overlap, absolute: absolute }
  }

  sortByTop(a, b) {
    return (a.top - b.top)
  }

  getCoords(el) {
    // From http://stackoverflow.com/a/26230989

    const box = el.getBoundingClientRect()

    const body = document.body
    const docEl = document.documentElement

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0

    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
  }

  // Helper-functions pilfered wholesale from
  // http://jaketrent.com/post/addremove-classes-raw-javascript/

  hasClass(el, className) {
    if (el.classList) {
      return el.classList.contains(className)
    } else {
      return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
    }
  }

  addClass(el, className) {
    if (el.classList) {
      el.classList.add(className)
    } else if (!this.hasClass(el, className)) {
      el.className += ' ' + className
    }
  }

  removeClass(el, className) {
    if (el.classList && this.hasClass(el, className)) {
      el.classList.remove(className)
    } else if (this.hasClass(el, className)) {
      const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
      el.className = el.className.replace(reg, ' ')
    }
  }

  static heightOfStickyElements() {
    return Array.from(
      document.querySelectorAll('.stick-top'),
    )
      .map(element => element.getBoundingClientRect().height)
      .reduce((x, y) => x + y)
  }
}

import { MutationTree } from 'vuex'
import { Products, Suggestions, PriceFilterItem } from '@one/types'
import Vue from 'vue'
import { getTime } from 'date-fns'
import { LoadedPage, ProductsState } from './types'
import Suggestion = Suggestions.Responses.Suggestion
import ProductPrice = Products.ProductPrice

export const mt = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_PRODUCT_PAGE: 'SET_PRODUCT_PAGE',
  SET_PRODUCT_PAGE_RELATED_PRODUCTS: 'SET_PRODUCT_PAGE_RELATED_PRODUCTS',
  SET_PRODUCT_PRICE: 'SET_PRODUCT_PRICE',
  SET_PRODUCTS_PRICES: 'SET_PRODUCTS_PRICES',
  CLEAR_PRODUCT_PAGE: 'CLEAR_PRODUCT_PAGE',
  SEARCH_CHANGED: 'SEARCH_CHANGED',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  CREATE_LOADED_PAGE: 'CREATE_LOADED_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGINATED_PRODUCTS: 'SET_PAGINATED_PRODUCTS',
  CLEAR_LOADED: 'CLEAR_LOADED',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
  SET_ORDERING: 'SET_ORDERING',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_SORT_CRITERIA: 'SET_SORT_CRITERIA',
  SET_SORTING_OPTIONS: 'SET_SORTING_OPTIONS',
  SET_FILTERS: 'SET_FILTERS',
  SET_PRICE: 'SET_PRICE',
  SET_PRICE_FILTER: 'SET_PRICE_FILTER',
  SET_CATEGORIES_COUNT: 'SET_CATEGORIES_COUNT',
  APPLY_FILTER: 'APPLY_FILTER',
  CLEAR_SELECTED_FILTERS: 'CLEAR_SELECTED_FILTERS',
  CLEAR_STORE: 'CLEAR_STORE',
  SET_SUGGESTIONS: 'SET_SUGGESTIONS',
  CATEGORY_SELECTED: 'CATEGORY_SELECTED',
  SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS',
  SET_DEFAULT_SORTING: 'SET_DEFAULT_SORTING',
  SET_PRODUCTS_DISPLAY_MODE: 'SET_PRODUCTS_DISPLAY_MODE',
  SET_MANUFACTURERS: 'SET_MANUFACTURERS',
  SET_STOCK_BY_WAREHOUSE: 'SET_STOCK_BY_WAREHOUSE',
  SET_CABLE_PRICES: 'SET_CABLE_PRICES',
}

const PRICE_TTL = 30000
const data: Array<Products.ExternalUrl> = [
  {
    fileName: 'photo',
    url:
      'https://static-demo-images.s3-eu-west-1.amazonaws.com/photo_plcs_min.svg',
    order: 1,
    placeholder: true,
  },
]

export const mutations: MutationTree<ProductsState> = {
  [mt.SET_PRODUCTS](state: ProductsState, products: Array<Products.Product>) {
    const mappedProducts = products
      .map(product => ({
        [product.id]: {
          ...product,
          photos: product.photos.length < 1 ? data : product.photos,
        },
      }))
      .reduce((x, y) => ({ ...x, ...y }), {})
    Vue.set(state, 'products', { ...state.products, ...mappedProducts })
  },
  [mt.SET_PRODUCT](state: ProductsState, product: Products.Product) {
    if (product.photos.length < 1) {
      product.photos = data
    }
    Vue.set(state.products, product.id, { ...product })
  },
  [mt.SET_PRODUCT_PAGE](state:ProductsState, productId: string) {
    state.productPage.id = productId
  },
  [mt.SET_PRODUCT_PAGE_RELATED_PRODUCTS](
    state:ProductsState,
    payload: Array<Products.RelatedProductsDto>,
  ) {
    state.productPage.relatedProducts = payload
  },

  [mt.CLEAR_PRODUCT_PAGE](state:ProductsState) {
    state.productPage.id = null
    state.productPage.price = null
    state.productPage.relatedProducts = []
  },
  [mt.SET_PRODUCTS_PRICES](state:ProductsState, prices: Array<ProductPrice>) {
    const mappedPrices = prices
      .map(price => ({
        [price.productId]: {
          ...price,
          timestamp: getTime(new Date()) + PRICE_TTL,
        },
      }))
      .reduce((x, y) => ({ ...x, ...y }), {})
    Vue.set(state, 'prices', { ...state.prices, ...mappedPrices })
  },
  [mt.SET_PRODUCT_PRICE](state:ProductsState, price: ProductPrice | null) {
    if (price) {
      price.timestamp = getTime(new Date()) + PRICE_TTL
      Vue.set(state.prices, price.productId, price)
    }
  },
  [mt.SEARCH_CHANGED](state:ProductsState, query: string) {
    state.searchText = query
    Vue.set(state.productsList, 'q', `${query}`)
  },
  [mt.SET_PAGE_NUMBER](state:ProductsState, page: number) {
    Vue.set(state.productsList, 'pageNumber', page)
  },
  [mt.CREATE_LOADED_PAGE](state, page) {
    Vue.set(state.productsList.loadedPages, page, {
      products: [],
      loaded: false,
    } as LoadedPage)
  },
  [mt.SET_PAGE_SIZE](state:ProductsState, pageSize: number) {
    Vue.set(state.productsList, 'pageSize', pageSize)
  },
  [mt.SET_PAGINATED_PRODUCTS](state:ProductsState, { page, productsIds }) {
    Vue.set(state.productsList.loadedPages[page], 'products', productsIds)
  },
  [mt.CLEAR_LOADED](state:ProductsState) {
    Vue.set(state.productsList, 'loadedPages', {})
  },
  [mt.FETCHING](state:ProductsState) {
    state.fetching = true
  },
  [mt.FETCHED](state:ProductsState) {
    state.fetching = false
  },
  [mt.CATEGORY_SELECTED](state:ProductsState, categorySelected: boolean) {
    state.categorySelected = categorySelected
  },
  [mt.SET_TOTAL_ITEMS](state:ProductsState, total: number) {
    Vue.set(state.productsList, 'totalItems', total)
  },
  [mt.SET_ORDERING](state:ProductsState, orderBy: string) {
    state.orderBy = orderBy
  },
  [mt.SET_TOTAL_PAGES](state:ProductsState, pages: number) {
    Vue.set(state.productsList, 'totalPages', pages)
  },
  [mt.SET_SORT_CRITERIA](state:ProductsState, sortCriteria: string) {
    Vue.set(state.productsList, 'sortCriteria', sortCriteria)
  },
  [mt.SET_PRICE](state:ProductsState, price: string) {
    Vue.set(state.productsList, 'price', price)
  },
  [mt.SET_SORTING_OPTIONS](
    state:ProductsState,
    sortingOptions: Array<Products.SortingOption>,
  ) {
    const isSortAvailable:
      | Products.SortingOption
      | undefined = sortingOptions.find(
      option => option.code === state.productsList.sortCriteria,
    )
    if (!isSortAvailable) {
      const selectedSort: Products.SortingOption | null =
        sortingOptions.find(option => option.selected) || null
      state.productsList.sortCriteria =
        (selectedSort && selectedSort.code) || sortingOptions[0].code
    }
    Vue.set(state.productsList, 'sortingOptions', sortingOptions)
  },
  [mt.SET_SELECTED_FILTERS](state:ProductsState, selectedFilters: any) {
    state.productsList.selectedFilters = selectedFilters
  },
  [mt.SET_FILTERS](state:ProductsState, filters) {
    Vue.set(state.productsList, 'attributes', filters)
  },
  [mt.SET_PRICE_FILTER](state:ProductsState, pricesFilter: Array<PriceFilterItem>) {
    const mappedPrices = pricesFilter
      .map(price => ({
        [price.name]: price,
      }))
      .reduce((x, y) => ({ ...x, ...y }), {})
    Vue.set(state.productsList, 'priceFilters', mappedPrices)
  },
  [mt.SET_CATEGORIES_COUNT](
    state:ProductsState,
    categoriesCount: { [key: string]: number },
  ) {
    state.productsList.categoriesCount = categoriesCount
  },
  [mt.APPLY_FILTER](state:ProductsState, { attrId, attrValueCode, value }) {
    // @ts-ignore
    state.productsList.attributes.byId[attrId].items[
      attrValueCode
    ].selected = value
  },
  [mt.CLEAR_SELECTED_FILTERS](state:ProductsState) {
    Object.keys(state.productsList.selectedFilters).forEach(
      (attribute: string) => {
        state.productsList.selectedFilters[attribute].forEach(
          (attributeValue: string) => {
            // @ts-ignore
            state.productsList.attributes.byId[attribute].items[
              attributeValue
            ].selected = false
          },
        )
      },
    )
    state.productsList.selectedFilters = {}
  },
  [mt.CLEAR_STORE](state:ProductsState) {
    state.fetching = false
    state.searchText = ''
    state.orderBy = ''
    state.suggest = null
    Vue.set(state, 'manufacturers', {})
    Vue.set(state, 'stocksByWarehouse', {})
    Vue.set(state, 'productPage', {})
    Vue.set(state, 'prices', {})
    Vue.set(state, 'cableDrumPrices', {})
    Vue.set(state, 'productsList', {
      q: '',
      pageSize: 15,
      pageNumber: 1,
      loadedPages: {},
      totalPages: 0,
      totalItems: 0,
      categoriesCount: {},
      filtersQuery: [],
      selectedFilters: {},
      attributes: {
        byId: {},
        allIds: [],
      },
      priceFilters: {},
      price: '',
      sortingOptions: [],
      sortCriteria: 'SCORE_DESC',
      // alphabeticalCategoriesSorting: false,
      // TODO: Tego nie chcemy resetowac, bo to jest globalne dla tego tenanta - upewnić się że tak jest
      sortOptionForCatalogBrowser: 'RANK_DESC',
      sortOptionForSearch: 'SCORE_DESC',
    })
  },
  [mt.SET_SUGGESTIONS](state:ProductsState, suggestions: Suggestion) {
    state.suggest = suggestions
  },
  [mt.SET_DEFAULT_SORTING](
    state:ProductsState,
    data: Products.Responses.DefaultSortOptions,
  ) {
    state.productsList.alphabeticalCategoriesSorting =
      data.alphabeticalCategoriesSorting
    state.productsList.sortOptionForSearch = data.sortOptionForSearch
    state.productsList.sortOptionForCatalogBrowser =
      data.sortOptionForCatalogBrowser
  },
  [mt.SET_PRODUCTS_DISPLAY_MODE](
    state:ProductsState,
    data: Products.ProductsListingConfigurationEnum,
  ) {
    state.productsList.displayMode = data
  },
  [mt.SET_MANUFACTURERS](state:ProductsState, { data, sku }) {
    Vue.set(state.manufacturers, sku, data)
  },
  [mt.SET_STOCK_BY_WAREHOUSE](state:ProductsState, { data, sku }) {
    Vue.set(state.stocksByWarehouse, sku, data)
  },
  [mt.SET_CABLE_PRICES](state:ProductsState, { data, sku }) {
    Vue.set(state.cableDrumPrices, sku, data)
  },
}
export default mutations

import { ActionContext, ActionTree } from 'vuex'
import { CMS, Pagination } from '@one/types'
import { AxiosResponse } from 'axios'
import { RootState } from '../index'
import { CmsState } from './types'
import { mt } from './mutations'
import MainConfigurationQuery = CMS.Responses.MainConfigurationQuery
import PagesDto = CMS.Responses.PagesDto
import PageDto = CMS.Responses.PageDto
import ArticleTag = CMS.Articles.Tags.ArticleTag

export const actions: ActionTree<CmsState, RootState> = {
  fetchMainConfiguration({
    commit,
  }: ActionContext<CmsState, RootState>): Promise<MainConfigurationQuery> {
    const app: any = this.app
    app.wait.start('main-page-fetching')

    return this.$api.cms.app
      .mainConfiguration()
      .then((success: AxiosResponse<MainConfigurationQuery>) => {
        commit(mt.SET_MAIN_PAGE_CONFIGURATION, success.data)
        return success.data
      })
      .finally(() => app.wait.end('main-page-fetching'))
  },
  fetchLocalizedMainPageConfiguration({ dispatch }: ActionContext<CmsState, RootState>) {
    console.warn('[DEPRECATED] Use fetchMainConfiguration')
    return dispatch('fetchMainConfiguration')
  },
  fetchStaticPages({
    commit,
  }: ActionContext<CmsState, RootState>): Promise<PagesDto> {
    const app: any = this.app
    app.wait.start('cms-pages-fetching')

    return this.$api.cms.app
      .getStaticPages()
      .then((success: AxiosResponse<PagesDto>) => {
        commit(mt.SET_PAGES, success.data)
        return success.data
      })
      .finally(() => app.wait.end('cms-pages-fetching'))
  },
  fetchStaticPage(
    { commit }: ActionContext<CmsState, RootState>,
    url: string,
  ): Promise<PageDto> {
    const app: any = this.app
    app.wait.start('cms-page-fetching')

    return this.$api.cms.app
      .getPage(url)
      .then((success: AxiosResponse<PageDto>) => {
        commit(mt.SET_PAGE_CONTENT, success.data)
        return success.data
      })
      .finally(() => app.wait.end('cms-page-fetching'))
  },
  fetchArticles(
    { commit }: ActionContext<CmsState, RootState>,
    {
      pageNumber,
      pageSize,
      tags,
    }: {
      pageNumber: number
      pageSize: number
      tags: Array<string>
    },
  ): Promise<Pagination.Pager<CMS.Responses.ArticleLink>> {
    const app: any = this.app
    app.wait.start('articles-list-fetching')

    return this.$api.cms.app
      .getArticles(pageNumber, pageSize, tags)
      .then(
        ({
          data,
        }: AxiosResponse<Pagination.Pager<CMS.Responses.ArticleLink>>) => {
          commit(mt.SET_ARTICLES_LIST, data)
          return Promise.resolve(data)
        },
      )
      .finally(() => app.wait.end('articles-list-fetching'))
  },
  fetchArticle(
    { commit }: ActionContext<CmsState, RootState>,
    slug: string,
  ): Promise<CMS.Responses.ArticleBody> {
    const app: any = this.app
    app.wait.start('single-article-fetching')

    return this.$api.cms.app
      .getArticle(slug)
      .then(({ data }: AxiosResponse<CMS.Responses.ArticleBody>) => {
        commit(mt.SET_ARTICLE, data)
        return Promise.resolve(data)
      })
      .finally(() => app.wait.end('single-article-fetching'))
  },
  fetchArticleTags({
    commit,
  }: ActionContext<CmsState, RootState>): Promise<Array<ArticleTag>> {
    const app: any = this.app
    app.wait.start('article-tags-fetching')

    return this.$api.cms.app
      .getArticleTags()
      .then(({ data }: AxiosResponse<Array<ArticleTag>>) => {
        commit(mt.SET_ARTICLE_TAGS, data)
        return Promise.resolve(data)
      })
      .finally(() => app.wait.end('article-tags-fetching'))
  },
  fetchGrid({ commit }, gridSlot: CMS.Grid.GridSlot) {
    return this.$api.cms.app
      .getGridBySlot(gridSlot)
      .then(({ data }: AxiosResponse<CMS.Grid.GridElement>) => {
        commit(mt.SET_GRID_TEMPLATE, {
          gridSlot,
          content: data,
        })
        return data
      })
  },
  fetchStaticPageBySlug({ commit }, slug: string) {
    return this.$api.cms.app.getStaticPageBySlug(slug).then((response) => {
      commit(mt.SET_STATIC_PAGE, response.data)
      return response.data
    })
  },
}
export default actions

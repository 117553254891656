import { commons } from '@one/core'
import { CMS } from '@one/types'
import { CmsState } from './types'
import Logo = CMS.Responses.Logo
import CMSTenantTheme = CMS.CMSTenantTheme

export default {
  isCMSPage: (state: CmsState) => (url: string): boolean =>
    !!state.pages[commons.UrlWithSlash(url)] && !!state.pages[commons.UrlWithoutSlash(url)],
  getPage: (state: CmsState) => (
    url: string,
  ): CMS.Responses.PageDto | null => {
    return (
      state.pages[commons.UrlWithSlash(url)] ||
      state.pages[commons.UrlWithoutSlash(url)] ||
      null
    )
  },
  configuration: (
    state: CmsState,
  ): CMS.Responses.MainConfigurationQuery | null => state.configuration || null,
  logo: (state: CmsState): Logo | null =>
    (state.configuration && state.configuration.logo) || null,
  language: (state: CmsState): string | null =>
    (state.configuration && state.configuration.lang) || null,
  theme: (state: CmsState): CMSTenantTheme | null =>
    (state.configuration && state.configuration.theme) || null,
  currency: (state: CmsState): string | null =>
    (state.configuration && state.configuration.currency) || null,
  articlesListItems: (
    state: CmsState,
  ): Array<CMS.Responses.ArticleLink> | null =>
    state.articles.articlesList && state.articles.articlesList.items,
  articleBySlug: (state: CmsState) => (
    slug: string,
  ): CMS.Responses.ArticleBody => state.articles.byId[slug] || null,
  articlesTotalItems: (state: CmsState): number | null =>
    state.articles.articlesList && state.articles.articlesList.totalItems,
  articlesTotalPages: (state: CmsState): number | null =>
    state.articles.articlesList && state.articles.articlesList.totalPages,
  articlesPageSize: (state: CmsState): number | null =>
    state.articles.articlesList && state.articles.articlesList.pageSize,
  articlesPageNumber: (state: CmsState): number | null =>
    state.articles.articlesList && state.articles.articlesList.pageNumber,
  articleTagByName: (state: CmsState) => (
    name: string,
  ): CMS.Articles.Tags.ArticleTag =>
    (state.articles.articleTags && state.articles.articleTags[name]) || null,
}

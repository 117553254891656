import { MutationTree } from 'vuex'
import Vue from 'vue'
import { RMA } from '@one/types'
import { arrayToObject } from '@one/core'
import { RMAState } from './types'

export const mt = {
  SET_COMPLAINT_REASONS: 'SET_COMPLAINT_REASONS',
  SET_PAGINATED_COMPLAINTS: 'SET_PAGINATED_COMPLAINTS',
  SET_COMPLAINTS: 'SET_COMPLAINTS',
  SET_COMPLAINTS_TOTAL: 'SET_COMPLAINTS_TOTAL',
  SET_COMPLAINT_DETAIL: 'SET_COMPLAINT_DETAIL',
  CLEAR_LOADED_COMPLAINTS_LIST: 'CLEAR_LOADED_COMPLAINTS_LIST',
  SET_PAGE_COMPLAINTS: 'SET_PAGE_COMPLAINTS',
  CLEAR_STORE: 'CLEAR_STORE',
}

export const mutations: MutationTree<RMAState> = {
  [mt.SET_COMPLAINT_REASONS](
    state: RMAState,
    data: Array<RMA.App.Response.ComplaintReasonDto>,
  ) {
    state.complaintReasons = data
      .map((reason: RMA.App.Response.ComplaintReasonDto) => ({
        [reason.id]: { ...reason },
      }))
      .reduce((x: any, y: any) => ({ ...x, ...y }), {})
  },
  [mt.SET_PAGINATED_COMPLAINTS](
    state: RMAState,
    complaintsIds: Array<string>,
  ) {
    Vue.set(
      state.complaintsList.loadedPages,
      state.complaintsList.page,
      complaintsIds,
    )
  },
  [mt.SET_COMPLAINTS](
    state: RMAState,
    complaints: RMA.App.Response.ComplaintDto,
  ) {
    Vue.set(state, 'complaints', {
      ...state.complaints,
      ...arrayToObject(complaints, 'id'),
    })
  },
  [mt.SET_COMPLAINTS_TOTAL](state: RMAState, total: number) {
    Vue.set(state.complaintsList, 'elementsAmount', total)
  },
  [mt.SET_COMPLAINT_DETAIL](
    state: RMAState,
    complaintDetail: RMA.App.Response.ComplaintDto,
  ) {
    Vue.set(state.complaints, complaintDetail.id, complaintDetail)
  },
  [mt.SET_PAGE_COMPLAINTS](state: RMAState, page: number) {
    Vue.set(state.complaintsList, 'page', page)
    Vue.set(
      state.complaintsList,
      'offset',
      page * state.complaintsList.rows - state.complaintsList.rows,
    )
  },
  [mt.CLEAR_LOADED_COMPLAINTS_LIST](state: RMAState) {
    Vue.set(state.complaintsList, 'loadedPages', {})
  },
  [mt.CLEAR_STORE](state: RMAState) {
    Vue.set(state, 'complaintReasons', {})
    Vue.set(state, 'complaints', {})
    Vue.set(state, 'createComplaint', {})
    Vue.set(state, 'complaintsList', {
      offset: 0,
      rows: 30,
      page: 1,
      elementsAmount: 0,
      loadedPages: {},
    })
  },
}
export default mutations

import {
  Offers,
} from '@one/types/dist/orderpath/app'
import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '~/store'

export interface OffersState {
  offersList: Offers.Responses.OffersList
  fetchParams: any
}

export const state = (): OffersState => ({
  offersList: {
    items: [],
    totalItems: 0,
    pageNumber: 1,
    totalPages: 0,
    pageSize: 10,
  } as Offers.Responses.OffersList,
  fetchParams: {
    pageNumber: 1,
    pageSize: 10,
    search: null,
    status: null,
    createdOnStartDate: null,
    createdOnEndDate: null,
    expirationDateStartDate: null,
    expirationDateEndDate: null,
  },
})

const namespaced: boolean = true

const cart: Module<OffersState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
}
export default cart

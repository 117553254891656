import { LayoutState } from './types'

export const state = (): LayoutState => ({
  breadcrumbs: [],
  showBreadcrumbs: true,
  layoutScroll: false,
  menuMobileShow: false,
  menuAccountShow: false,
  menuFiltersShow: false,
  menuCategoriesShow: false,
  backdrop: false,
  lang: '',
  currency: {
    iso: '',
    code: '',
    symbol: '',
  },
  priceGross: false,
  shouldSetGrossAsDefaultValue: false,
})

import { Store } from 'vuex'
import { Context } from '~/node_modules/@nuxt/types'
import VueI18n, { IVueI18n } from '~/node_modules/vue-i18n'
import { RootState } from '~/store'

export class BaseService<T> {
  protected readonly context: Context;
  protected readonly store: Store<RootState>;
  protected readonly stateName: string;
  protected readonly i18n: VueI18n & IVueI18n;

  constructor(context: any, stateName: string) {
    this.context = context
    this.store = context.store
    this.stateName = stateName
    this.i18n = context.app.i18n
  }

  public dispatch<T>(action: string, payload?: any): Promise<T> {
    return this.store.dispatch(`${this.stateName}/${action}`, payload)
  }

  public commit(mutation: string, payload?: any) {
    this.store.commit(`${this.stateName}/${mutation}`, payload)
  }

  public get state(): T {
    return this.store.state[this.stateName]
  }
}

import { MutationTree } from 'vuex'
import Vue from 'vue'
import {
  PaymentMethod,
  Checkout,
  Orders,
  Rfq,
} from '@one/types'
import {
  Cart,
  OrderConfirmation,
  Shared,
} from '@one/types/dist/orderpath/app'
import { arrayToObject, converters } from '@one/core'
import { arrangeProductsInCart, getProductKeyAttribute, reorderProducts } from './utils'
import { CartPaymentMethods, CartState, MappedCart, MappedCartObject } from './types'
import Shipment = Shared.Shipment
import ProductId = Shared.ProductId

export const mt = {
  SET_DELIVERY_METHODS: 'SET_DELIVERY_METHODS',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  // Cart related
  SET_CART: 'SET_CART',
  SET_BASIC_CARTS: 'SET_BASIC_CARTS',
  SET_CURRENT_CART: 'SET_CURRENT_CART',
  ADD_NEW_CART: 'ADD_NEW_CART',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_CONFIRMATION_COMMENTS: 'SET_CONFIRMATION_COMMENTS',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_DELIVERY_METHOD: 'SET_DELIVERY_METHOD',
  CHANGE_ITEMS_ORDERING: 'CHANGE_ITEMS_ORDERING',
  FETCHING_DELIVERY_METHODS: 'FETCHING_DELIVERY_METHODS',
  FETCHED_DELIVERY_METHODS: 'FETCHED_DELIVERY_METHODS',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  UNSELECT_PRODUCT: 'UNSELECT_PRODUCT',
  SELECT_ALL_PRODUCTS: 'SELECT_ALL_PRODUCTS',
  UNSELECT_ALL_PRODUCTS: 'UNSELECT_ALL_PRODUCTS',
  // Checkout related`
  SET_CHECKOUT: 'SET_CHECKOUT',
  // Order Confirmation related
  SET_ORDER_CONFIRMATION: 'SET_ORDER_CONFIRMATION',
  SET_ORDERS: 'SET_ORDERS',
  SET_ORDER_DETAIL: 'SET_ORDER_DETAIL',
  SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',
  SET_SELECTED_ORDER_CONFIRMATION: 'SET_SELECTED_ORDER_CONFIRMATION',
  // Orders
  CLEAR_LOADED: 'CLEAR_LOADED',
  SET_PAGINATED_ORDERS: 'SET_PAGINATED_ORDERS',
  SET_PAGE: 'SET_PAGE',
  SET_TOTAL: 'SET_TOTAL',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  SEARCH_CHANGED: 'SEARCH_CHANGED',
  RENAME_CART: 'RENAME_CART',
  CLEAR_STORE: 'CLEAR_STORE',
  CLEAR_LISTING_STORE: 'CLEAR_LISTING_STORE',
  SET_FILTER_START_DATE_ONLINE: 'SET_FILTER_START_DATE_ONLINE',
  SET_FILTER_END_DATE_ONLINE: 'SET_FILTER_END_DATE_ONLINE',
  SET_FILTER_START_DATE_OFFLINE: 'SET_FILTER_START_DATE_OFFLINE',
  SET_FILTER_END_DATE_OFFLINE: 'SET_FILTER_END_DATE_OFFLINE',
  // Rfq
  SET_RFQ_DETAIL: 'SET_RFQ_DETAIL',
  SET_PAGINATED_RFQ: 'SET_PAGINATED_RFQ',
  SET_RFQ: 'SET_RFQ',
  SET_TOTAL_RFQ: 'SET_TOTAL_RFQ',
  SEARCH_CHANGED_RFQ: 'SEARCH_CHANGED_RFQ',
  SET_PAGE_RFQ: 'SET_PAGE_RFQ',
  SET_FILTER_RFQ_START_DATE: 'SET_FILTER_RFQ_START_DATE',
  SET_FILTER_RFQ_END_DATE: 'SET_FILTER_RFQ_END_DATE',
  SET_FILTER_ORDER_STATUS: 'SET_FILTER_ORDER_STATUS',
  CLEAR_LOADED_RFQ: 'CLEAR_LOADED_RFQ',
  // Offline orders
  SET_OFFLINE_ORDERS_DETAIL: 'SET_OFFLINE_ORDERS_DETAIL',
  SET_PAGINATED_OFFLINE_ORDERS: 'SET_PAGINATED_OFFLINE_ORDERS',
  SET_OFFLINE_ORDERS: 'SET_OFFLINE_ORDERS',
  SET_TOTAL_OFFLINE_ORDERS: 'SET_TOTAL_OFFLINE_ORDERS',
  SEARCH_CHANGED_OFFLINE_ORDERS: 'SEARCH_CHANGED_OFFLINE_ORDERS',
  SET_PAGE_OFFLINE_ORDERS: 'SET_PAGE_OFFLINE_ORDERS',
  SET_ORDERS_STATUSES: 'SET_ORDERS_STATUSES',
  CLEAR_LOADED_OFFLINE_ORDERS: 'CLEAR_LOADED_OFFLINE_ORDERS',
  SET_ADDITIONAL_COST_TYPE: 'SET_ADDITIONAL_COST_TYPE',
  SET_SHIPMENT_VALIDITY: 'SET_SHIPMENT_VALIDITY',
  RESET_SHIPMENTS_VALIDITY_MAP: 'RESET_SHIPMENTS_VALIDITY_MAP',
  SET_IS_MANUAL_PROMOTIONS_LIST_COLLAPSED: 'SET_IS_MANUAL_PROMOTIONS_LIST_COLLAPSED',
}

export const mutations: MutationTree<CartState> = {
  [mt.ADD_NEW_CART](
    state: CartState,
    newCart: Cart.Responses.NewCart,
  ) {
    Vue.set(state.carts, newCart.cartId, newCart)
  },
  [mt.CHANGE_ITEMS_ORDERING](
    state: CartState,
    { cartId, ordering },
  ) {
    state.carts[cartId].cartObject.products = reorderProducts(
      state.carts[cartId].cartObject.products,
      ordering,
    )
  },
  [mt.SET_ADDITIONAL_COST_TYPE](
    state: CartState,
    { type, name }: { type: string; name: string },
  ) {
    const newCostTypeName = { [type]: name }
    state.additionalCostTypes = {
      ...state.additionalCostTypes,
      ...newCostTypeName,
    }
  },
  [mt.SET_CURRENT_CART](state: CartState, cartId: string) {
    state.selectedCart = cartId
  },
  [mt.SET_BASIC_CARTS](
    state: CartState,
    carts: Array<Cart.Responses.BasicCartInfo>,
  ) {
    state.basicCarts = carts
      .map((cart: Cart.Responses.BasicCartInfo) => ({ [cart.id]: { ...cart } }))
      .reduce((x: any, y: any) => ({ ...x, ...y }), {})
  },
  [mt.RENAME_CART](
    state: CartState,
    { cartId, name }: { cartId: string; name: string },
  ) {
    if (state.carts[cartId]) {
      Vue.set(state.carts[cartId].cartObject, 'name', name)
    }
    if (state.basicCarts[cartId]) {
      Vue.set(state.basicCarts[cartId], 'name', name)
    }
  },
  [mt.SET_CART](
    state: CartState,
    {
      cart,
      contextWarehouseId,
    }: {
      cart: Cart.Responses.Cart
      contextWarehouseId: string
    },
  ) {
    if (!contextWarehouseId) {
      throw new Error('Missing contextWarehouseId in SET_CART mutation')
    }
    const shipments: Record<string, Shipment> = arrayToObject(cart.shipments, 'id')
    const mappedProducts = arrangeProductsInCart(cart)
    const newCartObject: MappedCartObject = {
      ...cart,
      products: mappedProducts,
      shipments: shipments,
    }
    const currentCartState: MappedCart | undefined = state.carts[cart.id]
    if (currentCartState) {
      const updatedMappedCartState: MappedCart = {
        ...currentCartState,
        cartObject: newCartObject,
        contextWarehouseId: contextWarehouseId,
      }
      Vue.set(state.carts, cart.id, updatedMappedCartState)
    } else {
      const newMappedCartState: MappedCart = {
        cartObject: newCartObject,
        contextWarehouseId: contextWarehouseId,
        deliveryMethods: null,
        paymentMethods: null,
      }
      Vue.set(state.carts, cart.id, newMappedCartState)
    }
  },
  [mt.SET_DELIVERY_METHODS](
    state: CartState,
    { warehouseId, cartId, deliveryMethods }: any,
  ) {
    if (!warehouseId) {
      throw new Error('Missing warehouseId in SET_DELIVERY_METHODS mutation')
    }
    if (!state.carts[cartId]) {
      throw new Error(`Tried to set deliveryMethods on non existing cart ${cartId}`)
    }
    Vue.set(state.carts[cartId], 'deliveryMethods', {
      contextWarehouseId: warehouseId,
      shippingMethodsMapping: deliveryMethods,
    })
  },
  [mt.SET_PAYMENT_METHODS](
    state: CartState,
    {
      cartId,
      paymentMethods,
      warehouseId,
    }: {
      cartId: string
      paymentMethods: Array<PaymentMethod>
      warehouseId: string
    },
  ) {
    if (!warehouseId) {
      throw new Error('Missing warehouseId in SET_PAYMENT_METHODS mutation')
    }
    const newPaymentMethods: CartPaymentMethods = {
      contextWarehouseId: warehouseId,
      methods: paymentMethods
        .map((method: PaymentMethod) => ({ [method.id]: { ...method } }))
        .reduce((x: any, y: any) => ({ ...x, ...y }), {}),
    }
    Vue.set(state.carts[cartId], 'paymentMethods', newPaymentMethods)
  },
  [mt.SET_PAYMENT_METHOD](
    state: CartState,
    { cartId, paymentMethodId }: { cartId: string; paymentMethodId: string },
  ) {
    Vue.set(state.carts[cartId].cartObject, 'paymentMethodId', paymentMethodId)
  },
  [mt.SELECT_PRODUCT](
    state: CartState,
    { cartId, productId, productLineId }: { cartId: string; productId: string, productLineId: string },
  ) {
    const productsIds = state.carts[cartId].cartObject.selectedProducts || []
    productsIds && productsIds.push({ productId, productLineId })
    Vue.set(state.carts[cartId].cartObject, 'selectedProducts', productsIds)
  },
  [mt.SELECT_ALL_PRODUCTS](
    state: CartState,
    { cartId, productsIds }: { cartId: string; productsIds: Array<string> },
  ) {
    Vue.set(state.carts[cartId].cartObject, 'selectedProducts', productsIds)
  },
  [mt.UNSELECT_ALL_PRODUCTS](state: CartState, cartId: string) {
    Vue.set(state.carts[cartId].cartObject, 'selectedProducts', [])
  },
  [mt.UNSELECT_PRODUCT](
    state: CartState,
    context: { cartId: string; productId: string; productLineId: string; },
  ) {
    const selected = state.carts[context.cartId].cartObject.selectedProducts
    if (selected) {
      const selectedProduct = selected.filter((selected: ProductId) => {
        const key = getProductKeyAttribute(selected)
        return selected[key] !== context[key]
      })
      Vue.set(state.carts[context.cartId].cartObject, 'selectedProducts', selectedProduct)
    }
  },
  [mt.SET_COMMENTS](
    state: CartState,
    {
      cartId,
      comments,
    }: { cartId: string; comments: Cart.Requests.SetCartCommentsOption },
  ) {
    Vue.set(state.carts[cartId].cartObject, 'notes', comments.notes)
    Vue.set(
      state.carts[cartId].cartObject,
      'purchaseOrderNumber',
      comments.purchaseOrderNumber,
    )
  },
  [mt.SET_CONFIRMATION_COMMENTS](
    state: CartState,
    {
      orderConfirmationId,
      comments,
    }: {
      orderConfirmationId: string
      comments: OrderConfirmation.Requests.SetOrderConfirmationCommentsOption
    },
  ) {
    Vue.set(state.orderConfirmations[orderConfirmationId], 'notes', comments)
  },
  [mt.SET_CHECKOUT](
    state: CartState,
    {
      checkout,
    }: { checkoutId: string; checkout: Checkout.Responses.CheckoutCart },
  ) {
    Vue.set(state.cartsCheckouts, checkout.cartId, checkout.checkoutId)
    Vue.set(state.checkouts, checkout.checkoutId, checkout)
  },
  [mt.SET_ORDER_CONFIRMATION](
    state: CartState,
    {
      orderConfirmation,
    }: { orderConfirmation: OrderConfirmation.Responses.OrderConfirmationCart },
  ) {
    Vue.set(
      state.orderConfirmations,
      orderConfirmation.orderConfirmationId,
      orderConfirmation,
    )
    Vue.set(
      state.cartsOrderConfirmations,
      orderConfirmation.cartId,
      orderConfirmation.orderConfirmationId,
    )
  },
  [mt.SET_ORDERS](
    state: CartState,
    ordersHistory: Array<Orders.Order>,
  ) {
    const mappedOrders = ordersHistory
      .map((order: Orders.Order) => ({ [order.id]: { ...order } }))
      .reduce((x: any, y: any) => ({ ...x, ...y }), {})
    Vue.set(state, 'orders', { ...state.orders, ...mappedOrders })
  },
  [mt.SET_ORDER_DETAIL](
    state: CartState,
    orderDetail: Orders.DetailedOrder.Order,
  ) {
    Vue.set(state.ordersDetail, orderDetail.id, orderDetail)
  },
  [mt.SET_SELECTED_ORDER](state: CartState, orderId: string | null) {
    state.selectedOrder = orderId
  },
  [mt.SET_SELECTED_ORDER_CONFIRMATION](
    state: CartState,
    orderConfirmationId: string | null,
  ) {
    state.selectedOrderConfirmation = orderConfirmationId
  },
  [mt.SET_PAGE](state: CartState, page: number) {
    Vue.set(state.ordersHistory, 'page', page)
    Vue.set(state.ordersHistory, 'start', page - 1)
  },
  [mt.SET_PAGINATED_ORDERS](
    state: CartState,
    orders: Array<string>,
  ) {
    // @ts-ignore
    Vue.set(state.ordersHistory.loadedPages, state.ordersHistory.page, orders)
  },
  [mt.CLEAR_LOADED](state: CartState) {
    Vue.set(state.ordersHistory, 'loadedPages', {})
  },
  [mt.SET_TOTAL](state: CartState, total: number) {
    Vue.set(state.ordersHistory, 'total', total)
  },
  [mt.FETCHING_DELIVERY_METHODS](state: CartState) {
    state.fetchingDeliveryMethods = true
  },
  [mt.FETCHED_DELIVERY_METHODS](state: CartState) {
    state.fetchingDeliveryMethods = false
  },
  [mt.SEARCH_CHANGED](state: CartState, search: string) {
    Vue.set(state.ordersHistory, 'search', `${search}`)
  },
  [mt.SET_DELIVERY_METHOD](
    state: CartState,
    { cartId, deliveryMethodId }: { cartId: string; deliveryMethodId: string },
  ) {
    Vue.set(
      state.carts[cartId].cartObject.shipments,
      'deliveryMethodId',
      deliveryMethodId,
    )
  },
  // RFQ
  [mt.SET_TOTAL_RFQ](state: CartState, total: number) {
    Vue.set(state.rfqsList, 'total', total)
  },
  [mt.SET_RFQ](state: CartState, rfq: Array<Rfq.RfqCart>) {
    Vue.set(state, 'rfqs', {
      ...state.rfqs,
      ...arrayToObject(rfq, 'rfqNegotiationId'),
    })
  },
  [mt.SET_RFQ_DETAIL](
    state: CartState,
    rfqDetail: Rfq.RfqDetailed.DetailedRfqCart,
  ) {
    Vue.set(state.rfqsDetail, rfqDetail.id, rfqDetail)
  },
  [mt.SET_PAGE_RFQ](state: CartState, page: number) {
    Vue.set(state.rfqsList, 'page', page)
    Vue.set(state.rfqsList, 'start', page)
  },
  [mt.SET_PAGINATED_RFQ](state: CartState, orders: Array<string>) {
    // @ts-ignore
    Vue.set(state.rfqsList.loadedPages, state.rfqsList.page, orders)
  },
  [mt.SEARCH_CHANGED_RFQ](state: CartState, search: string) {
    Vue.set(state.rfqsList, 'search', `${search}`)
  },
  [mt.CLEAR_LOADED_RFQ](state: CartState) {
    Vue.set(state.rfqsList, 'loadedPages', {})
  },
  // OFFLINE_ORDERS
  [mt.SET_TOTAL_OFFLINE_ORDERS](state: CartState, total: number) {
    Vue.set(state.offlineOrdersList, 'total', total)
  },
  [mt.SET_OFFLINE_ORDERS](
    state: CartState,
    offlineOrders: Array<Orders.OfflineOrder>,
  ) {
    Vue.set(state, 'offlineOrders', {
      ...state.offlineOrders,
      ...arrayToObject(offlineOrders, 'id'),
    })
  },
  [mt.SET_OFFLINE_ORDERS_DETAIL](
    state: CartState,
    offlineOrdersDetail: Orders.OfflineOrder,
  ) {
    Vue.set(
      state.offlineOrdersDetail,
      offlineOrdersDetail.id,
      offlineOrdersDetail,
    )
  },
  [mt.SET_PAGE_OFFLINE_ORDERS](state: CartState, page: number) {
    Vue.set(state.offlineOrdersList, 'page', page)
    Vue.set(
      state.offlineOrdersList,
      'start',
      page * state.offlineOrdersList.rows - state.offlineOrdersList.rows,
    )
  },
  [mt.SET_PAGINATED_OFFLINE_ORDERS](
    state: CartState,
    orders: Array<string>,
  ) {
    // @ts-ignore
    Vue.set(
      state.offlineOrdersList.loadedPages,
      state.offlineOrdersList.page,
      orders,
    )
  },
  [mt.SEARCH_CHANGED_OFFLINE_ORDERS](
    state: CartState,
    search: string,
  ) {
    Vue.set(state.offlineOrdersList, 'search', `${search}`)
  },
  [mt.SET_FILTER_START_DATE_ONLINE](
    state: CartState,
    startDate: Date,
  ) {
    Vue.set(state.ordersHistory, 'startDate', converters.prepareFormatDate(startDate))
  },
  [mt.SET_FILTER_ORDER_STATUS](
    state: CartState,
    orderStatus: Array<string>,
  ) {
    Vue.set(state.ordersHistory, 'shipmentStatuses', orderStatus)
  },
  [mt.SET_FILTER_END_DATE_ONLINE](state: CartState, endDate: Date) {
    Vue.set(state.ordersHistory, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.SET_FILTER_START_DATE_OFFLINE](
    state: CartState,
    startDate: Date,
  ) {
    Vue.set(state.offlineOrdersList, 'startDate', converters.prepareFormatDate(startDate))
  },
  [mt.SET_FILTER_END_DATE_OFFLINE](state: CartState, endDate: Date) {
    Vue.set(state.offlineOrdersList, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.SET_FILTER_RFQ_START_DATE](state: CartState, startDate: Date) {
    Vue.set(state.rfqsList, 'startDate', converters.prepareFormatDate(startDate))
  },
  [mt.SET_FILTER_RFQ_END_DATE](state: CartState, endDate: Date) {
    Vue.set(state.rfqsList, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.CLEAR_LOADED_OFFLINE_ORDERS](state: CartState) {
    Vue.set(state.offlineOrdersList, 'loadedPages', {})
  },
  [mt.SET_ORDERS_STATUSES](
    state: CartState,
    data: Array<Orders.OrderStatusDto>,
  ) {
    state.orderStatuses = data
      .map((status: Orders.OrderStatusDto) => ({ [status.id]: { ...status } }))
      .reduce((x: any, y: any) => ({ ...x, ...y }), {})
  },
  [mt.SET_SHIPMENT_VALIDITY](state: CartState, { shipmentId, isValid }) {
    Vue.set(state.shipmentsValidity, shipmentId, isValid)
  },
  [mt.RESET_SHIPMENTS_VALIDITY_MAP](state: CartState) {
    state.shipmentsValidity = {}
  },
  // Clear store
  [mt.CLEAR_LISTING_STORE](state: CartState, listingName: string) {
    Vue.set(state, listingName, {
      start: 1,
      rows: 30,
      page: 1,
      total: 0,
      search: null,
      loadedPages: {},
    })
  },
  [mt.SET_IS_MANUAL_PROMOTIONS_LIST_COLLAPSED](state, isOpen) {
    Vue.set(state, 'isManualPromotionsListCollapsed', isOpen)
  },
  [mt.CLEAR_STORE](state: CartState) {
    Vue.set(state, 'carts', {})
    Vue.set(state, 'basicCarts', {})
    Vue.set(state, 'paymentMethods', {})
    Vue.set(state, 'checkouts', {})
    Vue.set(state, 'orderConfirmations', {})
    Vue.set(state, 'cartsCheckouts', {})
    Vue.set(state, 'cartsOrderConfirmations', {})
    Vue.set(state, 'orders', {})
    Vue.set(state, 'ordersDetail', {})
    Vue.set(state, 'ordersHistory', {
      start: 0,
      rows: 30,
      page: 1,
      total: 0,
      search: null,
      loadedPages: {},
    })
    Vue.set(state, 'rfqsList', {
      start: 0,
      rows: 30,
      page: 1,
      total: 0,
      search: null,
      loadedPages: {},
    })
    Vue.set(state, 'offlineOrdersList', {
      start: 0,
      rows: 30,
      page: 1,
      total: 0,
      search: null,
      loadedPages: {},
    })
    state.selectedOrder = null
    state.selectedOrderConfirmation = null
    state.selectedCart = null
    state.shipmentsValidity = {}
    state.isManualPromotionsListCollapsed = false
  },
}
export default mutations

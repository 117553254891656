import { MutationTree } from 'vuex/types/index'

export const MUTATIONS = {
  SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
  SET_PAYMENT_URL: 'SET_PAYMENT_URL',
  SET_PAYMENT_SECRET: 'SET_PAYMENT_SECRET',
}
export const mutations: MutationTree<any> = {
  [MUTATIONS.SET_PAYMENT_STATUS](state, status) {
    state.status = status
  },
  [MUTATIONS.SET_PAYMENT_URL](state, paymentUrl) {
    state.paymentUrl = paymentUrl
  },
  [MUTATIONS.SET_PAYMENT_SECRET](state, secret) {
    state.secret = secret
  },
}
export default mutations


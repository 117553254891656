import Vue from 'vue'
import { CartService } from '~/plugins/services/cart'
import { WishlistService } from '~/plugins/services/wishlist'

export interface OrderpathService {
  readonly cart: CartService;
  readonly wishlist: WishlistService;
}

export class Orderpath implements OrderpathService {
  public cart: CartService;
  public wishlist: WishlistService;

  constructor(context: any) {
    this.cart = new CartService(context)
    this.wishlist = new WishlistService(context)
  }
}
export default (ctx: any, inject: Function) => {
  inject('orderpath', Vue.observable(new Orderpath(ctx)))
}

import { MutationTree } from 'vuex'
import { Account, Auth, GoodsIssuedNotes, Documents, Pagination } from '@one/types'
import { arrayToObject, converters } from '@one/core'
import Vue from 'vue'
import { AccountState } from './types'
import Pager = Pagination.Pager
import GoodsReceivedNoteRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView;
export const mt = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_DELIVERY_ORDERS: 'SET_DELIVERY_ORDERS',
  SET_USERS_LIST: 'SET_USERS_LIST',
  SET_CLIENT_ADDRESSES: 'SET_CLIENT_ADDRESSES',
  SET_CLIENT_DATA: 'SET_CLIENT_DATA',
  SET_AGREEMENTS: 'SET_AGREEMENTS',
  CLEAR_AGREEMENTS: 'CLEAR_AGREEMENTS',
  SET_ELECTRONIC_INVOICES_AGREEMENT: 'SET_ELECTRONIC_INVOICES_AGREEMENT',
  CHANGE_AGREEMENT_ACCEPTANCE_STATE: 'CHANGE_AGREEMENT_ACCEPTANCE_STATE',
  SET_TRADE_CREDIT: 'SET_TRADE_CREDIT',
  SET_WAREHOUSE_BLOCKED: 'SET_WAREHOUSE_BLOCKED',
  SET_REGISTRATION_SETTING: 'SET_REGISTRATION_SETTING',
  // Invoices && Documents
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_DOCUMENTS_TYPE: 'SET_DOCUMENTS_TYPE',
  SET_ALL_NUMBERS_DOCUMENTS: 'SET_ALL_NUMBERS_DOCUMENTS',
  CHANGE_DOCUMENT_SELECTION_STATUS: 'CHANGE_DOCUMENT_SELECTION_STATUS',
  SET_ONLY_UNPAID_DOCUMENTS: 'SET_ONLY_UNPAID_DOCUMENTS',
  SET_DOCUMENTS_PAYMENT_DATA: 'SET_DOCUMENTS_PAYMENT_DATA',
  SET_SORTING_MODE: 'SET_SORTING_MODE',
  SET_CHANGE_DOCUMENT_FILTER: 'SET_CHANGE_DOCUMENT_FILTER',
  SET_FILTER_START_DATE: 'SET_FILTER_START_DATE',
  SET_FILTER_END_DATE: 'SET_FILTER_END_DATE',
  // Invoices && Documents Paginated
  CLEAR_LOADED: 'CLEAR_LOADED',
  CLEAR_DOCUMENTS_LIST: 'CLEAR_DOCUMENTS_LIST',
  SET_PAGINATED_DOCUMENTS: 'SET_PAGINATED_DOCUMENTS',
  SET_PAGE: 'SET_PAGE',
  SET_TOTAL: 'SET_TOTAL',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  SEARCH_CHANGED: 'SEARCH_CHANGED',
  CLEAR_STORE: 'CLEAR_STORE',
  // Roles and Authorities
  SET_AUTHORITIES: 'SET_AUTHORITIES',
  SET_ROLES: 'SET_ROLES',
  // Goods issued notes
  SET_GOODS_ISSUED_NOTES: 'SET_GOODS_ISSUED_NOTES',
  SET_GOODS_ISSUED_NOTES_NUMBER: 'SET_GOODS_ISSUED_NOTES_NUMBER',
  SET_START_DATE_GOODS_ISSUED_NOTES: 'SET_START_DATE_GOODS_ISSUED_NOTES',
  SET_END_DATE_GOODS_ISSUED_NOTES: 'SET_END_DATE_GOODS_ISSUED_NOTES',
  SET_SEARCH_GOODS_ISSUED_NOTES: 'SET_SEARCH_GOODS_ISSUED_NOTES',
  SET_SORT_GOODS_ISSUED_NOTES: 'SET_SORT_GOODS_ISSUED_NOTES',
  SET_PAGE_GOODS_ISSUED_NOTES: 'SET_PAGE_GOODS_ISSUED_NOTES',
  SET_GOODS_ISSUED_NOTE: 'SET_GOODS_ISSUED_NOTE',
  SET_GUS_DATA: 'SET_GUS_DATA',
  // Goods received notes
  SET_GOODS_RECEIVED_NOTES: 'SET_GOODS_RECEIVED_NOTES',
  SET_GOODS_RECEIVED_NOTES_NUMBER: 'SET_GOODS_RECEIVED_NOTES_NUMBER',
  SET_START_DATE_GOODS_RECEIVED_NOTES: 'SET_START_DATE_GOODS_RECEIVED_NOTES',
  SET_END_DATE_GOODS_RECEIVED_NOTES: 'SET_END_DATE_GOODS_RECEIVED_NOTES',
  SET_SEARCH_GOODS_RECEIVED_NOTES: 'SET_SEARCH_GOODS_RECEIVED_NOTES',
  SET_SORT_GOODS_RECEIVED_NOTES: 'SET_SORT_GOODS_RECEIVED_NOTES',
  SET_PAGE_GOODS_RECEIVED_NOTES: 'SET_PAGE_GOODS_RECEIVED_NOTES',
  SET_GOODS_RECEIVED_NOTE: 'SET_GOODS_RECEIVED_NOTE',
}

export const mutations: MutationTree<AccountState> = {
  [mt.SET_AGREEMENTS](
    state: AccountState,
    agreements: Array<Account.App.Responses.Agreement>,
  ) {
    Vue.set(state, 'agreements', {
      ...state.agreements,
      ...arrayToObject(agreements, 'id'),
    })
  },
  [mt.CLEAR_AGREEMENTS](state: AccountState) {
    Vue.set(state, 'agreements', [])
  },
  [mt.SET_ELECTRONIC_INVOICES_AGREEMENT](
    state: AccountState,
    item: Array<Account.App.Responses.InvoiceAgreement>,
  ) {
    Vue.set(state.agreements, 'electronicInvoices', item)
  },
  [mt.CHANGE_AGREEMENT_ACCEPTANCE_STATE](
    state: AccountState,
    item: Account.App.Responses.Agreement,
  ) {
    state.agreements[item && item.id] &&
      Vue.set(state.agreements[item.id], 'isAccepted', !item.isAccepted)
  },
  [mt.SET_CLIENT_DATA](
    state: AccountState,
    client: Account.App.Responses.ClientRestView,
  ) {
    state.client = client
  },
  [mt.SET_USERS_LIST](
    state: AccountState,
    usersList: Pager<Account.App.Responses.User>,
  ) {
    state.clientUsers = usersList
  },
  [mt.SET_CLIENT_ADDRESSES](
    state: AccountState,
    addressList: Pager<Account.App.Responses.Address>,
  ) {
    state.clientAddresses = addressList
  },
  [mt.SET_TRADE_CREDIT](
    state: AccountState,
    tradeCredit: Account.App.Responses.TradeCredit,
  ) {
    state.tradeCredit = tradeCredit
  },
  [mt.SET_DOCUMENTS](
    state: AccountState,
    documentsPage: Array<Documents.DocumentDto>,
  ) {
    Vue.set(state, 'documents', {
      ...state.documents,
      ...arrayToObject(documentsPage, 'number'),
    })
  },
  [mt.SET_DOCUMENTS_TYPE](
    state: AccountState,
    documentTypes: Array<Documents.DocumentTypeEnum>,
  ) {
    Vue.set(state.documentsPage, 'documentTypes', documentTypes)
  },
  [mt.SET_ALL_NUMBERS_DOCUMENTS](
    state: AccountState,
    documentNumbers: Array<string>,
  ) {
    Vue.set(
      state.documentsPage,
      'allDocumentsSelected',
      !!documentNumbers.length,
    )
    Vue.set(state.documentsPage, 'selectedDocuments', documentNumbers)
  },
  [mt.SET_DOCUMENTS_PAYMENT_DATA](
    state: AccountState,
    paymentData: Documents.DocumentSums,
  ) {
    state.documentsPage.sumsInvoice = paymentData
  },
  [mt.SET_ONLY_UNPAID_DOCUMENTS](
    state: AccountState,
    value: boolean,
  ) {
    Vue.set(state.documentsPage, 'onlyUnpaid', value)
  },
  [mt.SET_CHANGE_DOCUMENT_FILTER](
    state: AccountState,
    value: Documents.DocumentFilterEnum,
  ) {
    Vue.set(state.documentsPage, 'paymentFilter', value)
  },
  [mt.SET_SORTING_MODE](
    state: AccountState,
    value: Documents.SortingModeEnum,
  ) {
    Vue.set(state.documentsPage, 'sortingMode', value)
  },
  [mt.CHANGE_DOCUMENT_SELECTION_STATUS](
    state: AccountState,
    documentNumber: string,
  ) {
    const selectedDocuments = state.documentsPage.selectedDocuments
    const thereIsDocument = selectedDocuments.indexOf(documentNumber)
    if (thereIsDocument < 0) {
      selectedDocuments.push(documentNumber)
    } else {
      selectedDocuments.splice(thereIsDocument, 1)
    }
    Vue.set(state.documentsPage, 'selectedDocuments', selectedDocuments)
  },
  [mt.SET_PAGE](state: AccountState, page: number) {
    Vue.set(state.documentsPage, 'page', page)
    Vue.set(
      state.documentsPage,
      'pageNumber',
      page * state.documentsPage.pageSize - state.documentsPage.pageSize,
    )
  },
  [mt.SET_PAGINATED_DOCUMENTS](
    state: AccountState,
    documents: Array<string>,
  ) {
    Vue.set(
      state.documentsPage.loadedPages,
      state.documentsPage.page,
      documents,
    )
  },
  [mt.SET_FILTER_START_DATE](
    state: AccountState,
    startDate: Date,
  ) {
    Vue.set(state.documentsPage, 'startDate', converters.prepareFormatDate(startDate))
  },
  [mt.SET_FILTER_END_DATE](state: AccountState, endDate: Date) {
    Vue.set(state.documentsPage, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.CLEAR_LOADED](state: AccountState) {
    Vue.set(state.documentsPage, 'loadedPages', {})
  },
  [mt.SET_TOTAL](state: AccountState, totalItems: number) {
    Vue.set(state.documentsPage, 'totalItems', totalItems)
  },
  [mt.FETCHING](state: AccountState) {
    state.documentsPage.fetching = true
  },
  [mt.FETCHED](state: AccountState) {
    state.documentsPage.fetching = false
  },
  [mt.SEARCH_CHANGED](state: AccountState, search: string) {
    Vue.set(state.documentsPage, 'search', `${search}`)
  },
  [mt.SET_CURRENT_USER](
    state: AccountState,
    currentUser: Account.App.Responses.User,
  ) {
    state.currentUser = currentUser
  },
  [mt.SET_WAREHOUSE_BLOCKED](
    state: AccountState,
    blockState: Account.App.Responses.WarehouseSettingsDto,
  ) {
    state.isWarehouseSelectionBlocked = !blockState.available
  },
  [mt.SET_REGISTRATION_SETTING](
    state: AccountState,
    option: Account.App.Responses.RegistrationSettings,
  ) {
    state.registrationSettings = option
  },
  [mt.CLEAR_DOCUMENTS_LIST](state: AccountState) {
    Vue.set(state, 'documentsPage', {
      pageNumber: 1,
      pageSize: 10,
      search: null,
      startDate: null,
      endDate: null,
      paymentFilter: null,
      sortingMode: null,
      documentTypes: [
        Documents.DocumentTypeEnum.INVOICE,
        Documents.DocumentTypeEnum.INVOICE_CORRECTION,
      ],
      sumsInvoice: null,
      allDocumentsSelected: false,
      fetching: false,
      page: 1,
      totalItems: 0,
      selectedDocuments: [],
      loadedPages: {},
    })
  },
  [mt.CLEAR_STORE](state: AccountState) {
    state.currentUser = null
    Vue.set(state, 'agreements', {})
    Vue.set(state, 'publicAgreements', {})
    Vue.set(state, 'client', {})
    Vue.set(state, 'clientAddresses', {})
    Vue.set(state, 'clientUsers', {})
    Vue.set(state, 'tradeCredit', {})
    Vue.set(state, 'documents', {})
    Vue.set(state, 'roles', [])
    Vue.set(state, 'documentsPage', {
      pageNumber: 1,
      pageSize: 10,
      search: null,
      startDate: null,
      endDate: null,
      paymentFilter: null,
      sortingMode: null,
      documentTypes: [
        Documents.DocumentTypeEnum.INVOICE,
        Documents.DocumentTypeEnum.INVOICE_CORRECTION,
      ],
      sumsInvoice: null,
      allDocumentsSelected: false,
      fetching: false,
      page: 1,
      totalItems: 0,
      selectedDocuments: [],
      loadedPages: {},
    })
  },
  [mt.SET_AUTHORITIES](
    state: AccountState,
    payload: Auth.Roles.Authorities,
  ) {
    state.authorities = payload
  },
  [mt.SET_ROLES](
    state: AccountState,
    payload: Array<Auth.Roles.Responses.Role>,
  ) {
    Vue.set(
      state,
      'roles',
      payload.map(x => ({ [x.id]: x })).reduce((x, y) => ({ ...x, ...y }), {}),
    )
  },
  [mt.SET_GOODS_ISSUED_NOTES](
    state: AccountState,
    data: GoodsIssuedNotes.Responses.GoodsIssuedNotePaginated,
  ) {
    state.goodsIssuedNotes = data
  },
  [mt.SET_START_DATE_GOODS_ISSUED_NOTES](
    state: AccountState,
    startDate: Date,
  ) {
    Vue.set(
      state.goodsIssuedNotesPage,
      'startDate',
      converters.prepareFormatDate(startDate),
    )
  },
  [mt.SET_END_DATE_GOODS_ISSUED_NOTES](
    state: AccountState,
    endDate: Date,
  ) {
    Vue.set(state.goodsIssuedNotesPage, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.SET_SEARCH_GOODS_ISSUED_NOTES](
    state: AccountState,
    search: string,
  ) {
    Vue.set(state.goodsIssuedNotesPage, 'search', search)
  },
  [mt.SET_SORT_GOODS_ISSUED_NOTES](
    state: AccountState,
    sort: string,
  ) {
    Vue.set(state.goodsIssuedNotesPage, 'sortOrder', sort)
  },
  [mt.SET_PAGE_GOODS_ISSUED_NOTES](
    state: AccountState,
    page: number,
  ) {
    Vue.set(state.goodsIssuedNotesPage, 'pageNumber', page)
  },
  [mt.SET_GOODS_ISSUED_NOTE](
    state: AccountState,
    payload: GoodsIssuedNotes.Responses.GoodsIssuedNote,
  ) {
    state.goodsIssuedNote = payload
  },
  [mt.SET_GUS_DATA](
    state: AccountState,
    payload: Account.App.Responses.BillingAddress,
  ) {
    state.gusData = payload
  },
  [mt.SET_GOODS_RECEIVED_NOTES](
    state: AccountState,
    data: Pagination.Pager<GoodsReceivedNoteRestView>,
  ) {
    state.goodsReceivedNotes = data
  },
  [mt.SET_START_DATE_GOODS_RECEIVED_NOTES](
    state: AccountState,
    startDate: Date,
  ) {
    Vue.set(
      state.goodsReceivedNotesPage,
      'startDate',
      converters.prepareFormatDate(startDate),
    )
  },
  [mt.SET_END_DATE_GOODS_RECEIVED_NOTES](
    state: AccountState,
    endDate: Date,
  ) {
    Vue.set(state.goodsReceivedNotesPage, 'endDate', converters.prepareFormatDate(endDate))
  },
  [mt.SET_SEARCH_GOODS_RECEIVED_NOTES](
    state: AccountState,
    search: string,
  ) {
    Vue.set(state.goodsReceivedNotesPage, 'search', search)
  },
  [mt.SET_SORT_GOODS_RECEIVED_NOTES](
    state: AccountState,
    sort: string,
  ) {
    Vue.set(state.goodsReceivedNotesPage, 'sortOrder', sort)
  },
  [mt.SET_PAGE_GOODS_RECEIVED_NOTES](
    state: AccountState,
    page: number,
  ) {
    Vue.set(state.goodsReceivedNotesPage, 'pageNumber', page)
  },
  [mt.SET_GOODS_RECEIVED_NOTE](
    state: AccountState,
    payload: GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView,
  ) {
    state.goodsReceivedNote = payload
  },
}
export default mutations

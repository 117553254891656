import { Account, Documents } from '@one/types'
import { AccountState } from './types'
import ClientType = Account.App.ClientType

export default {
  tradeCredit: (state: AccountState) => state.tradeCredit,
  getUserAboutId: (state: AccountState) => (
    idUser: string,
  ): Account.App.Responses.User | undefined =>
    state.clientUsers.items.find(
      (user: Account.App.Responses.User) => user.id === idUser,
    ),
  getBillingAddress: (state: AccountState) =>
    state.client.billingAddress || null,
  getCustomerType: (
    state: AccountState,
  ): Account.App.ClientType | null => state.client.customerType || null,
  isCompanyCustomerType: (state: AccountState) =>
    state.client?.customerType === ClientType.COMPANY,
  isIndividualCustomerType: (state: AccountState) =>
    state.client?.customerType === ClientType.INDIVIDUAL,
  getClient: (state: AccountState) => state.client || null,
  getDocument: (state: AccountState) => (
    documentNumber: string,
  ): Documents.DocumentDto | null => state.documents[documentNumber],
  isDocumentSelected: (state: AccountState) => (
    documentNumber: string,
  ) => state.documentsPage.selectedDocuments.includes(documentNumber),
  getRoleId: (state: AccountState) => (id: string) =>
    state.roles[id],
  getDefaultAddressId: (state: AccountState): string | null => {
    if (!state.clientAddresses.items) {
      return null
    }
    const defaultAddress = state.clientAddresses.items.find(
      elem => elem.isDefault,
    )
    return defaultAddress ? defaultAddress.id : null
  },
  getBillingAddressId: (state: AccountState) => {
    if (!state.clientAddresses.items) {
      return null
    }
    const billingAddress = state.clientAddresses.items.find(
      elem => elem.type === 'BILLING',
    )
    return billingAddress ? billingAddress.id : null
  },
  warehouseSelectionBlocked: (state: AccountState) =>
    state.isWarehouseSelectionBlocked,
  registrationSetting: (state: AccountState) =>
    state.registrationSettings,
  getAdditionalWarehousesIds: (
    state: AccountState,
  ): Array<string> =>
    (state.client && state.client.additionalWarehousesIds) || [],
  getAllWarehouses: (
    state: AccountState,
    getters: any,
    _rootState: any,
    rootGetters: any,
  ): Array<string> => {
    const defaultWarehouseClient: string =
      state.client.warehouseId || rootGetters['stocks/getCentralWarehouse'][0]
    const isNotCentralWarehouse: boolean =
      getters.getAdditionalWarehousesIds.length &&
      !getters.getAdditionalWarehousesIds.includes(defaultWarehouseClient)
    return isNotCentralWarehouse
      ? [...getters.getAdditionalWarehousesIds, defaultWarehouseClient]
      : getters.getAdditionalWarehousesIds
  },
  getGoodsIssueNotesList: (state: AccountState) =>
    state.goodsIssuedNotes && state.goodsIssuedNotes.items,
  getGoodsIssueNotesCount: (state: AccountState) =>
    state.goodsIssuedNotes && state.goodsIssuedNotes.totalItems,
  getGoodsReceivedNotesList: (state: AccountState) =>
    state.goodsReceivedNotes && state.goodsReceivedNotes.items,
  getGoodsReceivedNotesCount: (state: AccountState) =>
    state.goodsReceivedNotes && state.goodsReceivedNotes.totalItems,
  gusData: (
    state: AccountState,
  ): Account.App.Responses.BillingAddress => state.gusData,
}

import Vue from 'vue'
import IdleVue from 'idle-vue'

const IDLE_TIME = 30 * 60 * 1000 // in minutes
export default ({ store }) => {
  if (process.client) {
    const monkeyPatchedVuexStore = {
      commit: store.commit,
      state: store.state,
      registerModule() {
        // Dynamic module registration breaks Vue Dev Tools so we dont want it
      },
    }
    if (!IdleVue.installed) {
      Vue.use(IdleVue, {
        store: monkeyPatchedVuexStore,
        moduleName: 'idle',
        idleTime: IDLE_TIME,
      })
      IdleVue.installed = true
    }
  }
}

import en from '../lang/en-US'

export default (context) => {
  const { route, store, app } = context
  const messages = {
    ...en,
    ...store.state.i18n.messages,
  }

  if (route.meta && route.path !== '/search') {
    const crumbs = route.meta
      .filter(x => x.breadcrumb)
      .map(x => ({
        name: messages[`pages.${x.breadcrumb.name || x.breadcrumb}`],
        link: typeof x.breadcrumb.link === 'function'
? x.breadcrumb.link(context)
: app.localePath({
          path: x.breadcrumb.link,
        }),
      }))
    if (crumbs.length) { store.commit('layout/SET_BREADCRUMBS', crumbs) }
  }
}

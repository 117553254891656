import api, { Version } from '@one/api'
import { AuthService } from '@one/core'

/*
login: admin@admin.com
password: !234Qwer
 */
export default ({ app, store }, inject) => {
  let baseURL = app.$config.BASE_URL
  const baseSsrURL = app.$config.BASE_SSR_URL
  if (baseURL && baseURL.endsWith('v1')) {
    // Removing version from baseURL
    baseURL = baseURL.substr(0, baseURL.length - 3)
  }
  // This variable is only as a safe-checker -> use case is exceptional
  let isRefreshing = false
  const apiService = api.init({
    baseURL: (process.server && baseSsrURL ? baseSsrURL : baseURL) || 'http://localhost:8080',
    version: Version.V1,
    debug: false,
    timing: app.$config.TIMING_ENABLED,
    cacheEnabled: process.client,
    queueEnabled: process.client,
    getToken: () => store.state.auth.accessToken,
    isTokenValid: (token) => {
      return AuthService.isTokenValid(token)
    },
    triggerTokenRefresh: async () => {
      if (app.$auth && !isRefreshing) {
        isRefreshing = true
        const isRefreshed = await app.$auth.tryToRefreshAccessToken()
        if (isRefreshed) {
          apiService.flushQueue()
        }
        isRefreshing = false
      }
    },
    async onResponseError(error) {
      const response = error.response
      const currentDate = new Date().valueOf()

      const logInformation = {
        ssr: typeof window === 'undefined', // quick check if in node
        service: 'oneAPI config',
        method: response?.config?.method,
        requestId: response?.data?.requestId,
        status: response?.status,
        tenantKey: response?.config?.headers['one-tenant'],
        timestamp: currentDate,
        url: response?.config?.url,
        params: response?.config?.params,
        msg: response?.data?.message,
        errorCode: response?.data?.errorCode,
      }
      app.$logger.warn(logInformation)
      if (error.response &&
        (error.response.status === 401 || error.response.status === 403) &&
        error.response.data.code !== 'BAD_CREDENTIALS'
      ) {
        if (app.$auth) {
          try {
            const isRefreshed = await app.$auth.tryToRefreshAccessToken()
            if (isRefreshed) {
              const request = error.response.config || error.config
              if (request) {
                return this.resendRequest(request)
              }
            }
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
      return Promise.reject(error)
    },
    getTenantKey: () => app.$tenantConfig.tenantKey,
  })
  inject('api', apiService)
}

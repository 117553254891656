







  import { Vue, Component, Prop } from 'vue-property-decorator'

  @Component
  export default class OneBaseHomePage extends Vue {
    name: string = 'one-base-home-page';

    @Prop({
      type: String,
      required: false,
      default: 'b-row',
    })
    tag!: string

    @Prop({
      type: [String, Number],
      default: 'auto',
    })
    height!: string | number
  }

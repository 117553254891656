import Vue from 'vue'
import { Context } from '@nuxt/types'
import axios, { AxiosResponse } from 'axios'
import { CMS } from '@one/types'
import { EnsureClientSide } from '../../utils/clientSideDecorator'
import { initLogger } from '../../utils/qalogger'
import { Configuration } from '~/plugins/config/state/interfaces'
import PwaIcon = CMS.Responses.PwaIcon
const log = initLogger('PWA')

export class PwaManager {
  private ctx: Context;
  private healthCheckInterval: number = 60000
  private _isOnline: boolean = true;
  private healthCheckIntervalHandler: any;
  private refreshing: boolean = false;
  private swScope: string = '/';
  private swUrl: string = '/sw.js';
  private icons: Array<PwaIcon> = [];
  private iconsAvailable: boolean = false;
  private name: string | undefined;
  private shortName: string | undefined;
  private description: string | undefined;

  public workbox: any;
  public appleIconUrl: string | null = null

  constructor(context: Context) {
    this.ctx = context
  }

  _events: Vue = new Vue()

  public $on = this._events.$on
  public $off = this._events.$off
  private $once = this._events.$once
  private $emit = this._events.$emit

  public get isEnabled() {
    const isClient = typeof window !== 'undefined'
    const hasRequiredFields = !!this.name && !!this.shortName && !!this.description
    return isClient ? (this.iconsAvailable && window.isSecureContext && hasRequiredFields) : this.iconsAvailable && hasRequiredFields
  }

  public async init(configuration?: Configuration) {
    const config = configuration || this.ctx.store.state.cms.configuration
    this.icons = config.icons
    this.iconsAvailable = await PwaManager.assertIconsAvailable(this.icons)
    this.name = config.name
    this.shortName = config.shortName
    this.description = config.description
    if (this.isEnabled) {
      this.initServerSide()
      this.initClientSide()
    } else {
      this.unregisterServiceWorker()
    }
  }

  private initServerSide() {
    this.setAppleIcon()
  }

  @EnsureClientSide
  private initClientSide() {
    this.setHealthCheck()
    this.initWorkbox()
  }

  private setAppleIcon() {
    const icon: PwaIcon = this.icons.find((x: PwaIcon) => x.size === '512x512') || this.icons[0]
    log('Setting apple icon ', icon)
    this.appleIconUrl = icon.img
  }

  @EnsureClientSide
  private unregisterServiceWorker() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  }

  @EnsureClientSide
  private setHealthCheck(): void {
    clearInterval(this.healthCheckIntervalHandler)
    this.healthCheckIntervalHandler = setInterval(() => this.healthCheck(), this.healthCheckInterval)
  }

  private healthCheck(): void {
    // navigator.onLine is not reliable so we have to implement it on our own
    const xhr = new XMLHttpRequest()

    // Open new request as a HEAD to the root hostname with a random param to bust the cache
    xhr.open('OPTIONS', `/api/version?rand=${+Math.floor((1 + Math.random()) * 0x10000)}`, true)
    xhr.timeout = 5000
    xhr.onload = () => {
      if (xhr.readyState === 4) {
        this._isOnline = (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304))
        return
      }
      this._isOnline = false
    }
    xhr.onerror = () => {
      this._isOnline = false
    }
    xhr.send()
  }

  public static assertIconsAvailable = (icons: Array<PwaIcon>): Promise<boolean> => {
    const firstIcon = icons[0]
    if (!firstIcon) { return Promise.resolve(false) }
    return new Promise((resolve) => {
      try {
        axios.get(firstIcon.img, {
          timeout: 1000,
        }).then(
          (res: AxiosResponse<any>) => {
            resolve(res.status === 200 || res.status === 301)
          },
        ).catch(() => resolve(false))
      } catch (e) {
        return resolve(false)
      }
    })
  }

  @EnsureClientSide
  private initUpdateCycle() {
    navigator.serviceWorker.addEventListener('controllerchange',
       () => {
        if (this.refreshing) { return }
        this.refreshing = true
        window.location.reload()
      },
    )
  }

  public get isOnline(): boolean {
    return this._isOnline
  }

  @EnsureClientSide
  async register() {
    if (!('serviceWorker' in navigator)) {
      throw new Error('serviceWorker is not supported in current browser!')
    }
    log('Registering new workbox')
    // @ts-ignore
    const { Workbox } = await import('workbox-cdn/workbox/workbox-window.prod.es5.mjs')

    const workbox = new Workbox(this.swUrl, {
      scope: this.swScope,
    })

    const registration: ServiceWorkerRegistration = await workbox.register()
    if (registration.waiting) {
      this.promptUserToRefresh(registration)
    }
    if (navigator.serviceWorker.controller) {
      const that = this
      registration.addEventListener('updatefound', () => {
        log('Update found!')
        registration.installing!.addEventListener('statechange', function () {
          log('on state change', this.state)
          if (registration.waiting) {
            that.promptUserToRefresh(registration)
          }
        })
      })
      this.initUpdateCycle()
    }
    return workbox
  }

  @EnsureClientSide
  promptUserToRefresh(reg: ServiceWorkerRegistration) {
    log('prompting user for update')
    this.$emit('pwa:update:prompt', (accepted: boolean) => {
      if (accepted) {
        log('update accepted')
        if (reg.waiting) {
          log('posting skip message')
          reg.waiting.postMessage('SKIP_WAITING')
        }
      }
    })
  }

  @EnsureClientSide
  initWorkbox() {
    // @ts-ignore
    window.$workbox = this.register().catch(error => console.error('Error registering workbox:', error))
    // @ts-ignore
    this.workbox = window.$workbox
  }
}

export default async (ctx: Context, inject: Function) => {
  const manager: PwaManager = Vue.observable(new PwaManager(ctx))
  inject('pwa', manager)
  // @ts-ignore
  const config = ctx.req && ctx.req.configuration
  await manager.init(config)
}

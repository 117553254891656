const replaceNbsps = (str: string) => {
    const re = new RegExp(String.fromCharCode(160), 'g')
    return str.replace(re, ' ')
}
export function priceFormatter(price: number, currency = {
  iso: 'pl-PL',
  code: 'PLN',
  symbol: 'zł',
}) {
    if (!isNaN(price) && price !== null) {
        return replaceNbsps(
          price.toLocaleString(currency.iso, {
              style: 'currency',
              currency: currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }),
        )
    } else {
        console.warn(`[WARN - FRONT APP]: (price) value ${price} is NaN`)
    }
}
export function parcelLockerAddressFormatter(locker: any) {
    if (locker) {
        const { street, zipCode, city, country } = locker
        return locker && `${street}, ${zipCode}, ${city}, ${country}`
    }
    return ''
}

import { MutationTree } from 'vuex'
import { Categories } from '@one/types'

import Vue from 'vue'
import { CategoriesState } from './types'

export const mt = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  SET_SELECTED_CATEGORIES_TREE: 'SET_SELECTED_CATEGORIES_TREE',
  CLEAR_STORE: 'CLEAR_STORE',
  CLEAR_SELECTED_CATEGORY: 'CLEAR_SELECTED_CATEGORY',
}

export const mutations: MutationTree<CategoriesState> = {
  [mt.SET_CATEGORIES](
    state: CategoriesState,
    categories: Array<Categories.Category>,
  ) {
    state.orderCategories = categories.map((c: Categories.Category) => c.id)
    categories.forEach((category: Categories.Category) => {
      // @ts-ignore
      Vue.set(state.categories, category.id, Object.freeze(category))
      state.categoriesFetched = true
    })
  },
  [mt.SELECT_CATEGORY](state: CategoriesState, category: string | null) {
    state.selectedCategoryId = category
  },
  [mt.SET_SELECTED_CATEGORIES_TREE](
    state: CategoriesState,
    tree: Array<string>,
  ) {
    state.selectedCategoriesTree = tree
  },
  [mt.CLEAR_STORE](state: CategoriesState) {
    state.categoriesFetched = false
    state.selectedCategoryId = null
    Vue.set(state, 'categories', {})
  },
  [mt.CLEAR_SELECTED_CATEGORY](state: CategoriesState) {
    state.categoriesFetched = false
    state.selectedCategoryId = null
    state.selectedCategoriesTree = []
  },
}
export default mutations

import { Products, Stocks } from '@one/types'
import { converters } from '@one/core'
import { MappedProductStocks, StocksState } from './types'
import Warehouse = Stocks.Warehouse
import WarehouseStocks = Stocks.Responses.WarehouseStocks

export default {
  getProductStocks: (state: StocksState) => (
    id: string,
  ): MappedProductStocks | null => {
    return state.productsStocks[id] || null
  },
  getProductQuantityInWarehouse: (state: StocksState) => (
    id: string,
    warehouse: string,
  ) => {
    if (
      state.productsStocks[id] &&
      state.productsStocks[id].warehouses.byId[warehouse]
    ) {
      return state.productsStocks[id].warehouses.byId[warehouse].quantity
    }
    return null
  },
  getProductOrderQuantityInWarehouse: (
    state: StocksState,
    _getters: any,
    rootState: any,
  ) => (id: string, warehouse: string) => {
    if (
      state.productsStocks[id] &&
      state.productsStocks[id].warehouses.byId[warehouse]
    ) {
      const prod: Products.Product = rootState.products.products[id]
      const converter: number = prod?.measurementUnits?.packingQuantity || 1
      return Math.floor(
        +converters.toOrderUnit(
          state.productsStocks[id].warehouses.byId[warehouse].quantity,
          converter,
        ),
      )
    }
    return null
  },
  getProductWarehouse: (state: StocksState) => (
    id: string,
    warehouse: string,
  ): WarehouseStocks | null => {
    if (state.productsStocks[id]) {
      return state.productsStocks[id].warehouses.byId[warehouse]
    }
    return null
  },
  getWarehouses: (state: StocksState): Array<string> => state.warehouses.allIds,
  getWarehousesList: (
    state: StocksState,
    getters: any,
  ): Array<Stocks.Warehouse> =>
    getters.getWarehouses
      .map((id: string) => state.warehouses.byId[id])
      .sort((a: Stocks.Warehouse, b: Stocks.Warehouse) => {
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0
      }),
  getWarehouse: (state: StocksState) => (id: string): Warehouse | undefined =>
    state.warehouses.byId[id],
  getLocalWarehouses: (state: StocksState) => {
    return state.warehouses.allIds.filter(
      (id: string) =>
        // @ts-ignore
        state.warehouses.byId[id].type === 'LOCAL',
    )
  },
  getCentralWarehouse: (state: StocksState): Array<any> | undefined => {
    return state.warehouses.allIds.filter(
      (id: string) =>
        // @ts-ignore
        state.warehouses.byId[id].type === 'CENTRAL',
    )
  },
  getDefaultWarehouseId: (state: StocksState): string | null => {
    return state.defaultWarehouse
  },
  getDefaultWarehouse: (state: StocksState) => {
    return (
      state.defaultWarehouse && state.warehouses.byId[state.defaultWarehouse]
    )
  },
  hasWarehouses: (state: StocksState): boolean =>
    state.warehouses.allIds.length > 0,

  isEnoughProductWithBlockLabelInStock: (_: StocksState, getters: any) => (
    amount: number,
    labels: Array<string>,
    idProduct: string,
  ): boolean => {
    const parentWarehouseId: string | undefined =
      getters.getDefaultWarehouse && getters.getDefaultWarehouse.parent
    const centralWarehouseID: string | undefined =
      getters.getCentralWarehouse[0]
    const defaultWarehouseId: string | undefined = getters.getDefaultWarehouseId
    const parentWarehouseStock = getters.getProductOrderQuantityInWarehouse(
      idProduct,
      parentWarehouseId || centralWarehouseID,
    )
    const warehouseStock = getters.getProductOrderQuantityInWarehouse(
      idProduct,
      defaultWarehouseId,
    )
    if (labels.includes('Block')) {
      if (defaultWarehouseId !== centralWarehouseID) {
        return amount > parentWarehouseStock + warehouseStock
      }
      return amount > parentWarehouseStock
    }
    return false
  },
  sumProductQuantityInWarehouses: (_, getters: any) => (
    idProduct: string,
  ): number => {
    const parentWarehouseId: string | undefined =
      getters.getDefaultWarehouse && getters.getDefaultWarehouse.parent
    const centralWarehouseID: string | undefined =
      getters.getCentralWarehouse[0]
    const defaultWarehouseId: string | undefined = getters.getDefaultWarehouseId
    const parentWarehouseStock = getters.getProductOrderQuantityInWarehouse(
      idProduct,
      parentWarehouseId || centralWarehouseID,
    )
    const warehouseStock = getters.getProductOrderQuantityInWarehouse(
      idProduct,
      defaultWarehouseId,
    )
    if (defaultWarehouseId !== centralWarehouseID) {
      return parentWarehouseStock + warehouseStock
    }
    return parentWarehouseStock
  },
}

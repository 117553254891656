import { ActionContext, ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { Wishlist } from '@one/types/dist/orderpath/app'
import { RootState } from '../index'
import { mt } from './mutations'
import { WishlistState } from './types'
import WishlistId = Wishlist.Responses.WishlistId

export const actions: ActionTree<WishlistState, RootState> = {
  fetchWishlists({ commit }: ActionContext<WishlistState, RootState>) {
    return this.$api.orderpath.app
      .getWishlists()
      .then(
        (success: AxiosResponse<Array<Wishlist.Responses.BasicWishlist>>) => {
          commit(mt.SET_BASIC_WISHLISTS, success.data)
          return success.data
        },
      )
  },
  async selectWishlist(
    { commit, dispatch }: ActionContext<WishlistState, RootState>,
    wishlistId: string,
  ) {
    const { app }: any = this
    if (!wishlistId) {
      return Promise.reject(
        Error('wishlistId cannot be null or undefined when selecting'),
      )
    }
    try {
      const fetchedWishlist: Wishlist.Responses.Wishlist = await dispatch(
        'fetchWishlist',
        wishlistId,
      )
      commit(mt.SET_CURRENT_WISHLIST, fetchedWishlist.id)
      return Promise.resolve(fetchedWishlist.id)
    } catch (e) {
      app.$logger.warn(e)
      return Promise.resolve(null)
    }
  },
  fetchWishlist(
    { commit, rootState }: ActionContext<WishlistState, RootState>,
    wishlistId: string,
  ) {
    return this.$api.orderpath.app
      .getWishlist(wishlistId, rootState.stocks.defaultWarehouse)
      .then((success: AxiosResponse<Wishlist.Responses.Wishlist>) => {
        const response: Wishlist.Responses.Wishlist = success.data
        commit(mt.SET_WISHLIST, response)
        return response
      })
  },
  renameWishlist(
    { commit }: ActionContext<WishlistState, RootState>,
    { wishlistId, name }: { wishlistId: string; name: string },
  ) {
    return this.$api.orderpath.app
      .renameWishlist(wishlistId, {
        newName: name,
      })
      .then(() => {
        commit(mt.RENAME_WISHLIST, {
          wishlistId,
          name,
        })
        return Promise.resolve()
      })
  },
  createWishlist(_, name: string) {
    return this.$api.orderpath.app
      .createWishlist({
        name,
      })
      .then(
        (success: AxiosResponse<WishlistId>) => success.data,
      )
  },
  addProductToWishlist(
    { dispatch }: ActionContext<WishlistState, RootState>,
    { wishlistId, newProduct },
  ) {
    return this.$api.orderpath.app
      .addProductsToWishlist(wishlistId, newProduct)
      .then(() => dispatch('fetchWishlist', wishlistId))
  },

  changeWishlistProductOrder(
    { commit, dispatch }: ActionContext<WishlistState, RootState>,
    {
      wishlistId,
      newOrder,
    }: {
      wishlistId: string
      newOrder: Wishlist.Requests.ChangeOrderOfProductsOption
    },
  ) {
    return this.$api.orderpath.app
      .changeWishlistProductOrder(wishlistId, newOrder)
      .then(async () => {
        await dispatch('fetchWishlist', wishlistId)
        commit(mt.CHANGE_ITEMS_ORDERING, {
          wishlistId,
          ordering: newOrder.productIds,
        })
      })
  },
  fetchAnonymousWishlist(
    { commit, rootState }: ActionContext<WishlistState, RootState>,
    wishlistId: string,
  ): Promise<Wishlist.Responses.Wishlist | null> {
    if (!wishlistId) {
      throw new Error('Trying to fetchWishlist with NULL wishlistId')
    }
    if (!rootState.stocks.defaultWarehouse) {
      throw new Error(
        `Trying to fetchWishlist ${wishlistId} with NULL defaultWarehouse`,
      )
    }
    const { app }: any = this
    app.wait.start(`wishlist-${wishlistId}-fetching`)
    const warehouseId: string = rootState.stocks.defaultWarehouse

    return this.$api.orderpath.app
      .getAnonymousWishlist(wishlistId, warehouseId)
      .then((success: AxiosResponse<Wishlist.Responses.Wishlist>) => {
        const wishlist: Wishlist.Responses.Wishlist = success.data
        // commit(mt.SET_WISHLIST, {
        //   wishlist,
        //   contextWarehouseId: warehouseId
        // })
        // FIXME: Tu chyba jest błąd że nie jest zapisywany kontekst magazynu - ZHA
        commit(mt.SET_WISHLIST, wishlist)
        return Promise.resolve(wishlist)
      })
      .catch(() => null)
      .finally(() => app.wait.end(`wishlist-${wishlistId}-fetching`))
  },
  removeAnonymousCart(
    _: ActionContext<WishlistState, RootState>,
    wishlistId: string,
  ) {
    return this.$api.orderpath.app.removeAnonymousWishlist(wishlistId)
  },
  removeWishlist(
    { commit }: ActionContext<WishlistState, RootState>,
    wishlistId: string,
  ) {
    return this.$api.orderpath.app.removeWishlist(wishlistId).then(() => {
      commit(mt.REMOVE_WISHLIST, wishlistId)
    })
  },
  removeProductFromWishlist(
    { dispatch }: ActionContext<WishlistState, RootState>,
    {
      wishlistId,
      productDataObject,
    }: {
      wishlistId: string
      productDataObject: Wishlist.Requests.RemoveProductFromWithListOption
    },
  ) {
    return this.$api.orderpath.app
      .removeWishlistProduct(wishlistId, productDataObject)
      .then(async () => {
        await dispatch('fetchWishlist', wishlistId)
        return Promise.resolve()
      })
  },
  copyWishlistToCart(
    _: ActionContext<WishlistState, RootState>,
    { wishlistId, cartId },
  ) {
    return this.$api.orderpath.app.copyToCart(wishlistId, cartId)
  },
  takeoverWishlist(
    _,
    wishlistId: string,
  ): Promise<WishlistId> {
    if (!wishlistId) {
      return Promise.reject(
        Error('wishlistId cannot be null or undefined when takeover'),
      )
    }

    return this.$api.orderpath.app
      .takeoverWishlist(wishlistId)
      .then(
        (success: AxiosResponse<WishlistId>) => success.data,
      )
  },
}
export default actions

export const initLogger = (scope: string): Function => {
  return (...args: any) => {
    let isUnityOrLocalEnv: boolean = false
    if (typeof window !== 'undefined') {
      isUnityOrLocalEnv = window.location.href.includes('one.unity.pl') || window.location.href.includes('.local')
    }
    // eslint-disable-next-line no-console
    return isUnityOrLocalEnv ? console.log(`[${scope}]`, ...args) : () => ({})
  }
}

import { Module } from 'vuex'

export const idleState: Module<any, any> = {
  namespaced: true,
  state: () => ({
    isIdle: false,
  }),
  mutations: {
    IDLE_CHANGED: (state, isIdle) => {
      state.isIdle = isIdle
    },
  },
}

export default idleState

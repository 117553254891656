import {
  Checkout,
  Orders,
  PaymentMethod,
  Rfq,
  Pricing,
} from '@one/types'
import {
  Cart,
  OrderConfirmation,
  Shared,
} from '@one/types/dist/orderpath/app'
import { CartState, DeliveryMethodsMapping, MappedCartObject } from './types'

import OrderConfirmationCart = OrderConfirmation.Responses.OrderConfirmationCart
import { getProductKeyAttribute } from '~/store/cart/utils'

export interface CartProductPrice {
  price: number,
  catalogPrice: number,
  discount: number,
  tax: number,
  line: number,
  lineTax: number,
  unit: number,
  unitTax: number,
  total: number,
  additionalCosts: Array<{ type: string, unit: number, total: number }>,
}
export default {
  getAdditionalCostName: (state: CartState) => (type: string) => {
    return state.additionalCostTypes[type]
  },
  getBasicCarts: (state: CartState) => state.basicCarts,
  getCarts: (state: CartState) => state.carts,
  getCurrentCart: (state: CartState): MappedCartObject | null =>
    state.selectedCart && state.carts[state.selectedCart]
      ? state.carts[state.selectedCart].cartObject
      : null,
  getCurrentCartId: (state: CartState): string | null =>
    state.selectedCart,
  getCurrentCredit: (state: CartState) => (
    paymentMethodId: string,
  ) => {
    const cart = state.selectedCart && state.carts[state.selectedCart]
    if (cart) {
      return cart?.paymentMethods?.methods[paymentMethodId]?.amount
    }
    return undefined
  },

  getCartItemsCount: (state: CartState) => (cartId: string) => {
    if (state.carts[cartId]) {
      return state.carts[cartId].cartObject.products.size
    } else if (state.basicCarts[cartId]) {
      return state.basicCarts[cartId].numberOfProductRows
    }
    return null
  },
  getCartTotalPrice: (state: CartState) => (
    cartId: string,
    priceGross: boolean = false,
  ) => {
    const cart = state.carts[cartId]
    const basicCart = state.basicCarts[cartId]
    if (cart) {
      return priceGross
        ? cart.cartObject.prices?.productsGross
        : cart.cartObject.prices?.productsNet
    } else if (basicCart) {
      return priceGross ? basicCart.price?.gross : basicCart.price?.net
    }
    return null
  },
  getBasicCartById: (state: CartState) => (
    id: string,
  ): Cart.Responses.BasicCartInfo | null =>
    state.basicCarts[id] ? state.basicCarts[id] : null,
  getCartById: (state: CartState) => (
    id: string,
  ): MappedCartObject | null =>
    state.carts[id] ? state.carts[id].cartObject : null,
  getCartProducts: (state: CartState) => (
    cartId: string,
  ): Map<string, Shared.ProductInCart> | null =>
    state.carts[cartId] ? state.carts[cartId].cartObject.products : null,
  getCartParentProductsKeys: (state: CartState) => (cartId: string) => {
    if (!state.carts[cartId]) { return [] }
    const products = state.carts[cartId].cartObject.products
    return Array.from(products.values()).filter((product) => {
      return product.parent === null
    }).map((product) => {
      const attr = getProductKeyAttribute(product)
      return product[attr]
    })
  },
  getCartProductsKeys: (state: CartState) => (cartId: string) =>
    state.carts[cartId]
      ? Array.from(state.carts[cartId].cartObject.products.keys())
      : [],
  getTotalProductCountInCart: (state: CartState) => (
    id: string,
    cartId: string,
  ) => {
    const product = state.carts[cartId]
      ? state.carts[cartId].cartObject.products.get(id)
      : null
    if (!product) { return null }
    return product.shipments
      .map(shipment => shipment.numberOfProducts)
      .reduce((x, y) => x + y)
  },
  getCartProduct: (state: CartState) => (cartId: string) => (
    productId: string,
  ) =>
    state.carts[cartId] &&
    state.carts[cartId].cartObject.products.has(productId)
      ? state.carts[cartId].cartObject.products.get(productId)
      : null,
  getCartProductPrice: () => (
    prices: Shared.ProductPrices & Checkout.CheckoutProductPrice,
    priceGross: boolean = false,
  ): CartProductPrice | null => {
    const additionalCosts = (
      additionalCosts: Array<Shared.ProductAdditionalCost>,
      isGross: boolean,
    ) => {
      if (!prices.additionalCosts) { return [] }
      return additionalCosts.map((elem: Shared.ProductAdditionalCost) => {
        return {
          type: elem.type,
          unit: isGross ? elem.contentUnitGross : elem.contentUnitNet,
          total: isGross ? elem.totalGross : elem.totalNet,
        }
      })
    }
    if (!prices) { return null }
    if (priceGross) {
      return {
        price: prices.gross!,
        catalogPrice: prices.basePriceGross!,
        discount: prices.basePriceDiscount!,
        tax: prices.tax,
        line: prices.lineGross,
        lineTax: prices.lineTax,
        unit: prices.unitGross,
        unitTax: prices.unitTax,
        total: prices.totalGross,
        additionalCosts: additionalCosts(prices.additionalCosts, true),
      }
    } else {
      return {
        price: prices.net!,
        catalogPrice: prices.basePriceNet!,
        discount: prices.basePriceDiscount!,
        tax: prices.tax,
        line: prices.lineNet,
        lineTax: prices.lineTax,
        unit: prices.unitNet,
        unitTax: prices.unitTax,
        total: prices.totalNet,
        additionalCosts: additionalCosts(prices.additionalCosts, false),
      }
    }
  },
  getPrices: () => (
    prices: Shared.CartPrices & OrderConfirmation.OrderConfirmationCartPrice,
    priceGross: boolean = false,
  ) => {
    const additionalCosts = (
      additionalCosts: Array<Pricing.AdditionalCost>,
      isGross: boolean,
    ) => {
      if (!prices.additionalCosts) { return null }
      return additionalCosts.map((elem: Pricing.AdditionalCost) => {
        return {
          type: elem.type,
          total: isGross ? elem.gross : elem.net,
        }
      })
    }
    if (!prices) { return null }
    if (priceGross) {
      return {
        products: prices.productsGross,
        shipment: (prices.deliveryTypesGross || prices.deliveryGross) ?? 0,
        total: prices.totalGross,
        tax: prices.totalTax,
        payment: prices.paymentGross,
        additionalCosts: additionalCosts(prices.additionalCosts, true),
      }
    } else {
      return {
        products: prices.productsNet,
        shipment: (prices.deliveryTypesNet || prices.deliveryNet) ?? 0,
        total: prices.totalNet,
        tax: null,
        payment: prices.paymentNet,
        additionalCosts: additionalCosts(prices.additionalCosts, false),
      }
    }
  },
  getShipmentPrices: () => (
    obj: Shared.Shipment,
    priceGross: boolean = false,
  ) => {
    const prices = obj && obj.prices
    if (!prices) { return null }
    if (priceGross) {
      return {
        delivery: prices.deliveryMethodGross,
        productsAndDelivery: prices.productsAndDeliveryMethodGross,
        products: prices.productsGross,
      }
    } else {
      return {
        delivery: prices.deliveryMethodNet,
        productsAndDelivery: prices.productsAndDeliveryMethodNet,
        products: prices.productsNet,
      }
    }
  },
  getFirstAvailablePaymentMethod: (state: CartState) => (
    cartId: string,
  ): string | null => {
    if (
      state.carts[cartId] &&
      state.carts[cartId] &&
      state.carts[cartId].paymentMethods !== null
    ) {
      const paymentMethodsIds: Array<string> = Object.keys(
        state.carts[cartId].paymentMethods!.methods,
      )
      return paymentMethodsIds.length ? paymentMethodsIds[0] : null
    }
    return null
  },
  getPaymentMethodById: (state: CartState) => (
    cartId: string,
    id: string,
  ): PaymentMethod | null => {
    if (
      state.carts[cartId] &&
      state.carts[cartId] &&
      state.carts[cartId].paymentMethods !== null
    ) {
      return state.carts[cartId].paymentMethods!.methods[id]
    }
    return null
  },
  getPaymentMethodsArray: (
    state: CartState,
  ): Array<PaymentMethod> | null => {
    if (
      state.selectedCart &&
      state.carts[state.selectedCart] &&
      state.carts[state.selectedCart].paymentMethods
    ) {
      return Object.values(
        state.carts[state.selectedCart].paymentMethods!.methods,
      )
    }
    return []
  },
  getSelectedPaymentMethod: (
    state: CartState,
  ): PaymentMethod | null => {
    if (state.selectedCart) {
      const paymentMethodId =
        state.carts[state.selectedCart] &&
        state.carts[state.selectedCart].cartObject.paymentMethodId
      if (paymentMethodId && state.carts[state.selectedCart].paymentMethods) {
 return state.carts[state.selectedCart].paymentMethods!.methods[
          paymentMethodId
        ]
}
    }
    return null
  },
  getDeliveryMethods: (state: CartState) => (
    cartId: string,
    shipmentId: string,
  ): DeliveryMethodsMapping | null => {
    return (
      state.carts[cartId] &&
      state.carts[cartId].deliveryMethods &&
      state.carts[cartId].deliveryMethods!.shippingMethodsMapping &&
      state.carts[cartId].deliveryMethods!.shippingMethodsMapping[shipmentId]
    )
  },
  getFetchingDeliveryMethodsStatus: (
    state: CartState,
  ): boolean | null => state.fetchingDeliveryMethods,
  getProductsPerShipment: (state: CartState) => (
    cartId: string,
    shipmentId: string,
  ): Array<string> | null => {
    return state.carts[cartId]
      ? Array.from(state.carts[cartId].cartObject.products.values())
          .filter((product: Shared.ProductInCart) => {
            return product.shipments.find((shipment: any) => {
              return shipment.shipmentId === shipmentId
            })
          })
          .map((product: Shared.ProductInCart) => {
            const key = getProductKeyAttribute(product)
            return product[key]
          })
      : null
  },
  isAllShipmentsValid: (state: CartState): boolean => {
    return Object.values(state.shipmentsValidity).every((valid: boolean) => valid)
  },
  isProductSelected: (state: CartState) => (
    productId: string,
  ): boolean => {
    if (!state.selectedCart) {
      return false
    }
    const selectedIds =
      state.carts[state.selectedCart].cartObject.selectedProducts ?? []
    if (selectedIds.length) {
      return !!selectedIds.find((product) => {
        const key = getProductKeyAttribute(product)
        return product[key] === productId
      })
    }
    return false
  },
  getSelectedProducts: (
    state: CartState,
  ): Array<Shared.ProductId> => {
    if (!state.selectedCart) {
      return []
    }
    return state.carts[state.selectedCart].cartObject.selectedProducts ?? []
  },
  getShipments: (state: CartState) => (
    cartId: string,
  ): Array<Shared.Shipment> => {
    return state.carts[cartId]
      ? Object.values(state.carts[cartId].cartObject.shipments)
      : []
  },
  getShipmentById: (state: CartState) => (
    cartId: string,
    shipmentId: string,
  ): Shared.Shipment | null => {
    return state.carts[cartId]
      ? state.carts[cartId].cartObject.shipments[shipmentId]
      : null
  },
  getCheckoutByCartId: (state: CartState) => (
    cartId: string,
  ): string | null =>
    state.cartsCheckouts[cartId] ? state.cartsCheckouts[cartId] : null,
  getCheckout: (state: CartState) => (
    checkoutId: string,
  ): Checkout.Checkout | null => state.checkouts[checkoutId],
  getOrderConfirmationsForCart: (state: CartState) => (
    cartId: string,
  ): string | null => state.cartsOrderConfirmations[cartId],
  getOrderConfirmationById: (state: CartState) => (
    confId: string,
  ): OrderConfirmationCart | null => state.orderConfirmations[confId],
  getOrder: (state: CartState) => (
    orderNumber: string,
  ): Orders.Order | undefined =>
    state.orders && (state.orders[orderNumber] as Orders.Order),
  getDetailOrder: (state: CartState) => (
    orderId: string,
  ): Orders.DetailedOrder.Order | undefined =>
    state.ordersDetail &&
    (state.ordersDetail[orderId] as Orders.DetailedOrder.Order),
  getOrders: (state: CartState) =>
    Object.keys(state.orders).map((x: string) => state.orders[x]),
  getSelectedOrder: (state: CartState): string | null =>
    state.selectedOrder,
  getRfqOrders: (state: CartState) =>
    Object.keys(state.rfqs).map((x: string) => state.rfqs[x]),
  getRfqDetail: (state: CartState) => (
    orderId: string,
  ): Rfq.RfqDetailed.DetailedRfqCart => state.rfqsDetail[orderId],
  getRfqOrder: (state: CartState) => (
    orderNumber: string,
  ): Rfq.RfqCart | undefined => state.rfqs[orderNumber],
  getRfqCartId: (state: CartState) => (rfqNegotiationId: string) =>
    state.rfqs[rfqNegotiationId] && state.rfqs[rfqNegotiationId].rfqCartId,
  getCartsForAuthorization: (state: CartState) =>
    Object.values(state.basicCarts).filter(
      (cart: Cart.Responses.BasicCartInfo) => !!cart.originalUser,
    ),
  getOfflineOrderDetail: (state: CartState) => (
    orderId: string,
  ): Orders.OfflineOrder | undefined =>
    state.offlineOrdersDetail &&
    (state.offlineOrdersDetail[orderId] as Orders.OfflineOrder),
  getOfflineOrder: (state: CartState) => (
    orderNumber: string,
  ): Orders.OfflineOrder | undefined =>
    state.offlineOrders &&
    (state.offlineOrders[orderNumber] as Orders.OfflineOrder),
  getOrderStatuses: (state: CartState) =>
    Object.values(state.orderStatuses),
  declaredPromoPoints: (state) => (poolId: string) => {
    return state.carts[state.selectedCart].cartObject?.declaredPromoPoints?.find((item) =>
      item.promoPoolId === poolId)?.usedPoints
  },
  appliedPromoPoints: (state) => (poolId: string) => {
    return state.carts[state.selectedCart].cartObject?.appliedPromoPoints?.find((item) =>
      item.promoPoolId === poolId)?.usedPoints
  },
  isManualPromotionsListCollapsed: (state: CartState) => state.isManualPromotionsListCollapsed,
}


  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { CreateElement, VNode, RenderContext } from 'vue'
  import { DefaultProps } from 'vue/types/options'

  @Component({
    // @ts-ignore
    functional: true,
  })
  export default class OneLink extends Vue {
    @Prop({
      type: String,
      default: '#',
    })
    to!: string

    render(createElement: CreateElement, context: RenderContext<DefaultProps>): VNode {
      if (!context.parent.$routes.isExternalUrl(context.props.to)) {
        return createElement(
          'nuxt-link',
          {
            staticClass: context.data.staticClass,
            class: ['one-link one-link--local', context.data.class],
            props: {
              to: context.props.to,
            },
            on: context.listeners,
            nativeOn: { ...context.listeners, ...context.data.nativeOn },
          },
          context.children,
        )
      } else {
        return createElement(
          'a',
          {
            staticClass: context.data.staticClass,
            class: ['one-link one-link--external', context.data.class],
            attrs: {
              href: context.props.to,
            },
            on: context.listeners,
            nativeOn: context.data.nativeOn,
          },
          context.children,
        )
      }
    }
  }

import { ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

import { AccessMode, Configuration, State } from './interfaces'

class ConfigState implements State {
  configuration!: Configuration | null
  tenantKey!: string | null
  isDev!: boolean | null
  currency!: string | null
  accessMode!: AccessMode | null
  isRegistrationEnabled!: boolean | null
  isB2CRegistrationEnabled!: boolean | null
  showProductId!: boolean | null
  showGoodsIssuedNotes!: boolean | null

  public static create(): ConfigState {
    return {
      configuration: null,
      tenantKey: null,
      currency: null,
      accessMode: null,
      isRegistrationEnabled: null,
      isB2CRegistrationEnabled: null,
      showProductId: null,
      showGoodsIssuedNotes: null,
    } as ConfigState
  }
}

export default class ConfigModule implements Module<State, any> {
  namespaced: boolean = true
  state = () => ConfigState.create()

  getters: GetterTree<State, any>
  actions: ActionTree<State, any>
  mutations: MutationTree<State>

  constructor() {
    this.getters = getters
    this.actions = actions
    this.mutations = mutations
  }

  public static register(store: Store<any>, path?: string): void {
    store.registerModule<State>(path || 'config', new ConfigModule(), {
      preserveState: !!store.state[path || 'config'],
    })
  }
}

import { Module } from 'vuex'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'
import { StocksState } from '~/store/stocks/types'
import { RootState } from '~/store'

export const state = (): StocksState => ({
  warehouses: {
    byId: {},
    allIds: [],
  },
  productsStocks: {},
  defaultUserWarehouse: null,
  defaultWarehouse: null,
})

const namespaced: boolean = true

const stocks: Module<StocksState, RootState> = {
  namespaced,
  actions,
  state,
  getters,
  mutations,
}

export default stocks

import { ActionContext, ActionTree } from 'vuex'
import { RMA } from '@one/types'
import { AxiosPromise, AxiosResponse } from 'axios'
import { RootState } from '../index'
import { mt } from './mutations'
import { RMAState } from './types'

export const actions: ActionTree<RMAState, RootState> = {
  fetchComplaintReasons({ commit }: ActionContext<RMAState, RootState>) {
    return this.$api.rma.app
      .getComplaintReasons()
      .then(
        ({
          data,
        }: AxiosResponse<Array<RMA.App.Response.ComplaintReasonDto>>) => {
          commit(mt.SET_COMPLAINT_REASONS, data)
          return Promise.resolve(data)
        },
      )
  },

  createRMA(_, data: RMA.App.Request.ComplaintFormDto) {
    return this.$api.rma.app.createComplaint(data).then(
      (res: any): AxiosPromise<void> => {
        return Promise.resolve(res)
      },
    )
  },

  fetchComplaintsList({
    state,
    commit,
  }: ActionContext<RMAState, RootState>) {
    const {
      offset,
      rows,
    }: RMA.App.Response.PaginationComplaint = state.complaintsList

    return this.$api.rma.app
      .getComplaints(offset, rows)
      .then(
        ({ data }: AxiosResponse<RMA.App.Response.PaginationComplaintDto>) => {
          const complaintsIds = data.elements.map(
            (orders: RMA.App.Response.ComplaintDto) => orders.id,
          )
          commit(mt.SET_PAGINATED_COMPLAINTS, complaintsIds)
          commit(mt.SET_COMPLAINTS, data.elements)
          commit(mt.SET_COMPLAINTS_TOTAL, data.elementsAmount)
          return Promise.resolve(data)
        },
      )
  },

  fetchPaginatedComplaintsList({
    state,
    dispatch,
  }: ActionContext<RMAState, RootState>) {
    const {
      page,
      loadedPages,
    }: RMA.App.Response.PaginationComplaint = state.complaintsList
    if (!(page in loadedPages)) {
      return dispatch('fetchComplaintsList')
    }
    return Promise.resolve()
  },

  fetchSingleComplaint(
    { commit }: ActionContext<RMAState, RootState>,
    complaintId: string,
  ) {
    return this.$api.rma.app
      .getComplaint(complaintId)
      .then(({ data }: AxiosResponse<RMA.App.Response.ComplaintDto>) => {
        commit(mt.SET_COMPLAINT_DETAIL, data)
        commit(mt.SET_COMPLAINTS, [data])
        return Promise.resolve(data)
      })
  },

  changePageComplaints(
    { commit, dispatch }: ActionContext<RMAState, RootState>,
    page: number,
  ) {
    commit(mt.SET_PAGE_COMPLAINTS, page)
    return dispatch('fetchPaginatedComplaintsList')
  },
}
export default actions

import { ActionContext, Module } from 'vuex'
import { CMS } from '@one/types'
import {
  Cart,
  Wishlist,
} from '@one/types/dist/orderpath/app'
import { AccessMode } from '~/plugins/config/state/interfaces'
import WishlistId = Wishlist.Responses.WishlistId
import BasicWishlist = Wishlist.Responses.BasicWishlist;

// TODO: Remove this type when BE will create config service
export interface TenantConfiguration extends CMS.Responses.MainConfigurationQuery {
  tenantKey: string,
  accessMode: AccessMode,
  isDev: boolean
}
export interface RootState {
  [key: string]: any
}
export const strict = false
// eslint-disable-next-line no-console
const log = process.env.NODE_ENV === 'production' ? () => ({}) : console.log
export const initState: Module<any, any> = {
  namespaced: true,
  state: () => ({
    tenantKey: null,
    isAppLoaded: false,
    isOrderpathLoaded: false,
  }),
  getters: {
    tenantKey: state => state.tenantKey,
  },
  mutations: {
    SET_TENANT_KEY: (state, tenantKey) => {
      state.tenantKey = tenantKey
    },
    SET_APP_LOADED: (state) => {
      state.isAppLoaded = true
    },
    SET_ORDERPATH_LOADED: (state, isLoaded) => {
      state.isOrderpathLoaded = isLoaded
    },
  },
  actions: {
    clearStore({ commit }: any) {
      commit('account/CLEAR_STORE')
      commit('cart/CLEAR_STORE')
      commit('products/CLEAR_STORE')
      commit('wishlist/CLEAR_STORE')
      commit('stocks/CLEAR_STORE')
      commit('rma/CLEAR_STORE')
      commit('plugins/CLEAR_STORE')
      commit('layout/SET_PRICE_GROSS', false)
    },
    initialPromiseWrapper(
      { dispatch }: ActionContext<any, any>,
      data: any,
    ): Promise<[string, boolean|Error]> {
      const initialWaitString = `initial-${data.waitString}-loading`

      if (this.app.wait.initialized) {
        this.app.wait.start(initialWaitString)
      }
      return dispatch(data.action, data.payload)
        .then((): [string, boolean] => [data.waitString, true])
        .catch((e): [string, Error] => [data.waitString, e])
        .finally(() => {
          if (this.app.wait.initialized) {
            this.app.wait.end(initialWaitString)
          }
        })
    },
    async fetchRequiredData({ dispatch }: ActionContext<any, any>) {
      return await Promise.all([
        dispatch('initialPromiseWrapper', { action: 'account/getWarehouseStatus', waitString: 'stocks-status' }),
        dispatch('initialPromiseWrapper', { action: 'plugins/fetchPlugins', waitString: 'plugins-status' }),
        dispatch('initialPromiseWrapper', { action: 'cms/fetchStaticPages', waitString: 'static-pages' }),
        dispatch('initialPromiseWrapper', { action: 'categories/fetchCategories', waitString: 'categories' }),
        dispatch('initialPromiseWrapper', { action: 'stocks/fetchWarehouses', waitString: 'warehouses' }),
        dispatch('initialPromiseWrapper', { action: 'products/fetchDefaultSortOptions', waitString: 'products' }),
        dispatch('initialPromiseWrapper', { action: 'products/fetchProductsDisplayMode', waitString: 'products' }),
      ])
    },
    async fetchAuthenticatedUserData({ dispatch, commit, state }: ActionContext<any, any>) {
      const res = await Promise.all([
        dispatch('initialPromiseWrapper', { action: 'categories/fetchCategories', waitString: 'categories' }),
        dispatch('initialPromiseWrapper', { action: 'account/getAuthorities', waitString: 'authorities' }),
        dispatch('initialPromiseWrapper', { action: 'account/fetchGetCurrentUser', waitString: 'current-user' }),
        dispatch('initialPromiseWrapper', { action: 'account/getRoles', waitString: 'roles' }),
        dispatch('initialPromiseWrapper', { action: 'account/fetchClient', waitString: 'current-client' }),
      ])
      commit('layout/SET_PRICE_GROSS', state.account.client.customerType === 'INDIVIDUAL' ||
        state.layout.shouldSetGrossAsDefaultValue)
      return res
    },
    async fetchHeavyData({ commit, rootState }: ActionContext<any, any>) {
      // this works good, but need cleanup and refactor
      const ANONYMOUS_USER_ID: string = 'one-anonymous'
      commit('SET_ORDERPATH_LOADED', false)
      const getWishlistId = (key: string) :string | null => {
        try {
          const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
          return userConfig.wishlistId
        } catch (e) {
          return null
        }
      }
      const getCartId = (key: string): string | null => {
        try {
          const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
          return userConfig.cartId
        } catch (e) {
          return null
        }
      }
      const setWishlistId = (key: string, id: string) => {
        const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
        userConfig.wishlistId = id
        localStorage?.setItem(key, JSON.stringify(userConfig))
      }
      const setCartId = (key: string, id: string) => {
        const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
        userConfig.cartId = id
        localStorage?.setItem(key, JSON.stringify(userConfig))
      }
      const clearCartId = (key: string) => {
        const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
        delete userConfig.cartId
        localStorage?.setItem(key, JSON.stringify(userConfig))
      }
      const clearWishlistId = (key: string) => {
        const userConfig = JSON.parse(localStorage?.getItem(key) || '{}')
        delete userConfig.wishlistId
        localStorage?.setItem(key, JSON.stringify(userConfig))
      }

      const tryToSelectCartFromBasicCarts = async (userId: string): Promise<boolean> => {
        const basicCarts: Array<Cart.Responses.BasicCartInfo> = await this.$orderpath.cart.fetchCarts()
        if (basicCarts.length) {
          log('User basic carts: exists')
          const firstCartSelected: string | null = await this.$orderpath.cart.selectCart(basicCarts[0].id)
          if (firstCartSelected === null) {
            return false
          }
          log(`Cart ${basicCarts[0].id} choosed from basicCarts`)
          setCartId(userId, basicCarts[0].id)
          return true
        }
        return false
      }
      const tryToFetchUserSavedCart = async (userId: string) => {
        const userSavedCartId: string | null = getCartId(userId)
        if (userSavedCartId) {
          log('User cart saved: true')
          log(`Selecting user cart ${userSavedCartId}`)
          const selectedCartId: string | null = await this.$orderpath.cart.selectCart(userSavedCartId)
          if (selectedCartId) {
            log(`User cart ${userSavedCartId} selected`)
            setCartId(userId, selectedCartId)
            this.$orderpath.cart.fetchCarts()
            return true
          }
        }
        return false
      }
      const tryToCreateAndSelectCart = async (userId: string) => {
        log('User cart saved: false')
        log('User basic carts: empty')
        const newCartId: string = await this.$orderpath.cart.createCartAndSelect()
        log(`New cart ${newCartId} selected`)
        setCartId(userId, newCartId)
      }

      const tryToSelectWishlistFromBasicWishlists = async (userId: string): Promise<boolean> => {
        const basicWishlists: Array<BasicWishlist> = await this.$orderpath.wishlist.fetchWishlists()
        if (basicWishlists.length) {
          log('User basic wishlists: exists')
          const firstItem: string | null = await this.$orderpath.wishlist.selectWishlist(basicWishlists[0].id)
          if (firstItem === null) {
            return false
          }
          log(`Wishlist ${basicWishlists[0].id} choosed from basicWishlist`)
          setWishlistId(userId, basicWishlists[0].id)
          return true
        }
        return false
      }
      const tryToFetchUserSavedWishlist = async (userId: string) => {
        const userSavedWishlistId: string | null = getWishlistId(userId)
        if (userSavedWishlistId) {
          log('User wishlist saved: true')
          log(`Selecting user wishlist ${userSavedWishlistId}`)
          const selectedWishlistId: string | null = await this.$orderpath.wishlist.selectWishlist(userSavedWishlistId)
          if (selectedWishlistId) {
            log(`User wishlist ${selectedWishlistId} selected`)
            setWishlistId(userId, selectedWishlistId)
            this.$orderpath.wishlist.fetchWishlists()
            return true
          }
        }
        return false
      }
      const tryToCreateAndSelectWishlist = async (userId: string) => {
        log('User wishlist saved: false')
        log('User basic wishlists: empty')
        const newWishlistId: string = await this.$orderpath.wishlist.createWishlistAndSelect()
        log(`New wishlist ${newWishlistId} selected`)
        setWishlistId(userId, newWishlistId)
      }

      const initCart = async () => {
        const savedAnonymousCartId: string | null = getCartId(ANONYMOUS_USER_ID)
        log('Start Cart')
        if (savedAnonymousCartId) {
          log('IsAnonymousCartSaved: true')
          if (this.$auth.isAuthenticated) {
            const userId: string = rootState.account.currentUser?.id
            log('isAuthenticated: true ', userId)
            log('Checking if anonymous cart is empty')
            const anonymousCart: Cart.Responses.Cart | null = await this.$orderpath.cart.fetchAnonymousCart(savedAnonymousCartId)
            if (anonymousCart) {
              if (Array.isArray(anonymousCart.products) && anonymousCart.products.length < 1) {
                log('Anonymous cart was empty, removing')
                await this.$orderpath.cart.removeAnonymousCart(savedAnonymousCartId)
                clearCartId(ANONYMOUS_USER_ID)
                log(`Saved anonymous cart ${savedAnonymousCartId} cleared`)
                const userCartFetched: boolean = await tryToFetchUserSavedCart(userId)
                if (!userCartFetched) {
                  const basicCartSelected: boolean = await tryToSelectCartFromBasicCarts(userId)
                  if (!basicCartSelected) {
                    tryToCreateAndSelectCart(userId)
                  }
                }
              } else {
                log('Anonymous cart has items, takeover')
                try {
                  const cart: Cart.Responses.NewCart = await this.$orderpath.cart.takeoverCart(savedAnonymousCartId)
                  log(`Cart ${savedAnonymousCartId} takeover succeed`)
                  clearCartId(ANONYMOUS_USER_ID)
                  log(`Saved anonymous cart ${savedAnonymousCartId} cleared`)
                  await this.$orderpath.cart.fetchCarts()
                  log(`Cart ${cart.cartId} selecting`)
                  const selectedCartId: string | null = await this.$orderpath.cart.selectCart(cart.cartId)
                  if (selectedCartId) {
                    log(`Cart ${cart.cartId} selected`)
                    setCartId(userId, selectedCartId)
                  } else {
                    log(`Cart ${cart.cartId} not selected`)
                    const basicCartSelected: boolean = await tryToSelectCartFromBasicCarts(userId)
                    if (!basicCartSelected) {
                      tryToCreateAndSelectCart(userId)
                    }
                  }
                } catch (e) {
                  log(`Cart ${savedAnonymousCartId} takeover failed`)
                  log(`Saved anonymous cart ${savedAnonymousCartId} cleared`)
                  clearCartId(ANONYMOUS_USER_ID)
                  const userCartFetched: boolean = await tryToFetchUserSavedCart(userId)
                  if (!userCartFetched) {
                    const basicCartSelected: boolean = await tryToSelectCartFromBasicCarts(userId)
                    if (!basicCartSelected) {
                      tryToCreateAndSelectCart(userId)
                    }
                  }
                }
              }
            } else {
              log('Anonymous cart does not exists on BE, removing')
              clearCartId(ANONYMOUS_USER_ID)
              const userCartFetched: boolean = await tryToFetchUserSavedCart(userId)
              if (!userCartFetched) {
                const basicCartSelected: boolean = await tryToSelectCartFromBasicCarts(userId)
                if (!basicCartSelected) {
                  tryToCreateAndSelectCart(userId)
                }
              }
            }
          } else if (this.$tenantConfig.isPlatformOpened) {
            log('isAuthenticated: false')
            log('isPlatformOpened: true')
            log(`Cart ${savedAnonymousCartId} selecting`)
            const selectedCartId: string | null = await this.$orderpath.cart.selectCart(savedAnonymousCartId)
            if (selectedCartId) {
              log(`Cart ${savedAnonymousCartId} selected`)
              setCartId(ANONYMOUS_USER_ID, selectedCartId)
            } else {
              log(`Cart ${savedAnonymousCartId} not selected`)
              const newCartId: string = await this.$orderpath.cart.createCartAndSelect()
              log(`New cart ${newCartId} selected`)
              setCartId(ANONYMOUS_USER_ID, newCartId)
            }
          } else {
            log('isAuthenticated: false')
            log('isPlatformOpened: false')
            clearCartId(ANONYMOUS_USER_ID)
            log('Anonymous cart cleared')
          }
        } else if (this.$auth.isAuthenticated) {
          log('IsAnonymousCartSaved: false')
          const userId: string = rootState.account.currentUser.id
          log('isAuthenticated: true ', userId)
          const userCartFetched: boolean = await tryToFetchUserSavedCart(userId)
          if (!userCartFetched) {
            const basicCartSelected: boolean = await tryToSelectCartFromBasicCarts(userId)
            if (!basicCartSelected) {
              tryToCreateAndSelectCart(userId)
            }
          }
        } else if (this.$tenantConfig.isPlatformOpened) {
          log('IsAnonymousCartSaved: false')
          log('isAuthenticated: false')
          log('isPlatformOpened: true')
          const newCart: Cart.Responses.NewCart = await this.$orderpath.cart.createCart()
          await this.$orderpath.cart.selectCart(newCart.cartId)
          log(`New Cart ${newCart.cartId} selecting`)
          setCartId(ANONYMOUS_USER_ID, newCart.cartId)
        }
        log('End Cart')
      }
      const initWishlist = async () => {
        const savedAnonymousWishlistId: string | null = getWishlistId(ANONYMOUS_USER_ID)
        if (savedAnonymousWishlistId) {
          log('IsAnonymousWishlistSaved: true')
          if (this.$auth.isAuthenticated) {
            const userId: string = rootState.account.currentUser.id
            log('isAuthenticated: true')
            log('Checking if anonymous wishlist is empty')
            const anonymousWishlist: Wishlist.Responses.Wishlist | null = await this.$orderpath.wishlist.fetchAnonymousWishlist(savedAnonymousWishlistId)
            if (anonymousWishlist) {
              // FIXME: Trzeba w oneCore wywalić mutowanie obiektu wishlist który tutaj dostajemy bo products nie jest Array
              if (
                (Array.isArray(anonymousWishlist.products) && anonymousWishlist.products.length < 1) ||
                (anonymousWishlist.products instanceof Map && anonymousWishlist.products.size < 1)
              ) {
                log('Anonymous wishlist was empty, removing')
                await this.$orderpath.wishlist.removeAnonymousWishlist(savedAnonymousWishlistId)
                clearWishlistId(ANONYMOUS_USER_ID)
                log(`Saved anonymous wishlist ${savedAnonymousWishlistId} cleared`)
                const userWishlistFetched: boolean = await tryToFetchUserSavedWishlist(userId)
                if (!userWishlistFetched) {
                  const basicWishlistSelected: boolean = await tryToSelectWishlistFromBasicWishlists(userId)
                  if (!basicWishlistSelected) {
                    tryToCreateAndSelectWishlist(userId)
                  }
                }
              } else {
                log('Anonymous wishlist has items, takeover')
                try {
                  const wishlist: WishlistId = await this.$orderpath.wishlist.takeoverWishlist(savedAnonymousWishlistId)
                  log(`Wishlist ${savedAnonymousWishlistId} takeover succeed`)
                  clearWishlistId(ANONYMOUS_USER_ID)
                  log(`Saved anonymous wishlist ${savedAnonymousWishlistId} cleared`)
                  await this.$orderpath.wishlist.fetchWishlists()
                  log(`Wishlist ${wishlist.id} selecting`)
                  const selectedWishlistId: string | null = await this.$orderpath.wishlist.selectWishlist(wishlist.id)
                  if (selectedWishlistId) {
                    log(`Wishlist ${wishlist.id} selected`)
                    setWishlistId(userId, selectedWishlistId)
                  } else {
                    log(`Wishlist ${wishlist.id} not selected`)
                    const basicWishlistSelected: boolean = await tryToSelectWishlistFromBasicWishlists(userId)
                    if (!basicWishlistSelected) {
                      tryToCreateAndSelectWishlist(userId)
                    }
                  }
                } catch (e) {
                  log(`Wishlist ${savedAnonymousWishlistId} takeover failed`)
                  log(`Saved anonymous wishlist ${savedAnonymousWishlistId} cleared`)
                  clearWishlistId(ANONYMOUS_USER_ID)
                  const userWishlistFetched: boolean = await tryToFetchUserSavedWishlist(userId)
                  if (!userWishlistFetched) {
                    const basicWishlistSelected: boolean = await tryToSelectWishlistFromBasicWishlists(userId)
                    if (!basicWishlistSelected) {
                      tryToCreateAndSelectWishlist(userId)
                    }
                  }
                }
              }
            } else {
              log('Anonymous wishlist does not exists on BE, removing')
              clearWishlistId(ANONYMOUS_USER_ID)
              const userWishlistFetched: boolean = await tryToFetchUserSavedWishlist(userId)
              if (!userWishlistFetched) {
                const basicWishlistSelected: boolean = await tryToSelectWishlistFromBasicWishlists(userId)
                if (!basicWishlistSelected) {
                  tryToCreateAndSelectWishlist(userId)
                }
              }
            }
          } else if (this.$tenantConfig.isPlatformOpened) {
            log('isAuthenticated: false')
            log('isPlatformOpened: true')
            log(`Wishlist ${savedAnonymousWishlistId} selecting`)
            const selectedWishlistId: string | null = await this.$orderpath.wishlist.selectWishlist(savedAnonymousWishlistId)
            if (selectedWishlistId) {
              log(`Wishlist ${selectedWishlistId} selected`)
              setWishlistId(ANONYMOUS_USER_ID, selectedWishlistId)
            } else {
              log(`Wishlist ${selectedWishlistId} not selected`)
              const newWishlistId: string = await this.$orderpath.wishlist.createWishlistAndSelect()
              log(`New wishlist ${newWishlistId} selected`)
              setWishlistId(ANONYMOUS_USER_ID, newWishlistId)
            }
          } else {
            log('isAuthenticated: false')
            log('isPlatformOpened: false')
            clearWishlistId(ANONYMOUS_USER_ID)
            log('Anonymous wishlist cleared')
          }
        } else if (this.$auth.isAuthenticated) {
          log('IsAnonymousWishlistSaved: false')
          log('isAuthenticated: true')
          const userId: string = rootState.account.currentUser.id

          const userWishlistFetched: boolean = await tryToFetchUserSavedWishlist(userId)
          if (!userWishlistFetched) {
            const basicWishlistSelected: boolean = await tryToSelectWishlistFromBasicWishlists(userId)
            if (!basicWishlistSelected) {
              tryToCreateAndSelectWishlist(userId)
            }
          }
        } else if (this.$tenantConfig.isPlatformOpened) {
          log('IsAnonymousWishlistSaved: false')
          log('isAuthenticated: false')
          log('isPlatformOpened: true')
          const newWishlist: WishlistId = await this.$orderpath.wishlist.createWishlist()
          await this.$orderpath.wishlist.selectWishlist(newWishlist.id)
          log(`New wishlist ${newWishlist.id} selecting`)
          setWishlistId(ANONYMOUS_USER_ID, newWishlist.id)
        }
        log('End wishlists')
      }
      await initCart()
      await initWishlist()
      commit('SET_ORDERPATH_LOADED', true)
      commit('SET_APP_LOADED', true)
      return Promise.resolve()
    },
    async nuxtServerInit(context: ActionContext<any, any>, nuxt) {
      const config: TenantConfiguration = nuxt.req.configuration
      try {
        context.commit('cms/SET_MAIN_PAGE_CONFIGURATION', nuxt.req.configuration)
        this.$tenantConfig
          .setTenantKey(config.tenantKey)
          .setCurrency(config.currency)
          .setAccessMode(config.accessMode)
          .setConfiguration(config)
          .setIsDev(config.isDev)
        context.commit('layout/SET_CURRENCY', config.currency)
        await context.dispatch('fetchRequiredData')
        await this.$auth.initSessionFromCookies(nuxt.req)
        if (this.$auth.isAuthenticated) {
          await context.dispatch('fetchAuthenticatedUserData')
        } else {
          context.commit('layout/SET_PRICE_GROSS', true)
        }
        context.commit('stocks/SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED')
      } catch (err: any) {
        nuxt.$logger.error(err)
      }
    },
  },
}

export default initState

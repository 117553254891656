






































  import { Component, Prop, Vue } from 'vue-property-decorator'
  import uuid from 'uuid-random'

  export interface Tab {
    name: string
    slotName: string
  }

  @Component
  export default class OneProductDataContainer extends Vue {
    @Prop({
      required: false,
      type: String,
    })
    readonly title?: string;

    @Prop({
      required: false,
      type: Array,
      default: () => [],
    })
    readonly tabs!: Array<Tab>;

    _uuid: string = uuid()
    currentTab: string | null = null
    showCollapse: boolean = true

    get collapseUuid(): string {
      return `collapse-${this._uuid}`
    }

    get isTabbed(): boolean {
      return !!this.tabs.length
    }

    get isTabActive(): Function {
      return (tabName: string): boolean => {
        return this.currentTab === tabName
      }
    }

    setActiveTab(tabName: string): void {
      if (this.tabs.find(tab => tab.name === tabName)) {
        this.currentTab = tabName
      } else {
        this.$logger.warn(`Tab with name ${tabName} does not exist! Available tabs: ${this.tabs}`)
      }
    }

    onTabClick(tabName: string): void {
      this.currentTab = tabName
    }

    onCollapseClick(): void {
      this.showCollapse = !this.showCollapse
      this.$emit('on-collapse-click')
    }

    mounted() {
      if (this.isTabbed) {
        this.setActiveTab(this.tabs[0].name)
      }
    }

    cursorActive(): string {
      return this.showCollapse ? 'icon-arrows-up' : 'icon-arrows-down'
    }
  }

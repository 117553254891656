























  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import OneError404 from '~/components/atoms/errors/OneError404.vue'
  import OneError500 from '~/components/atoms/errors/OneError500.vue'

  @Component
  export default class Error extends Vue {
    @Prop({
      required: true,
      type: Object,
    })
    error: any;

    get errorPage() {
      if (this.error.statusCode === 404) {
        return OneError404
      }
      return OneError500
    }

    get getRequestID() {
      return this.error.err && this.error.err.config && this.error.err.config.headers['one-request-id']
    }

    get isDev():boolean {
      return process.env.NODE_ENV !== 'production'
    }

    get hasResponse():boolean {
      return this.error.err && !!this.error.err.response
    }

    get isFrontendError():boolean {
      // eslint-disable-next-line max-len
      return this.error && this.error.err && !!this.error.err.request // if no request than its only frontend, else call was issued
    }

    get parsedRequest() {
      const { request }: { request: XMLHttpRequest } = this.error.err
      if (request) {
        return {
          url: request.responseURL,
          headers: request.getAllResponseHeaders(),
        }
      }
      return null
    }

    get stacktrace() {
      if (!this.error.err) { return {} }
      if (!this.isFrontendError && this.hasResponse) {
        return {
          isFrontendError: this.isFrontendError,
          requestTime: new Date()?.toLocaleString(),
          requestTimeUTC: new Date()?.toUTCString(),
          request: this.parsedRequest,
          response: {
            ...this.error.err.response,
            config: undefined,
          },
          config: this.error.err.response.config,
        }
      }
      return {
        isFrontendError: this.isFrontendError,
        err: this.error.err,
      }
    }

    onCopyToClipboard() {
      const configText = this.$refs.config
      // @ts-ignore
      configText.select()

      /* Copy the text inside the text field */
      document.execCommand('copy')
    }
  }

export function EnsureClientSide(_target: any, _propertyName: string, propertyDescriptor: PropertyDescriptor) {
  const method = propertyDescriptor.value

  propertyDescriptor.value = function (...args: any[]) {
    if (process.client) {
      return method.apply(this, args)
    }
    return null
  }
  return propertyDescriptor
}
































  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class OneHeader extends Vue {
    @Prop({
      default: false,
    })
    extendedHeader?: Boolean

    @Prop({
      default: '',
    })
    seoH1?: String

    @Prop({
      default: true,
    })
    fullScope?: Boolean
  }


// TODO Error handling for the old cart - v1
export enum ErrorType {
  POLICY = 'POLICY',
  PAYMENT = 'PAYMENT',
  ORDER_ALREADY_SUBMITTED = 'ORDER_ALREADY_SUBMITTED',
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
  CANNOT_BE_ORDER = 'CANNOT_BE_ORDER',
  USER_IS_NOT_ACTIVE = 'USER_IS_NOT_ACTIVE'
}

export enum PaymentError {
  NOT_ENOUGH_TRADE_CREDIT = 'NOT_ENOUGH_TRADE_CREDIT'
}

interface SubmitOrderErrors {
  errorType: ErrorType
  paymentError?: PaymentError
  policyViolations: Array<{
  description: string
  }>
}
// End Error handling for the old cart - v1

interface GenericError {
  error: boolean
  errorCode?: string
  message?: string
  requestId?: string
}

export type ErrorHandlerType = GenericError & SubmitOrderErrors

export default function translateBackendError(error: ErrorHandlerType, ctx: any) {
  const defaultError = ctx.i18n.t('errors.generic')
  const translateError = (errorType: string) => {
    const translatePath = `backend_errors.${errorType}`
    return ctx.i18n.te(translatePath) ? ctx.i18n.t(translatePath) : defaultError
  }
  if (error.errorCode) { return translateError(error.errorCode) }
  // TODO Error handling for the old cart - v1
  if (!error.errorType) {
    return defaultError
  }
  if (error.errorType === ErrorType.PAYMENT) {
    return translateError(error.paymentError!)
  } else if (error.errorType === ErrorType.POLICY) {
    return error.policyViolations.map(pV => pV.description).join(', ')
  } else {
    return translateError(error.errorType)
  }
  // End Error handling for the old cart - v1
}

import {
  Offers,
  Shared,
} from '@one/types/dist/orderpath/app'
import { OffersState } from './index'

export const SET_PAGINATED_OFFERS = 'SET_PAGINATED_OFFERS'
export const CHANGE_SEARCH = 'CHANGE_SEARCH'
export const SET_PAGE = 'SET_PAGE'
export const SET_DATE_FILTER = 'SET_DATE_FILTER'
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER'

export const CLEAR_OFFER_LIST = 'CLEAR_OFFER_LIST'
export const CLEAR_STORE = 'CLEAR_STORE'

export const mutations = {
  [SET_PAGINATED_OFFERS](
    state: OffersState,
    payload: Offers.Responses.OffersList,
  ) {
    state.offersList = payload
  },
  [CHANGE_SEARCH](state: OffersState, search: string | null) {
    state.fetchParams.search = search
  },
  [SET_PAGE](state: OffersState, page: number) {
    state.fetchParams.pageNumber = page
  },
  [SET_DATE_FILTER](
    state: OffersState,
    { dateType, value }: { dateType: string; value: string | null },
  ) {
    state.fetchParams[dateType] = value
  },
  [SET_STATUS_FILTER](
    state: OffersState,
    status: Shared.OfferStatusEnum | null,
  ) {
    state.fetchParams.status = status
  },
  [CLEAR_OFFER_LIST](state: OffersState) {
    state.offersList = {
      items: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 0,
      pageSize: 10,
    }
  },
  [CLEAR_STORE](state: OffersState) {
    state.offersList = {
      items: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 0,
      pageSize: 10,
    }
    state.fetchParams = {
      pageNumber: 1,
      pageSize: 10,
      search: null,
      status: null,
      createdOnStartDate: null,
      createdOnEndDate: null,
      expirationDateStartDate: null,
      expirationDateEndDate: null,
    }
  },
}
export default mutations

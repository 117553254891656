import { Module } from 'vuex'
import { Products } from '@one/types'
import { mutations } from './mutations'
import { actions } from './actions'
import getters from './getters'
import { ProductPage, ProductsState } from '~/store/products/types'
import ProductPrice = Products.ProductPrice
import SortingOptionCode = Products.SortingOptionCode
import { RootState } from '~/store'

export const state = (): ProductsState => ({
  fetching: false,
  searchText: '',
  orderBy: '',
  products: {},
  productPage: {} as ProductPage,
  prices: {} as Record<string, ProductPrice | null>,
  suggest: null,
  categorySelected: false,
  manufacturers: {},
  stocksByWarehouse: {},
  cableDrumPrices: {},
  productsList: {
    q: '',
    pageSize: 15,
    pageNumber: 1,
    loadedPages: {},
    totalPages: 0,
    totalItems: 0,
    categoriesCount: {},
    filtersQuery: [],
    selectedFilters: {},
    attributes: {
      byId: {},
      allIds: [],
    },
    price: '',
    sortingOptions: [],
    priceFilters: {},
    sortCriteria: 'SCORE_DESC',
    sortOptionForCatalogBrowser: SortingOptionCode.RANK_DESC,
    sortOptionForSearch: SortingOptionCode.SCORE_DESC,
    alphabeticalCategoriesSorting: false,
    displayMode: 'product-list-view',
  },
})

const namespaced: boolean = true

const products: Module<ProductsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export default products

import { Wishlist as Types } from '@one/types/dist/orderpath/app'
import { format } from 'date-fns'
import { BaseService } from '~/plugins/services/base'
import { WishlistState } from '~/store/wishlist/types'

export interface WishlistServiceOptions {
  dateFormat?: string,
}

export class WishlistService extends BaseService<WishlistState> {
  dateFormat: string = 'dd.MM.yyyy HH:mm:ss'

  constructor(context: any, options?: WishlistServiceOptions) {
    super(context, 'wishlist')
    if (options) {
      this.loadOptions(options)
    }
  }

  private setWishlistInLocalStorage(key: string, id: string) {
    const userConfig = JSON.parse(localStorage.getItem(key) || '{}')
    userConfig.wishlistId = id
    localStorage.setItem(key, JSON.stringify(userConfig))
  }

  public clearWishlistFromLocalStorage(key: string) {
    const userConfig = JSON.parse(localStorage.getItem(key) || '{}')
    delete userConfig.wishlistId
    localStorage.setItem(key, JSON.stringify(userConfig))
  }

  private loadOptions(options: WishlistServiceOptions) {
    this.dateFormat = options.dateFormat || this.dateFormat
  }

  private createWishlistName(name?: string) {
    return `${this.i18n.t('wishlist.default_name')} - ${(name && name.trim()) || format(new Date(), this.dateFormat)}`
  }

  public get currentWishlistId(): string | null {
    return this.state.selectedWishlist
  }

  public selectWishlist(wishlistId: string): Promise<string | null> {
    return this.dispatch<string | null>('selectWishlist', wishlistId).then(
      (wishlistId: string | null) => {
        if (wishlistId) {
          const userKey: string =
            this.context.app.$auth.isAuthenticated ? this.store.state.account.currentUser.id : 'one-anonymous'
          this.setWishlistInLocalStorage(userKey, wishlistId)
        }
        return Promise.resolve(wishlistId)
      },
    )
  }

  public fetchWishlists(): Promise<Array<Types.Responses.BasicWishlist>> {
    return this.dispatch<Array<Types.Responses.BasicWishlist>>('fetchWishlists')
  }

  public fetchWishlist(wishlistId?: string): Promise<Types.Responses.Wishlist> {
    return this.dispatch<Types.Responses.Wishlist>('fetchWishlist', wishlistId || this.currentWishlistId)
  }

  public fetchAnonymousWishlist(wishlistId: string): Promise<Types.Responses.Wishlist | null> {
    return this.dispatch<Types.Responses.Wishlist>('fetchAnonymousWishlist', wishlistId)
  }

  public createWishlist(name?: string): Promise<Types.Responses.WishlistId> {
    return this.dispatch<Types.Responses.WishlistId>('createWishlist', this.createWishlistName(name))
  }

  public takeoverWishlist(wishlistId: string): Promise<Types.Responses.WishlistId> {
    return this.dispatch<Types.Responses.WishlistId>('takeoverWishlist', wishlistId)
  }

  public renameWishlist(newName?: string): Promise<void> {
    return this.dispatch<void>('renameWishlist', {
      wishlistId: this.currentWishlistId,
      name: this.createWishlistName(newName),
    })
  }

  public removeAnonymousWishlist(wishlistId: string): Promise<void> {
    return this.dispatch<void>('removeAnonymousWishlist', wishlistId)
  }

  /**
   * @param wishlistId  If empty, currentWishlistId selected.
  */
  public removeWishlist(wishlistId?: string): Promise<void> {
    const wishlistToRemove: string | null = wishlistId || this.currentWishlistId
    if (!wishlistToRemove) {
      return Promise.reject(new Error('No wishlist to remove'))
    }
    return this.dispatch<void>('removeWishlist', wishlistToRemove).then(
      async () => {
        const wishlists: Array<Types.Responses.BasicWishlist> = await this.fetchWishlists()
        if (wishlists.length) {
          await this.selectWishlist(wishlists[0].id)
        } else {
          await this.createWishlistAndSelect()
        }
      },
    )
  }

  /**
   * Method creates new wishlist, then refetch wishlists from BE and select first wishlist
   * @param name User friendly name. If empty, current date in format dd-MM-yyy is set
   */
  public async createWishlistAndSelect(name?: string): Promise<string> {
    const newWishlist: Types.Responses.WishlistId = await this.createWishlist(name)
    await this.fetchWishlists()
    await this.selectWishlist(newWishlist.id)
    return newWishlist.id
  }

  public async fetchWishlistsAndSelectOrCreate(): Promise<string> {
    const basicWishlists: Array<Types.Responses.BasicWishlist> = await this.fetchWishlists()
    if (basicWishlists.length) {
      const selectedWishlistId = await this.selectWishlist(basicWishlists[0].id)
      if (selectedWishlistId) {
        return selectedWishlistId
      }
    }
    return await this.createWishlistAndSelect()
  }
}

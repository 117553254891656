






  import { Vue, Component, Prop } from 'nuxt-property-decorator'

  @Component
  export default class Error404 extends Vue {
    @Prop({
      required: true,
      type: Object,
    })
    error: any;
  }























  import { Component, Vue, Prop } from 'vue-property-decorator'
  @Component
  export default class OneFormContainerInput extends Vue {
    name: string = 'OneFormContainerInput.vue'

    @Prop({
      type: Boolean,
      default: false,
    })
    required!: boolean;

    @Prop({
      type: Boolean,
      default: true,
    })
    showLabel!: boolean;

    @Prop({
      type: String,
      default: '',
    })
    label!: string;

    @Prop({
      type: Array,
      default: () => ([]),
    })
    validationErrors!: Array<string>;

    @Prop({
      type: String,
      default: 'col-md-3',
    })
    labelClass!: string;

    @Prop({
      type: String,
      default: 'col-md-5',
    })
    inputClass!: string;
  }
